export interface ICountry {
    CountryCode: string;
    Alpha3Code: string;
    Name: string;
}

export interface IState {
    StateCode: string;
    Name: string;
}

/**
 * holds the definition for the Address object.  This should be
 * updated if the Address Interface changes
 * @property Id
 * @property Address1
 * @property Address2
 * @property City
 * @property StateCode
 * @property Zip
 * @property CountryCode
 * @property Province
 * @property Country
 * @property State
 */
export interface IAddress {
    Id: number;
    Address1: string;
    Address2: string;
    City: string;
    StateCode?: string;
    Zip: string;
    CountryCode?: string;
    Province: string;

    // foreign keys
    Country?: ICountry;
    State?: IState;
}

export function formatAddress(addr: IAddress): string {
    let isInternational = addr.CountryCode !== 'US';
    let fullAddress = '';
    fullAddress += addr?.Address1 ?? '';
    fullAddress += addr?.Address2 ? ' ' + addr.Address2 : '';
    fullAddress += addr?.City ? ', ' + addr.City : '';
    if (isInternational) {
        fullAddress += addr?.Province ? ', ' + addr.Province : '';
        fullAddress += addr?.Country?.Alpha3Code ? ', ' + addr.Country.Alpha3Code : '';
    } else {
        fullAddress += addr?.StateCode ? ', ' + addr.StateCode : '';
    }
    fullAddress += addr?.Zip ? ' ' + addr.Zip : '';
    return fullAddress;
}

export function formatAddressVertical(addr: IAddress): string {
    let isInternational = addr.CountryCode !== 'US';
    let fullAddress = '';
    fullAddress += addr?.Address1 ?? '';
    fullAddress += addr?.Address2 ? '<br>' + addr.Address2 : '';
    fullAddress += addr?.City ? '<br> ' + addr.City : '';
    if (isInternational) {
        fullAddress += addr?.Province ? ', ' + addr.Province : '';
        fullAddress += addr?.Country?.Alpha3Code ? ', ' + addr.Country.Alpha3Code : '';
    } else {
        fullAddress += addr?.StateCode ? ', ' + addr.StateCode : '';
    }
    fullAddress += addr?.Zip ? '  ' + addr.Zip : '';
    return fullAddress;
}
