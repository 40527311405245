import { isNullOrWhitespace } from '@mt-ng2/common-functions';

export function formatPhone(num: string): string {
    let formatted = '';
    if (isNullOrWhitespace(num)) {
        return formatted;
    }
    let chunks = num.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    formatted = !chunks[2] ? chunks[1] : '(' + chunks[1] + ') ' + chunks[2] + (chunks[3] ? '-' + chunks[3] : '');
    return formatted;
}

export function formatPhoneWithExt(num: string, ext: string): string {
    let extension = '';
    if (ext) {
        extension = 'Ext ' + ext;
    }
    let formattedPhone = formatPhone(num);
    return formattedPhone + ' ' + extension;
}
