import { Component, Injector } from '@angular/core';

import { pluralize } from '@mt-ng2/common-functions';
import { FormSelectBaseWithNullableOption } from '../form-select-wrapper/form-select-base-with-nullable-option';

@Component({
    styles: [
        `
            .inside-box-maxlength {
                position: relative !important;
                bottom: -13px;
                top: -13px;
                margin-bottom: -13px;
                right: 3px;
                font-size: 70%;
            }
        `,
    ],
    templateUrl: './form-radio-button-list.component.html',
})
export class FormRadioButtonListComponent extends FormSelectBaseWithNullableOption {
    constructor(injector: Injector) {
        super(injector);
    }

    getNoItemsErrorMessage(): string {
        return `no ${pluralize(this.config.label.toLowerCase())} available`;
    }
}
