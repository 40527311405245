import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'mt-color-picker-control',
    templateUrl: './color-picker-control.component.html',
})
export class ColorPickerControlComponent {
    @Input() colorPicker; // The color to show in the color picker dialog.
    @Input() cpWidth = '230px'; // Use this option to set color picker dialog width ('230px').
    @Input() cpHeight = 'auto'; // Use this option to force color picker dialog height ('auto').
    @Input() cpToggle = false; // Sets the default open / close state of the color picker (false).
    @Input() cpDisabled = false; // Disables opening of the color picker dialog via toggle / events.
    @Input() cpColorMode = 'color'; // Dialog color mode: 'color', 'grayscale', 'presets' ('color').
    @Input() cpCmykEnabled = false; // Enables CMYK input format and color change event (false).
    @Input() cpOutputFormat = 'auto'; // Output color format: 'auto', 'hex', 'rgba', 'hsla' ('auto').
    @Input() cpAlphaChannel = 'enabled'; // Alpha mode: 'enabled', 'disabled', 'always', 'forced' ('enabled').
    @Input() cpFallbackColor = ''; // Used when the color is not well-formed or is undefined ('#000').
    @Input() cpPosition = 'right'; // Dialog position: 'right', 'left', 'top', 'bottom' ('right').
    @Input() cpPositionOffset = '0%'; // Dialog offset percentage relative to the directive element (0%).
    @Input() cpPositionRelativeToArrow = false; // Dialog position is calculated relative to dialog arrow (false).
    @Input() cpPresetLabel = 'Preset Colors'; // Label text for the preset colors if any provided ('Preset colors').
    @Input() cpPresetColors = []; // Array of preset colors to show in the color picker dialog @Input() (]).;
    @Input() cpDisableInput = false; // Disables / hides the color input field from the dialog (false).
    @Input() cpDialogDisplay = 'popup'; // Dialog positioning mode: 'popup', 'inline' ('popup').
    @Input() cpIgnoredElements: any = []; // Array of HTML elements that will be ignored when clicked @Input() (]).;
    @Input() cpSaveClickOutside = true; // Save currently selected color when user clicks outside (true).
    @Input() cpCloseClickOutside = true; // Close the color picker dialog when user clicks outside (true).
    @Input() cpOKButton = false; // Show an OK / Apply button which saves the color (false).
    @Input() cpOKButtonText = 'OK'; // Button label text shown inside the OK / Apply button ('OK').
    @Input() cpOKButtonClass = 'cp-ok-button-class'; // Additional class for customizing the OK / Apply button ('').
    @Input() cpCancelButton; // Show a Cancel / Reset button which resets the color (false).
    @Input() cpCancelButtonText; // Button label text shown inside the Cancel / Reset button ('Cancel').
    @Input() cpCancelButtonClass; // Additional class for customizing the Cancel / Reset button ('').
    @Input() cpAddColorButton = false; // Show an Add Color button which add the color into preset (false).
    @Input() cpAddColorButtonText = 'Add color'; // Button label text shown inside the Add Color button ('Add color').
    @Input() cpAddColorButtonClass = 'cp-add-color-button-class'; // Additional class for customizing the Add Color button ('').
    @Input() cpRemoveColorButtonClass = 'cp-remove-color-button-class'; // Additional class for customizing the Remove Color button ('').
    @Input() cpMaxPresetColorsLength = 6; // Use this option to set the max colors allowed in presets (null).
    @Input() cpPresetEmptyMessage = 'No colors added'; // Message for empty colors if any provided used ('No colors added').
    @Input() cpPresetEmptyMessageClass = 'preset-empty-message'; // Additional class for customizing the empty colors message ('').
    @Input() cpUseRootViewContainer = false; // Create dialog component in the root view container (false).

    @Output() cpInputChange = new EventEmitter<any>(true); // Input name and its value, send when user changes color through inputs
    @Output() cpToggleChange = new EventEmitter<boolean>(true); // Status of the dialog, send when dialog is opened / closed (open: boolean).

    @Output() cpSliderChange = new EventEmitter<any>(true); // Slider name and its value, send when user changes color through slider
    @Output() cpSliderDragEnd = new EventEmitter<string>(true); // Slider name and current color, send when slider dragging ends (mouseup,touchend)
    @Output() cpSliderDragStart = new EventEmitter<string>(true); // Slider name and current color, send when slider dragging starts (mousedown,touchstart)

    @Output() colorPickerOpen = new EventEmitter<string>(true); // Current color value, send when dialog is opened (value: string).
    @Output() colorPickerClose = new EventEmitter<string>(true); // Current color value, send when dialog is closed (value: string).

    @Output() colorPickerCancel = new EventEmitter<string>(true); // Color select canceled, send when Cancel button is pressed (void).
    @Output() colorPickerSelect = new EventEmitter<string>(true); // Selected color value, send when OK button is pressed (value: string).
    @Output() colorPickerChange = new EventEmitter<string>(true); // Changed color value, send when color is changed (value: string).

    @Output() cpCmykColorChange = new EventEmitter<string>(true); // Outputs the color as CMYK string if CMYK is enabled (value: string).
    @Output() cpPresetColorsChange = new EventEmitter<any>(true); // Preset colors, send when 'Add Color' button is pressed (value: array).
    constructor() {}
}
