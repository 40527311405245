// pulled directly from this repo https://github.com/ng-bootstrap/ng-bootstrap

import { Component, Input, OnChanges, ChangeDetectionStrategy, SimpleChanges, ViewEncapsulation, Inject, OnInit, Optional } from '@angular/core';
import { ITypeAheadConfig, TypeAheadModuleConfigToken, MatchingStrategy } from '@mt-ng2/type-ahead-control-config';
import { MatcherFactory } from './libraries/MatchingFactories/MatcherFactory';

/**
 * A component that can be used inside a custom result template in order to highlight the term inside the text of the
 * result
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    selector: 'highlighter',
    styles: [
        `
            .ngb-highlight {
                font-weight: bold;
            }
        `,
    ],
    template:
        `<ng-template ngFor [ngForOf]="parts" let-part let-isOdd="odd">` +
        `<span *ngIf="isOdd; else even" [class]="highlightClass">{{part}}</span><ng-template #even>{{part}}</ng-template>` +
        `</ng-template>`, // template needs to be formatted in a certain way so we don't add empty text nodes
})
export class HighlighterComponent implements OnChanges, OnInit {
    parts: string[];

    /**
     * The CSS class of the span elements wrapping the term inside the result
     */
    @Input() highlightClass = 'ngb-highlight';

    /**
     * The result text to display. If the term is found inside this text, it's highlighted
     */
    @Input() result: string;

    /**
     * The searched term
     */
    @Input() term: string;

    /**
     * Defines the type of matching we will use to highlight text
     */
    @Input() matchingStrategy: MatchingStrategy;

    constructor(
        @Inject(TypeAheadModuleConfigToken)
        @Optional()
        private typeAheadConfig: ITypeAheadConfig,
    ) {}

    ngOnInit(): void {
        // Prioritize the input strategy otherwise fall back on the config.
        if (this.matchingStrategy !== undefined) {
            return;
        } else if (this.typeAheadConfig && this.typeAheadConfig.matchingStrategy) {
            this.matchingStrategy = this.typeAheadConfig.matchingStrategy;
        }
    }

    /**
     * If a term is passed we used the MatcherFactory to get the highlighted parts
     * based on the matching strategy defined and the component / module level
     * @param changes changes object is not used in this method
     */
    ngOnChanges(changes: SimpleChanges): void {
        const resultStr = this.toString(this.result);
        const termLC = this.toString(this.term).toLowerCase();
        let currentIdx = 0;
        if (termLC.length > 0) {
            const matcher = new MatcherFactory().createMatchingStrategy(this.matchingStrategy);
            this.parts = matcher.getHighlightedParts(resultStr, termLC, currentIdx);
        } else {
            this.parts = [resultStr];
        }
    }

    toString(value: any): string {
        return value !== undefined && value !== null ? `${value}` : '';
    }
}
