import { Component, Input, EventEmitter, Output, OnInit, ViewChild, ElementRef, forwardRef, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import {
    NumericControlTypes,
    INumericControlComponentApi,
    round,
    getDefaultNumberFormatDisplayFunction,
    NumberFormatDisplayFunction,
    ConvertNumberFunction,
    DefaultConversionFunctions,
} from '../libraries/numeric-control.library';

/**
 * This component is a Reactive Forms ready control that can be set within a FromGroup and assigned a
 * formControlName property to be hooked into a Form.  In order to act like a input type=number while
 * editing but then be able to display text like a input type=input, it changes its type on blur and
 * focus.
 */
@Component({
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumericComponent),
        },
    ],
    selector: 'mt-numeric',
    templateUrl: './numeric-control.component.html',
})
export class NumericComponent implements OnInit, ControlValueAccessor {
    // *** CONTROL VALUE ACCESSOR ***
    /* tslint:disable:member-ordering */
    writeValue(obj: any): void {
        this._updateValueInView(obj);
    }
    private _onValueChanged: (value: any) => void;
    registerOnChange(fn: any): void {
        this._onValueChanged = fn;
    }
    private _onTouched: () => void;
    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }
    disabled = false;
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    // *** END -- CONTROL VALUE ACCESSOR ***

    @Input() type = NumericControlTypes.Integer;
    @Input() scale: number;
    @Input() placeholder: string = null;
    @Input() doNotAllowZero = false;
    /**
     * used to display the string version of the control's value.  If left undefined, will assign
     * a default standard number format.
     */
    @Input() numberFormatDisplayFunction: NumberFormatDisplayFunction;
    /**
     * used to convert a number from its display value to its form value (i.e. when percentage the
     * display value 27 becomes the form value .27)
     */
    @Input() convertFromDisplayToFormFunction: ConvertNumberFunction;
    /**
     * used to convert a number from its form value to its display value (i.e. when percentage the
     * form value .27 becomes the display value 27)
     */
    @Input() convertFromFormToDisplayFunction: ConvertNumberFunction;

    @Output() blur = new EventEmitter<null>();
    @Output() focus = new EventEmitter<null>();
    @Output() ready = new EventEmitter<INumericControlComponentApi>();

    @ViewChild('inputElement', { static: true }) inputElement: ElementRef;

    /**
     * current form value of the control, used when determining if _onValueChanged should be called
     */
    value: number;

    constructor(private _renderer: Renderer2) {}

    ngOnInit(): void {
        if (typeof this.scale === 'undefined' || this.scale === null) {
            this.scale = this.type === NumericControlTypes.Currency ? 2 : 0;
        }
        if (typeof this.numberFormatDisplayFunction === 'undefined') {
            this.numberFormatDisplayFunction = getDefaultNumberFormatDisplayFunction();
        }
        if (typeof this.convertFromDisplayToFormFunction === 'undefined') {
            this.convertFromDisplayToFormFunction = DefaultConversionFunctions.fromDisplayToForm(this.type);
        }
        if (typeof this.convertFromFormToDisplayFunction === 'undefined') {
            this.convertFromFormToDisplayFunction = DefaultConversionFunctions.fromFormToDisplay(this.type);
        }
        this.ready.emit({
            focus: this.focusMe.bind(this),
        });
    }

    focusMe(): void {
        this.inputElement?.nativeElement?.focus?.();
    }

    onBlur(): void {
        this.blur.emit();
        this._onTouched?.();
        this.setFormValue();
    }

    /**
     * gets the value from the input type input element, scrubs it to have only number characters, sets
     * the input type of the input element to be number, and sets the scrubbed value.  Also emits a
     * focus event.
     */
    onFocus(): void {
        this._changeInputToNumberType();
        this.inputElement.nativeElement.select?.();
        this.focus.emit();
    }

    private _changeInputToNumberType(): void {
        let value = this.inputElement.nativeElement.value;
        value = value.replace(/[^0-9.-]/g, '');
        this._renderer.setProperty(this.inputElement.nativeElement, 'type', 'number');
        this._renderer.setProperty(this.inputElement.nativeElement, 'value', value);
    }

    setFormValue(): void {
        const value = this._getFormValueFromInput();
        if (value !== this.value) {
            this.value = value;
            this._onValueChanged?.(value);
        }
        this._updateValueInView(value);
    }

    /**
     * takes in a form value of number, performs any manipulation of the value from what is stored in the form to what is
     * shown in the view (i.e. percentage shows as 27 but gets stored as .27) and gets the value as a formatted string.
     * Sets the input type of the input element to be input, and sets the string value.
     */
    private _updateValueInView(value: number): void {
        this.value = value;
        // run the conversion function if provided
        if (this.convertFromFormToDisplayFunction) {
            value = this.convertFromFormToDisplayFunction(value, this.type, this.scale);
        }
        this._changeInputToTextType(value);
    }

    private _changeInputToTextType(value: number): void {
        let valueToShow = this._getValueToShow(value);
        this._renderer.setProperty(this.inputElement.nativeElement, 'type', 'input');
        this._renderer.setProperty(this.inputElement.nativeElement, 'value', valueToShow);
    }

    /**
     * using the value from the form, get a string version to show in the view.
     * @returns string
     */
    private _getValueToShow(value: number): string {
        if (this.numberFormatDisplayFunction) {
            return this.numberFormatDisplayFunction(value, this.type, this.scale);
        } else {
            return value?.toString();
        }
    }

    /**
     * get the value from the input element, validate it as a number and round using scale.  Also perform
     * any manipulation of the value from what is in the view to what is stored in the form (i.e. percentage
     * shows as 27 but gets stored as .27)
     * @returns number
     */
    private _getFormValueFromInput(): number {
        let value = this._validateValueFromInputAndRound(this.inputElement.nativeElement.value);
        // run the conversion function if provided
        if (this.convertFromDisplayToFormFunction) {
            value = this.convertFromDisplayToFormFunction(value, this.type, this.scale);
        }
        return value;
    }

    private _validateValueFromInputAndRound(value: number): number {
        if (isNaN(value)) {
            value = null;
        } else if (typeof value === 'string') {
            const isEmptyString = (value as string).trim() === '';
            if (isEmptyString) {
                value = null;
            } else {
                value = +value; // convert to number
            }
        }
        if (value) {
            value = round(value, this.scale);
        }
        if ((!value && value !== 0) || (this.doNotAllowZero && value === 0)) {
            value = null;
        }
        return value;
    }
}
