import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { NotificationsService } from './notifications.service';
import { PortalNotificationStrategy } from '../strategies/concrete-strategies/portal-notification-strategy';

@Injectable()
export class PortalNotificationsService extends NotificationsService {
    constructor(private toastrService: ToastrService) {
        super();
        super.setNotificationStrategy(new PortalNotificationStrategy(this.toastrService));
    }
}
