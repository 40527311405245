import { Injectable, Optional } from '@angular/core';

import { CookieService } from 'ngx-cookie';

import { CookieStrategyService } from './cookie-strategy.service';
import { PortalCookieStrategy } from './portal-cookie-strategy.service';

@Injectable({ providedIn: 'root' })
export class StorageService extends CookieStrategyService {
    constructor(@Optional() cookieStrategyService: CookieStrategyService, cookieService: CookieService) {
        super();
        if (!cookieStrategyService) {
            cookieStrategyService = new PortalCookieStrategy(cookieService);
        }
        Object.assign(this, cookieStrategyService);
        this.get = cookieStrategyService.get.bind(cookieStrategyService);
        this.set = cookieStrategyService.set.bind(cookieStrategyService);
        this.remove = cookieStrategyService.remove.bind(cookieStrategyService);
    }

    get(key: string): Promise<string> {
        throw new Error('Method not implemented.');
    }

    set(key: string, value: any, options?: any): void | Promise<any> {
        throw new Error('Method not implemented.');
    }

    remove(key: string): void | Promise<string> {
        throw new Error('Method not implemented.');
    }
}
