import { DaysOfTheWeek, IContextualDate, ISearchFilterDaterangeValue, Months } from './model';

export class ContextualDateFunctions {
    static ThisWeek(firstDayOfTheWeek: DaysOfTheWeek): ISearchFilterDaterangeValue {
        let today = new Date();
        let todayCopy = new Date();
        let first = today.getDate() - today.getDay() + firstDayOfTheWeek;
        let last = first + 6;
        let startDate = new Date(today.setDate(first));
        let endDate = new Date(todayCopy.setDate(last));
        return { startDate, endDate };
    }
    static ThisMonth(firstDayOfTheWeek: DaysOfTheWeek): ISearchFilterDaterangeValue {
        let today = new Date();
        let startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        let endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        return { startDate, endDate };
    }
    static ThisQuarter(firstDayOfTheWeek: DaysOfTheWeek): ISearchFilterDaterangeValue {
        let today = new Date();
        let year = today.getFullYear();
        let month = today.getMonth();
        let quarterRange = setQuarters(month, year);
        return quarterRange;
    }
    static ThisYear(firstDayOfTheWeek: DaysOfTheWeek): ISearchFilterDaterangeValue {
        let today = new Date();
        let startDate = new Date(today.getFullYear(), Months.January, 1);
        let endDate = new Date(today.getFullYear(), Months.December, 31);
        return { startDate, endDate };
    }
    static LastWeek(firstDayOfTheWeek: DaysOfTheWeek): ISearchFilterDaterangeValue {
        let today = new Date();
        let todayCopy = new Date();
        let first = today.getDate() - today.getDay() + firstDayOfTheWeek - 7;
        let last = first + 6;
        let startDate = new Date(today.setDate(first));
        let endDate = new Date(todayCopy.setDate(last));
        return { startDate, endDate };
    }
    static LastMonth(firstDayOfTheWeek: DaysOfTheWeek): ISearchFilterDaterangeValue {
        let today = new Date();
        let startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        let endDate = new Date(today.getFullYear(), today.getMonth(), 0);
        return { startDate, endDate };
    }
    static LastQuarter(firstDayOfTheWeek: DaysOfTheWeek): ISearchFilterDaterangeValue {
        let today = new Date();
        let year = today.getFullYear();
        let month = today.getMonth() - 3;
        if (month < Months.January) {
            month += 12;
            year -= 1;
        }
        let quarterRange = setQuarters(month, year);
        return quarterRange;
    }
    static LastYear(firstDayOfTheWeek: DaysOfTheWeek): ISearchFilterDaterangeValue {
        let today = new Date();
        let startDate = new Date(today.getFullYear() - 1, Months.January, 1);
        let endDate = new Date(today.getFullYear() - 1, Months.December, 31);
        return { startDate, endDate };
    }
    static NextWeek(firstDayOfTheWeek: DaysOfTheWeek): ISearchFilterDaterangeValue {
        let today = new Date();
        let todayCopy = new Date();
        let first = today.getDate() - today.getDay() + firstDayOfTheWeek + 7;
        let last = first + 6;
        let startDate = new Date(today.setDate(first));
        let endDate = new Date(todayCopy.setDate(last));
        return { startDate, endDate };
    }
    static NextMonth(firstDayOfTheWeek: DaysOfTheWeek): ISearchFilterDaterangeValue {
        let today = new Date();
        let startDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        let endDate = new Date(today.getFullYear(), today.getMonth() + 2, 0);
        return { startDate, endDate };
    }
    static NextQuarter(firstDayOfTheWeek: DaysOfTheWeek): ISearchFilterDaterangeValue {
        let today = new Date();
        let year = today.getFullYear();
        let month = today.getMonth() + 3;
        if (month > Months.December) {
            month -= 12;
            year += 1;
        }
        let quarterRange = setQuarters(month, year);
        return quarterRange;
    }
    static NextYear(firstDayOfTheWeek: DaysOfTheWeek): ISearchFilterDaterangeValue {
        let today = new Date();
        let startDate = new Date(today.getFullYear() + 1, Months.January, 1);
        let endDate = new Date(today.getFullYear() + 1, Months.December, 31);
        return { startDate, endDate };
    }
}

export class ContextualDates {
    static ThisWeek = { Name: 'This Week', GetDates: ContextualDateFunctions.ThisWeek } as IContextualDate;
    static ThisMonth = { Name: 'This Month', GetDates: ContextualDateFunctions.ThisMonth } as IContextualDate;
    static ThisQuarter = { Name: 'This Quarter', GetDates: ContextualDateFunctions.ThisQuarter } as IContextualDate;
    static ThisYear = { Name: 'This Year', GetDates: ContextualDateFunctions.ThisYear } as IContextualDate;
    static LastWeek = { Name: 'Last Week', GetDates: ContextualDateFunctions.LastWeek } as IContextualDate;
    static LastMonth = { Name: 'Last Month', GetDates: ContextualDateFunctions.LastMonth } as IContextualDate;
    static LastQuarter = { Name: 'Last Quarter', GetDates: ContextualDateFunctions.LastQuarter } as IContextualDate;
    static LastYear = { Name: 'Last Year', GetDates: ContextualDateFunctions.LastYear } as IContextualDate;
    static NextWeek = { Name: 'Next Week', GetDates: ContextualDateFunctions.NextWeek } as IContextualDate;
    static NextMonth = { Name: 'Next Month', GetDates: ContextualDateFunctions.NextMonth } as IContextualDate;
    static NextQuarter = { Name: 'Next Quarter', GetDates: ContextualDateFunctions.NextQuarter } as IContextualDate;
    static NextYear = { Name: 'Next Year', GetDates: ContextualDateFunctions.NextYear } as IContextualDate;
}

export const DefaultContextualDates: IContextualDate[] = [
    ContextualDates.ThisWeek,
    ContextualDates.ThisMonth,
    ContextualDates.ThisQuarter,
    ContextualDates.ThisYear,
    ContextualDates.LastWeek,
    ContextualDates.LastMonth,
    ContextualDates.LastQuarter,
    ContextualDates.LastYear,
    ContextualDates.NextWeek,
    ContextualDates.NextMonth,
    ContextualDates.NextQuarter,
    ContextualDates.NextYear,
];

export function setQuarters(month: number, year: number): ISearchFilterDaterangeValue {
    let startDate: Date;
    // month refers to the month +- 3 months based on current quarter, last quarter, or next quarter
    if (month < Months.April) {
        startDate = new Date(year, Months.January, 1); // Jan 1st
    } else if (month < Months.July && month >= Months.April) {
        startDate = new Date(year, Months.April, 1); // April 1st
    } else if (month < Months.October && month >= Months.July) {
        startDate = new Date(year, Months.July, 1); // July 1st
    } else if (month < 12 && month >= Months.October) {
        startDate = new Date(year, Months.October, 1); // October 1st
    }
    let endDate = new Date(startDate.getTime()); // copy date
    endDate.setMonth(startDate.getMonth() + 3); // three months from now
    endDate.setDate(0); // last day of previous month
    return { startDate, endDate };
}
