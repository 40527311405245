import { INumericAddOn } from './numeric-add-on';
import { ITimepickerOptions } from './time-picker-options';
import { IDatepickerOptions } from './date-picker-options';
import { IColorpickerOptions } from './color-picker-options';
import { ITypeAheadOptions } from './type-ahead-options';
import { IWysiwygComponentConfig } from '@mt-ng2/wysiwyg-control';
import { InputTypes, SelectInputTypes } from '../../form-elements/form-elements.library';
import { NumericInputTypes } from '../../form-elements/form-numeric/form-numeric.component';
import { ConvertNumberFunction, NumberFormatDisplayFunction } from '@mt-ng2/numeric-control';

export interface IDynamicFieldType {
    fieldType: DynamicFieldTypes;
    inputType?: InputTypes | NumericInputTypes | SelectInputTypes;
    scale?: number;
    doNotAllowZero?: boolean;
    numericAddOn?: INumericAddOn;
    numericFunctions?: Partial<IDynamicFieldTypeNumericFunctions>;
    allowInternationalAddresses?: boolean;
    maxToShowInSelectedText?: number;
    showSelectAllButtons?: boolean;
    multiselectAutoClose?: boolean | 'outside' | 'inside';
    timepickerOptions?: ITimepickerOptions;
    datepickerOptions?: IDatepickerOptions;
    colorpickerOptions?: IColorpickerOptions;
    typeAheadOptions?: ITypeAheadOptions;
    allowNullableOption?: boolean;
    wysiwygOptions?: IWysiwygComponentConfig;
}

export enum DynamicFieldTypes {
    Input = 1,
    Numeric = 2,
    Checkbox = 3,
    Password = 4,
    Select = 5,
    MtAddress = 6,
}

export interface IDynamicFieldTypeNumericFunctions {
    numberFormatDisplay: NumberFormatDisplayFunction;
    convertFromDisplayToForm: ConvertNumberFunction;
    convertFromFormToDisplay: ConvertNumberFunction;
}
