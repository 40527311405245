import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';

export class NotesEntityListConfig extends EntityListConfig {

    constructor(disableSort?: boolean) {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Title',
                    sort: {
                        disableSort: disableSort,
                    },
                }),
                new EntityListColumn({
                    accessors: ['NoteText'],
                    bindToInnerHtml: true,
                    name: 'Content',
                    sort: {
                        disableSort: disableSort,
                    },
                }),
            ],
            delete: {
                confirm: true,
                width: 50,
            },
        };
        super(listConfig);
    }
}
