import { Directive, ElementRef, OnInit, HostListener } from '@angular/core';
import { formatPhone } from '@mt-ng2/format-functions';

@Directive({
    selector: '[phoneMask]',
})
export class PhoneMaskDirective implements OnInit {
    private el: HTMLInputElement;
    constructor(private elementRef: ElementRef) {
        this.el = this.elementRef.nativeElement;
    }

    ngOnInit(): void {
        this.el.value = formatPhone(this.el.value);
    }

    @HostListener('input', ['$event.target.value'])
    onInput(value): void {
        this.el.value = formatPhone(value.replace(/\D/g, ''));
    }
}
