import {
    ComponentFactoryResolver,
    Directive,
    Input,
    OnInit,
    ViewContainerRef,
    Output,
    EventEmitter,
    OnDestroy,
    Inject,
    ComponentRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { FormTextboxComponent } from '../form-elements/form-textbox/form-textbox.component';
import { FormNumericComponent } from '../form-elements/form-numeric/form-numeric.component';
import { FormPasswordComponent } from '../form-elements/form-password/form-password.component';
import { FormCheckBoxComponent } from '../form-elements/form-checkbox/form-checkbox.component';
import { FormMtAddressComponent } from '../form-elements/form-mt-address/form-mt-address.component';
import { IFormElementEvents } from '../form-elements/form-elements';
import { DynamicField } from '../libraries/dynamic-field.library';
import { DynamicFormModuleConfig } from '../libraries/dynamic-form-module.config';
import { FormTypeAheadComponent } from '../form-elements/form-type-ahead/form-type-ahead.component';
import { FormPhoneComponent } from '../form-elements/form-phone/form-phone.component';
import { FormTextareaComponent } from '../form-elements/form-textarea/form-textarea.component';
import { InputTypes, SelectInputTypes } from '../form-elements/form-elements.library';
import { FormDatePickerComponent } from '../form-elements/form-date-picker/form-date-picker.component';
import { FormTimePickerComponent } from '../form-elements/form-time-picker/form-time-picker.component';
import { FormDatetimePickerComponent } from '../form-elements/form-datetime-picker/form-datetime-picker.component';
import { FormColorPickerComponent } from '../form-elements/form-color-picker/form-color-picker.component';
import { FormDateInputComponent } from '../form-elements/form-date-input/form-date-input.component';
import { FormDateTimeInputComponent } from '../form-elements/form-datetime-input/form-datetime-input.component';
import { FormTagsComponent } from '../form-elements/form-tags/form-tags.component';
import { FormWYSIWYGComponent } from '../form-elements/form-wysiwyg/form-wysiwyg.component';
import { FormDropdownComponent } from '../form-elements/form-dropdown/form-dropdown.component';
import { FormMultiSelectDropdownComponent } from '../form-elements/form-multi-select-dropdown/form-multi-select-dropdown.component';
import { FormOldStyleMultiSelectDropdownComponent } from '../form-elements/form-old-stlye-multi-select-dropdown/form-old-style-multi-select-dropdown.component';
import { FormRadioButtonListComponent } from '../form-elements/form-radio-button-list/form-radio-button-list.component';
import { FormOldStyleRadioButtonListComponent } from '../form-elements/form-old-style-radio-button-list/form-old-style-radio-button-list.component';
import { DynamicFieldTypes } from '../libraries/interfaces/dynamic-field-type';

@Directive({
    selector: '[appDynamicField]',
})
export class DynamicFieldDirective implements OnInit, OnDestroy, IFormElementEvents {
    @Input()
    config: DynamicField;

    @Input()
    group: FormGroup;

    @Output() blur: EventEmitter<any> = new EventEmitter<any>();
    @Output() focus: EventEmitter<any> = new EventEmitter<any>();
    @Output() valueChanges: EventEmitter<any> = new EventEmitter<any>();

    subscriptions: Subscription = new Subscription();
    component: ComponentRef<any>;

    constructor(private resolver: ComponentFactoryResolver, private container: ViewContainerRef, public moduleConfig: DynamicFormModuleConfig) {}

    ngOnInit(): void {
        const component = this.getComponent(this.config, this.moduleConfig);
        const factory = this.resolver.resolveComponentFactory<any>(component);
        this.component = this.container.createComponent(factory);
        this.component.instance.config = this.config;
        this.component.instance.parentGroup = this.group;

        // wiring up the events that can be passed through
        const instance: IFormElementEvents = this.component.instance;
        if (instance.blur?.subscribe) {
            this.subscriptions.add(
                instance.blur.subscribe(() => {
                    this.blur.emit();
                }),
            );
        }
        if (instance.focus?.subscribe) {
            this.subscriptions.add(
                instance.focus.subscribe(() => {
                    this.focus.emit();
                }),
            );
        }
        if (instance.valueChanges?.subscribe) {
            this.subscriptions.add(
                instance.valueChanges.subscribe((value) => {
                    this.valueChanges.emit(value);
                }),
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Determines which form-element component to resolve based on the
     * config input
     */
    getComponent(dynamicField: DynamicField, config: DynamicFormModuleConfig): any {
        // check for custom component designated in the DynamicField
        if (dynamicField.component) {
            return dynamicField.component;
        }
        // check for module config custom component function
        if (config?.customFormComponentFunction) {
            const customFormComponent = config.customFormComponentFunction(dynamicField);
            if (customFormComponent) {
                return customFormComponent;
            }
        }
        // else get via our standard control mapping
        const fieldType = dynamicField.type.fieldType;
        if (fieldType === DynamicFieldTypes.Checkbox) {
            return FormCheckBoxComponent;
        }
        if (fieldType === DynamicFieldTypes.Input) {
            const inputType = dynamicField.type.inputType as InputTypes;
            switch (inputType) {
                case InputTypes.Textbox:
                    return FormTextboxComponent;
                case InputTypes.Textarea:
                    return FormTextareaComponent;
                case InputTypes.Phone:
                    return FormPhoneComponent;
                case InputTypes.Datepicker:
                    return FormDatePickerComponent;
                case InputTypes.Timepicker:
                    return FormTimePickerComponent;
                case InputTypes.DateTimepicker:
                    return FormDatetimePickerComponent;
                case InputTypes.Colorpicker:
                    return FormColorPickerComponent;
                case InputTypes.DateInput:
                    return FormDateInputComponent;
                case InputTypes.DateTimeInput:
                    return FormDateTimeInputComponent;
                case InputTypes.Tags:
                    return FormTagsComponent;
                case InputTypes.WYSIWYG:
                    return FormWYSIWYGComponent;
                default:
                    return FormTextboxComponent;
            }
        }
        if (fieldType === DynamicFieldTypes.Numeric) {
            return FormNumericComponent;
        }
        if (fieldType === DynamicFieldTypes.Password) {
            return FormPasswordComponent;
        }
        if (fieldType === DynamicFieldTypes.Select) {
            const selectType = dynamicField.type.inputType as SelectInputTypes;
            switch (selectType) {
                case SelectInputTypes.Dropdown:
                    return FormDropdownComponent;
                case SelectInputTypes.MultiselectDropdown:
                    return FormMultiSelectDropdownComponent;
                case SelectInputTypes.RadioButtonList:
                    return FormRadioButtonListComponent;
                case SelectInputTypes.OldStyleRadioButtonList:
                    return FormOldStyleRadioButtonListComponent;
                case SelectInputTypes.OldStyleMultiselectDropdown:
                    return FormOldStyleMultiSelectDropdownComponent;
                case SelectInputTypes.TypeAhead:
                    return FormTypeAheadComponent;
                default:
                    if ((dynamicField.options?.length ?? 0) <= 6) {
                        return FormRadioButtonListComponent;
                    }
                    return FormDropdownComponent;
            }
        }
        if (fieldType === DynamicFieldTypes.MtAddress) {
            return FormMtAddressComponent;
        }
    }
}
