import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'entity-list-pagination',
    template: `
        <ngb-pagination
            [collectionSize]="total"
            [(page)]="currentPage"
            [pageSize]="itemsPerPage"
            [maxSize]="5"
            (pageChange)="currentPageChange.emit(currentPage); pageChange.emit($event)"
        ></ngb-pagination>
    `,
})
export class EntityListPaginationComponent {
    @Input() total: number;
    @Input() itemsPerPage: number;
    /**
     * the current page value
     *
     * this value supports two-way binding
     */
    @Input() currentPage: number;
    @Output() currentPageChange = new EventEmitter<number>();
    @Output() pageChange = new EventEmitter<number>();
}
