import { Component, Injector } from '@angular/core';
import { CustomFormControlComponentBase } from '../custom-form-component.base';
import { formatPhone } from '@mt-ng2/format-functions';

@Component({
    styles: [
        `
            .inside-box-maxlength {
                position: relative !important;
                bottom: -13px;
                top: -13px;
                margin-bottom: -13px;
                right: 3px;
                font-size: 70%;
            }
        `,
    ],
    templateUrl: './form-phone.component.html',
})
export class FormPhoneComponent extends CustomFormControlComponentBase {
    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.subscribeToStatusChanges();
        this.subscribeToValueChanges();
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.formatNativePhoneElement(<string>this.config.value);
    }

    subscribeToStatusChanges(): void {
        this.subscriptions.add(
            this.getControl().statusChanges.subscribe((status) => {
                this.inputElement.nativeElement.disabled = status === 'DISABLED';
            }),
        );
    }

    subscribeToValueChanges(): void {
        this.subscriptions.add(
            this.getControl().valueChanges.subscribe((value) => {
                this.formatNativePhoneElement(value);
            }),
        );
    }

    formatNativePhoneElement(value: string): void {
        this.inputElement.nativeElement.value = formatPhone(value);
    }

    onBlur(): void {
        if (this.getControl().enabled) {
            this.setPhoneValue();
            this._isFocused = false;
            this.blur.emit(true);
        }
    }

    setPhoneValue(): void {
        const control = this.getControl();
        const phoneControl = this.inputElement;
        let value = phoneControl.nativeElement.value;
        value = value.replace(/\D/g, '');
        if (control.value !== value) {
            control.setValue(value);
            control.markAsDirty();
        }
    }
}
