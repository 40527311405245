import { ColumnShowOnPrint, IEntityListDynamicCellComponentGeneric } from './entity-list-column.library';
import { IMtConfirmOptions } from '@mt-ng2/alerts-module';
import { ComponentErrorHandler } from '@mt-ng2/component-error-handler';
import { Type } from '@angular/core';

export type EntityListDeleteColumnHtmlFunction = (entity: any) => string;
export type EntityListDeleteConfirmFunction = (entity: any) => IMtConfirmOptions;

/**
 * defines a delete column for the EntityListComponent
 * @param confirm perform a confirm within the entity list component
 * @param width how wide to make the column
 */
export interface IEntityListDeleteColumn {
    confirm?: boolean | IMtConfirmOptions | EntityListDeleteConfirmFunction;
    headerText?: string;
    columnHtml?: string | EntityListDeleteColumnHtmlFunction;
    showOnPrint?: ColumnShowOnPrint;
    width?: number;
    component?: Type<IEntityListDynamicCellComponentGeneric<any>>;
}

function instanceOfIEntityListDeleteColumn(object: any): object is IEntityListDeleteColumn {
    if (typeof object !== 'object') {
        return false;
    }
    return (
        'confirm' in object ||
        'headerText' in object ||
        'columnHtml' in object ||
        'showOnPrint' in object ||
        'width' in object ||
        'component' in object
    );
}

/**
 * defines a delete column for the EntityListComponent
 * @param confirm perform a confirm within the entity list component
 * @param width how wide to make the column
 */
export class EntityListDeleteColumn implements IEntityListDeleteColumn {
    private errorHandler = new ComponentErrorHandler('EntityListDeleteColumn', '@mt-ng2/entity-list-module');

    public confirm: IMtConfirmOptions | EntityListDeleteConfirmFunction;
    public headerText: string;
    public columnHtml: string | EntityListDeleteColumnHtmlFunction;
    public width: number;
    public showOnPrint: ColumnShowOnPrint;
    public component: Type<IEntityListDynamicCellComponentGeneric<any>>;

    constructor(confirm?: boolean | IMtConfirmOptions | IEntityListDeleteColumn, width?: number, showOnPrint?: ColumnShowOnPrint) {
        this.deprecatedConstructorParamsCheck(width, showOnPrint);

        const constructorParams: IEntityListDeleteColumn = instanceOfIEntityListDeleteColumn(confirm)
            ? <IEntityListDeleteColumn>confirm
            : {
                  confirm: confirm,
                  showOnPrint: showOnPrint,
                  width: width,
              };

        if (typeof constructorParams.confirm === 'undefined' || constructorParams.confirm === true) {
            this.confirm = {};
        } else if (constructorParams.confirm !== false) {
            this.confirm = constructorParams.confirm;
        } else {
            this.confirm = null;
        }
        this.width = typeof constructorParams.width !== 'undefined' ? constructorParams.width : 50;
        this.showOnPrint = typeof constructorParams.showOnPrint !== 'undefined' ? constructorParams.showOnPrint : ColumnShowOnPrint.HideOnPrint;
        this.headerText = typeof constructorParams.headerText !== 'undefined' ? constructorParams.headerText : 'Delete';
        this.columnHtml =
            typeof constructorParams.columnHtml !== 'undefined'
                ? constructorParams.columnHtml
                : '<i class="fa fa-2x fa-fw fa-trash icon-main" aria-hidden="true"></i>';
        this.component = typeof constructorParams.component !== 'undefined' ? constructorParams.component : null;
    }

    private deprecatedConstructorParamsCheck(width: number, showOnPrint: ColumnShowOnPrint): void {
        if (typeof width !== 'undefined') {
            this.errorHandler.addWarning(
                'width constructor parameter deprecated August-2018 with release 2.1.1 - use IEntityListDeleteColumn as your first parameter instead',
            );
        }
        if (typeof showOnPrint !== 'undefined') {
            this.errorHandler.addWarning(
                'showOnPrint constructor parameter deprecated August-2018 with release 2.1.1 - use IEntityListDeleteColumn as your first parameter instead',
            );
        }
    }

    public getColumnHtml(entity: any): string {
        if (typeof this.columnHtml === 'function') {
            return this.columnHtml(entity);
        } else {
            return this.columnHtml;
        }
    }

    public getConfirm(entity: any): IMtConfirmOptions {
        if (typeof this.confirm === 'function') {
            return this.confirm(entity);
        } else {
            return this.confirm;
        }
    }

    showColumn(printMode: boolean): boolean {
        let show = true;
        const showOnPrint = this.showOnPrint;
        if (showOnPrint === null || showOnPrint === undefined) {
            return show;
        }

        if (printMode) {
            show = showOnPrint === ColumnShowOnPrint.ShowAlways || showOnPrint === ColumnShowOnPrint.ShowOnlyOnPrint;
        } else {
            show = showOnPrint === ColumnShowOnPrint.ShowAlways || showOnPrint === ColumnShowOnPrint.HideOnPrint;
        }
        return show;
    }
}
