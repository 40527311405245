import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injector, NgZone } from '@angular/core';

import { NotificationsService } from '@mt-ng2/notifications-module';

import { IErrorsModuleConfig, ErrorsModuleConfigToken } from './config/errors-module-config';
import { IsToastableErrorGuard } from './libraries/toastable-error';
import { NotificationTypes } from './libraries/notification-types';
import { ConcurrencyService } from './services/concurrency.service';

import { IModalOptions, ModalService } from '@mt-ng2/modal-module';

export abstract class GlobalErrorHandler implements ErrorHandler {
    private notificationsService: NotificationsService;

    protected get concurrencyService(): ConcurrencyService {
        return this.injector.get(ConcurrencyService);
    }

    protected get errorsModuleConfig(): IErrorsModuleConfig {
        return this.injector.get(ErrorsModuleConfigToken, null);
    }

    protected get modalService(): ModalService {
        return this.injector.get(ModalService);
    }

    protected get zone(): NgZone {
        return this.injector.get(NgZone);
    }

    public config: IErrorsModuleConfig = {
        customErrorHandlerFunction: (this.errorsModuleConfig && this.errorsModuleConfig.customErrorHandlerFunction) || null,
        loginErrorMessage:
            (this.errorsModuleConfig && this.errorsModuleConfig.loginErrorMessage) || `I've got a bad feeling about this.  Please re-login.`,
        serviceUnavailableMessage:
            (this.errorsModuleConfig && this.errorsModuleConfig.serviceUnavailableMessage) ||
            `Application is unavailable, please try again in a few minutes`,
        unhandledErrorMessage: (this.errorsModuleConfig && this.errorsModuleConfig.unhandledErrorMessage) || `An unhandled error has occurred.`,
    };

    constructor (public injector: Injector) { }

    handleError(error: any): void {
        if (!this.notificationsService) {
            this.notificationsService = this.injector.get(NotificationsService);
        }

        const toastroptions = {
            enableHtml: true,
            preventDuplicates: true,
            preventOpenDuplicates: true,
        };
        console.log(error);
        if (this.config.customErrorHandlerFunction) {
            const toastableError: any = this.config.customErrorHandlerFunction(error);
            if (toastableError && IsToastableErrorGuard(toastableError)) {
                // check if toastableError is of interface IToastableError
                switch (toastableError.notificationType) {
                    case NotificationTypes.Info:
                        this.notificationsService.info(toastableError.errorMessage);
                        return;
                    case NotificationTypes.Warning:
                        this.notificationsService.warning(toastableError.errorMessage);
                        return;
                    case NotificationTypes.Success:
                        this.notificationsService.success(toastableError.errorMessage);
                        return;
                    case NotificationTypes.Error:
                    default:
                        this.notificationsService.error(toastableError.errorMessage);
                        return;
                }
            }
        }
        if (error.originalError instanceof HttpErrorResponse && error.originalError.status === 401) {
            // Using an Object.Assign to load both error options.
            // This loads the Ionic and Portal(toastrOptions).
            // The Notification strategies handle parsing them out correctly
            this.notificationsService.error(
                this.config.loginErrorMessage,
                'Error',
                Object.assign(
                    {
                        header: 'Error',
                    },
                    toastroptions,
                ),
            );
        } else if (error.originalError instanceof HttpErrorResponse && error.originalError.status === 409) {
            // Handle Concurrency
            const concurrencyModalOptions: IModalOptions = {
                cancelButtonClass: 'btn btn-swal-override',
                cancelButtonText: 'Update',
                confirmButtonClass: 'btn btn-swal-override',
                confirmButtonText: 'Overwrite',
                showCancelButton: true,
                text: 'Would you like to reload with the updated data or overwrite with your changes?',
                title: 'Someone else has changed the data since you made your changes.',
                type: 'warning',
            };
            this.modalService.showModal(concurrencyModalOptions).subscribe((result) => {
                if (result.value) {
                    const version = error.originalError.error.Version;
                    error.formObject.Version = version;
                    this.zone.runGuarded((): void => {
                        this.overWrite(error);
                    });
                } else if (result.dismiss) {
                    window.location.reload();
                }
            });
        } else if (error.originalError instanceof HttpErrorResponse && error.originalError.status === 503) {
            // Using an Object.Assign to load both error options.
            // This loads the Ionic and Portal(toastrOptions).
            // The Notification strategies handle parsing them out correctly
            this.notificationsService.info(
                this.config.serviceUnavailableMessage,
                Object.assign(
                    {
                        header: 'Info',
                    },
                    toastroptions,
                ),
            );
        } else if (error && error.status && error.status === 503) {
            // Using an Object.Assign to load both error options.
            // This loads the Ionic and Portal(toastrOptions).
            // The Notification strategies handle parsing them out correctly
            this.notificationsService.info(
                this.config.serviceUnavailableMessage,
                Object.assign(
                    {
                        header: 'Info',
                    },
                    toastroptions,
                ),
            );
        } else if (error.error && error.error.ModelState) {
            // Handle Model State Errors
            let errString = '';
            let modelErrors = Object.keys(error.error.ModelState).map((key) => {
                return error.error.ModelState[key];
            });
            modelErrors.forEach((err) => {
                // The validators throw line breaks in between, but toast prefers br tags
                err = err.toString().replace(/(\r\n|\n|\r)/g, '<br />');
                errString += err;
            });
            this.notificationsService.error(
                errString,
                'Error',
                Object.assign(
                    {
                        header: 'Error',
                    },
                    toastroptions,
                ),
            );
        } else {
            // Catch All
            this.notificationsService.error(
                this.config.unhandledErrorMessage,
                'Error',
                Object.assign(
                    {
                        header: 'Error',
                    },
                    toastroptions,
                ),
            );
        }
    }

    overWrite(error: any): void {
        this.concurrencyService.overWriteEntity(error).subscribe(
            () => {
                this.notificationsService.success('Overwritten Successfully', {
                    onHidden: function (): void {
                        window.location.reload();
                    },
                    timeOut: 1500,
                });
            },
            () => {
                this.notificationsService.error('Failed to overwrite.');
            },
        );
    }
}
