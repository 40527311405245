import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { sortByProperty } from '@mt-ng2/common-functions';

import { forkJoin, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IState } from '@model/interfaces/state';
import { ICountry } from '@model/interfaces/country';
import { IStateMetaItem } from '@model/interfaces/custom/state-meta-item';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';
import { ActivatedRoute } from '@angular/router';
import { IReferralType } from '@model/interfaces/referral-type';

import { SharedFunctionsService } from '../../common/services/shared-functions-service';
@Injectable()
export class CommonService {
    private _states: IState[];
    private _statesMeta: IStateMetaItem[];
    private _usStatesMeta: IStateMetaItem[];
    private _countries: ICountry[];
    private _countriesMeta: ICountryMetaItem[];
    sharedFunctionsService: SharedFunctionsService;

    constructor(private http: HttpClient) {

        this.sharedFunctionsService = new SharedFunctionsService();
    }

    getStates(): Observable<IState[]> {
        if (!this._states) {
            return this.getAllStates().pipe(
                map((answer) => {
                    return answer.filter((item) => this.sharedFunctionsService.countryHasStatesList(item.CountryCode, null));
                }),
            );
        } else {
            return of(this._states);
        }
    }

    getAllStates(): Observable<IState[]> {
        if (!this._states) {
            return this.http.get<IState[]>('/options/states').pipe(
                tap((answer) => {
                    sortByProperty(answer, 'Name');
                    this._states = answer;
                }),
            );
        } else {
            return of(this._states);
        }
    }

    getCountries(): Observable<ICountry[]> {
        if (!this._countries) {
            return this.http.get<ICountry[]>('/options/countries').pipe(
                tap((answer) => {
                    this._countries = answer;
                }),
            );
        } else {
            return of(this._countries);
        }
    }

    getAccountManagerRegions(): ICountryMetaItem[] {
        if (!this._countriesMeta) {
            return this.getCountryMetaItems().filter((country) => country.IsAccountRegion);
        } else {
            return this._countriesMeta.filter((country) => country.IsAccountRegion);
        }
    }

    getStateMetaItems(): IStateMetaItem[] {
        if (!this._usStatesMeta) {
            this._usStatesMeta = this._states
                .map((state, i) => {
                    if (this.sharedFunctionsService.countryHasStatesList(state.CountryCode, null)) {
                        return {
                            CountryCode: state.CountryCode,
                            Id: i,
                            Name: state.Name,
                            StateCode: state.StateCode,
                        };
                    }
                    return null;
                })
                .filter((state) => state);
        }
        return this._usStatesMeta;
    }

    getAllStateMetaItems(): IStateMetaItem[] {
        if (!this._statesMeta) {
            this._statesMeta = this._states.map((state, i) => {
                return {
                    CountryCode: state.CountryCode,
                    Id: i,
                    Name: state.Name,
                    StateCode: state.StateCode,
                };
            });
        }
        return this._statesMeta;
    }

    getCountryMetaItems(): ICountryMetaItem[] {
        if (!this._countriesMeta) {
            this._countriesMeta = this._countries.map((country, i) => {
                return {
                    Alpha3Code: country.Alpha3Code,
                    CountryCode: country.CountryCode,
                    Id: i,
                    IsAccountRegion: country.IsAccountRegion,
                    Name: country.Name,
                };
            });
        }
        return this._countriesMeta;
    }

    stripHtml(stringWithHTML: string): string {
        return stringWithHTML
            .trim()
            .replace(/<("[^"]*"|'[^']*'|[^'">])*>/gi, '')
            .replace(/^\s+|\s+$/g, '')
            .replace(/(\r\n|\n|\r)/gm, '');
    }

    getCountryId(countryCode: string, countries: ICountryMetaItem[]): number | null {
        let Id = null;

        if (countryCode !== null) {
            let country = countries.find((c) => c.CountryCode.trim() === countryCode.trim());
            if (country) {
                Id = country.Id;
            }

        }
        return Id;
    }

    getCountryNameByCode(countryCode: string, countries: ICountryMetaItem[]): string {
        let name = '';

        if (countryCode !== null) {
            let country = countries.find((c) => c.CountryCode.trim() === countryCode.trim());
            if (country) {
                name = country.Name;
            }

        }
        return name;
    }

    getCountryIdByCode(countryCode: string, countries: ICountryMetaItem[]): number | null {
        let Id = null;

        if (countryCode !== null) {
            let country = countries.find((c) => c.CountryCode.trim() === countryCode.trim());
            if (country) {
                Id = country.Id;
            }

        }
        return Id;
    }

    getCountryCodeById(Id: number, countries: ICountryMetaItem[]): string {
        let countryCode = '';
        let country = countries.find((c) => c.Id === Id);

        if (country) {
            countryCode = country.CountryCode;
        }
        return countryCode;
    }

    //
    // Single point to check whether or not the country code is one that we have a defined list of states for
    //
    countryIdHasStatesList(countryId: number, countries: ICountryMetaItem[], states: IStateMetaItem[]): boolean {
        const countryCode = this.getCountryCodeById(countryId, countries);
        return this.countryHasStatesList(countryCode, states);
    }

    //
    // Single point to check whether or not the country code is one that we have a defined list of states for
    //
    countryHasStatesList(countryCode: string, states: IStateMetaItem[]): boolean {
        let hasStatesList = false;
        if (countryCode) {
            const index = (states.findIndex((s) => s.CountryCode.trim() === countryCode.trim()));
            hasStatesList = (index >= 0);
        }
        return hasStatesList;
    }

    getStateId(countryCode: string, countries: ICountryMetaItem[], stateCode: string, states: IStateMetaItem[]): number | null {
        let Id = null;

        if (countryCode !== null) {
            let country = countries.find((c) => c.CountryCode.trim() === countryCode.trim());
            if (country) {
                if (stateCode !== null) {
                    let state = states.find((c) => c.CountryCode.trim() === country.CountryCode.trim() && c.StateCode.trim() === stateCode.trim());
                    if (state) {
                        Id = state.Id;
                    }
                }
            }
        }
        return Id;
    }

    getStatesList(countryCode: string, states: IStateMetaItem[]): IStateMetaItem[] {

        let countryStates = [] as IStateMetaItem[];
        if (countryCode !== null) {
            countryStates = states.filter((s) => s.CountryCode.trim() === countryCode.trim());
        }
        return countryStates;
    }

    getStateCodeById(Id: number, states: IStateMetaItem[]): string | null {
        let stateCode = null;
        const state = states.find((s) => s.Id === Id);

        if (state) {
            stateCode = state.StateCode;
        }

        return stateCode;
    }

    filterStatesForCounty(countryCode: string, states: IStateMetaItem[]): IStateMetaItem[] {

        let statesForCountry: IStateMetaItem[] = [];

        if (this.countryHasStatesList(countryCode, states)) {
            let emptyState: IStateMetaItem = { Id: null, CountryCode: '', StateCode: '', Name: '' };
            statesForCountry.push(emptyState);
            states.filter((s) => s.CountryCode.trim() === countryCode).forEach((s) => statesForCountry.push(s));
        }

        return statesForCountry;
    }

    //
    // Indicate that the Email and Confirm Email fields are the same as one another
    //
    emailsAreEqual(email: string, confirmEmail:  string): boolean {

        let isValid = null;

        if (email && confirmEmail) {
            isValid = (email.toString().toLowerCase().trim() === confirmEmail.toString().toLowerCase().trim());
        }

        return isValid;
    }

    //
    // Determine if the selected referral requires additional comments
    //
    otherReferralType(referralTypeId: number, referralTypes: IReferralType[]): boolean {
        let hasComments = false;
        let referralType = referralTypes.find((r) => r.Id === referralTypeId);
        if (referralType) {
            hasComments = referralType.HasComments;
        }
        return hasComments;
    }
}
