import { IMatchingStrategy } from './MatcherFactory';

import { getValue } from '../type-ahead.library';
import { ComponentErrorHandler } from '@mt-ng2/component-error-handler';
import { MatchingStrategy } from '@mt-ng2/type-ahead-control-config';

export class EndsWithMatcher implements IMatchingStrategy {
    private _strategy: MatchingStrategy;

    constructor(strategy: MatchingStrategy) {
        this._strategy = strategy;
    }

    getCurrentStrategy(): MatchingStrategy {
        return this._strategy;
    }

    getHighlightedParts(resultStr: string, termLC: string): string[] {
        const resultLC = resultStr.toLowerCase();
        if (resultLC.endsWith(termLC)) {
            let result = [];
            const lastInstance = resultStr.substr(resultLC.lastIndexOf(termLC));
            const restOfTheString = resultStr.substr(0, resultLC.lastIndexOf(termLC));
            result.push(restOfTheString);
            result.push(lastInstance);
            return result;
        }
    }

    getItems(items: any[], searchText: string, errorHandler: ComponentErrorHandler, nameProperty = ''): any[] {
        return items.filter((item) => {
            if (!getValue(item, nameProperty, errorHandler)) {
                return false;
            }
            if (!searchText) {
                return true;
            }

            return getValue(item, nameProperty, errorHandler).toLowerCase().endsWith(searchText.toLowerCase());
        });
    }
}
