import { EntityListComponent } from '../components/entity-list.component';
import { SortDirection } from './entity-list-column.library';

export interface IEntityListApi {
    /**
     * access to methods for selected items
     */
    selectedItems: {
        /**
         * clear all selected items
         */
        clear: () => void;
        /**
         * get a copy of the selected items
         */
        get: () => any[];
        /**
         * set the selected items, this will overwrite any previously selected items
         */
        set: (value: any[]) => void;
    };
    sorting: {
        /**
         * set current sorting, this will not trigger `onColumnSorted` event from entity list
         */
        set: (sortPropertyName: string, direction: SortDirection) => void;
    };
}

export function buildEntityListApi(component: EntityListComponent): IEntityListApi {
    const api: Partial<IEntityListApi> = {};

    const clearSelectedItems = (): void => {
        component.selectHelper.selectedEntities = [];
        component.cdr.detectChanges();
    };

    const getSelectedItems = (): any[] => {
        return JSON.parse(JSON.stringify(component.selectHelper.selectedEntities));
    };

    const setSelectedItems = (value: any[]): void => {
        component.selectHelper.selectedEntities = value;
        component.cdr.detectChanges();
    };

    api.selectedItems = {
        clear: clearSelectedItems,
        get: getSelectedItems,
        set: setSelectedItems,
    };

    api.sorting = {
        set: component.setColumnSorting.bind(component),
    };

    return api as IEntityListApi;
}
