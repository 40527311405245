import { Component, EventEmitter, Input, OnInit, Output, Injector, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { FileItem } from 'ng2-file-upload';
import { saveAs } from 'file-saver';

import { IEntityRouteConfig } from '@mt-ng2/entity-components-base';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { SortDirection } from '@mt-ng2/entity-list-module';
import { ComponentErrorHandler } from '@mt-ng2/component-error-handler';
import { IDocumentControlModuleConfig } from '@mt-ng2/document-control-config';
import { EntityComponentsDocumentsModuleConfig } from './libraries/module-config.library';

export interface IDocument {
    Id: number;
    Name: string;
    DateUpload: Date;
    UploadedBy?: number;
    FilePath: string;
}

export interface IDocumentUploadControlConfig extends IDocumentControlModuleConfig {}

export interface IHasDocuments {
    documentUploadControlConfig?: IDocumentUploadControlConfig;

    getDocument(entityId: number, documentId: number): Observable<Blob>;

    getDocuments(entityId: number, searchParams: SearchParams): Observable<HttpResponse<IDocument[]>>;

    saveDocument(entityId: number, file: File): Observable<IDocument>;

    deleteDocument(entityId: number, documentId: number): Observable<object>;
}

@Component({
    selector: 'app-common-documents',
    template: `
        <ng-container *componentErrorHandler="errorHandler">
            <div class="miles-card padded" *ngIf="!showUploadArea">
                <h4>Documents</h4>
                <div *ngIf="documentArray.length">
                    <div class="row">
                        <div *ngFor="let document of documentArray; let i = index">
                            <div class="col-xs-4 miles-inline-list">
                                <span class="fa fa-fw {{ document.Name | fileicon }}" (click)="downloadDoc(i)"></span>
                                <span (click)="downloadDoc(i)"> {{ document.Name }}</span>
                                <span *ngIf="canEdit" class="fa fa-fw fa-trash text-danger delete" (mtConfirm)="deleteDoc(i)"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="showTotal" class="pull-right max-of-total">
                    <span>{{ _max }} of {{ _total }}</span>
                </div>
                <i *ngIf="!documentArray.length">No Documents</i>
                <div *ngIf="canAdd" class="fab-wrap">
                    <button type="button" class="btn btn-primary btn-fab-md btn-fab-center" (click)="isEditing = true">
                        <span class="fa fa-plus"></span>
                    </button>
                </div>
                <div *ngIf="showTotal" class="show-on-hover">
                    <a (click)="seeAll()" class="btn btn-primary btn-flat see-all"
                        >see all
                        <span class="badge">{{ _total }}</span>
                    </a>
                </div>
            </div>
            <div [hidden]="!showUploadArea" class="miles-form padded">
                <mt-document
                    *ngIf="documentUploadControlConfig.maxFileSize"
                    [allowedMimeType]="documentUploadControlConfig.allowedMimeType"
                    [maxFileSize]="documentUploadControlConfig.maxFileSize"
                    (onAfterAddingFile)="addDocument($event)"
                    (onAddingFileFailed)="onWhenAddingFileFailed($event)"
                ></mt-document>
                <mt-document
                    *ngIf="!documentUploadControlConfig.maxFileSize"
                    [allowedMimeType]="documentUploadControlConfig.allowedMimeType"
                    (onAfterAddingFile)="addDocument($event)"
                    (onAddingFileFailed)="onWhenAddingFileFailed($event)"
                ></mt-document>

                <br />
                <button type="button" class="btn btn-flat btn-default" (click)="cancel()">Cancel</button>
            </div>
        </ng-container>
    `,
})
export class CommonDocumentsComponent implements OnInit {
    public errorHandler = new ComponentErrorHandler('CommonDocumentsComponent', '@mt-ng2/entity-components-documents');
    protected _isEditing: boolean;
    @Input('isEditing')
    set isEditing(value: boolean) {
        this.errorHandler.checkNull(value, 'isEditing');
        this._isEditing = value;
    }

    _total: number;
    @Input('total')
    set total(value: number) {
        this.errorHandler.checkNull(value, 'total');
        this._total = value;
    }

    _max: number;
    @Input('max')
    set max(value: number) {
        this.errorHandler.checkNull(value, 'max');
        this._max = value;
    }

    protected _canEdit: boolean;
    @Input('canEdit')
    get canEdit(): boolean {
        return this._canEdit;
    }
    set canEdit(value: boolean) {
        this.errorHandler.checkNull(value, 'canEdit');
        this._canEdit = value;
    }

    protected _canAdd: boolean;
    @Input('canAdd')
    get canAdd(): boolean {
        return this._canAdd;
    }
    set canAdd(value: boolean) {
        this.errorHandler.checkNull(value, 'canAdd');
        this._canAdd = value;
    }

    protected _seeAllUrl: string;
    @Input('seeAllUrl')
    set seeAllUrl(value: string) {
        this._seeAllUrl = value;
    }

    protected _documentsPassedIn = false;
    protected _documentArray: IDocument[] = [];
    @Input('documentArray')
    set documentArray(value: IDocument[]) {
        this.errorHandler.checkNull(value, 'documentArray');
        this._documentArray = value;
        this._documentsPassedIn = true;
    }
    get documentArray(): IDocument[] {
        return this._documentArray;
    }

    protected _service: IHasDocuments;
    @Input('service')
    get service(): IHasDocuments {
        if (!this._documentsPassedIn && this._service && this.parentId && this.parentId > 0) {
            return this._service;
        }
        return null;
    }
    set service(value: IHasDocuments) {
        this._service = value;
    }

    protected _documentUploadControlConfig: IDocumentUploadControlConfig;
    @Input('documentUploadControlConfig')
    get documentUploadControlConfig(): IDocumentUploadControlConfig {
        return this._documentUploadControlConfig;
    }
    set documentUploadControlConfig(value: IDocumentUploadControlConfig) {
        this._documentUploadControlConfig = value;
    }

    protected _allowedMimeType: string[];
    @Input('allowedMimeType')
    set allowedMimeType(value: string[]) {
        this._allowedMimeType = value;
    }

    @Output('onAddDocument') onAddDocument: EventEmitter<FileItem> = new EventEmitter<FileItem>();
    @Output('onValidateFailed') onValidateFailed: EventEmitter<string> = new EventEmitter<string>();
    @Output('onDeleteDocument') onDeleteDocument: EventEmitter<any> = new EventEmitter<any>();
    @Output('onDownloadDocument') onDownloadDocument: EventEmitter<any> = new EventEmitter<any>();

    parentId: number;

    protected route: ActivatedRoute;
    protected router: Router;
    protected notificationsService: NotificationsService;
    protected claimsService: ClaimsService;
    protected config: EntityComponentsDocumentsModuleConfig;

    constructor(protected injector: Injector) {
        this.route = injector.get(ActivatedRoute);
        this.router = injector.get(Router);
        this.notificationsService = injector.get(NotificationsService);
        this.claimsService = injector.get(ClaimsService);
        this.config = injector.get(EntityComponentsDocumentsModuleConfig);
        this._max = this.config.max;
    }

    ngOnInit(): void {
        this.setVariables();
        this.isEditing = false;
        this.getDocuments();
    }

    /**
     * Assigns local variables to defaults or config properties.
     */
    setVariables(): void {
        const config: IEntityRouteConfig = <IEntityRouteConfig>this.route.parent.snapshot.data;
        if (config && config.entityIdParam) {
            this.parentId = +this.route.parent.snapshot.paramMap.get(config.entityIdParam);
        }
        const service = this.service || (config && config.service) || null;
        if (service) {
            if (typeof service === 'function') {
                this.service = this.injector.get(service);
            }
        }
        if (this._canEdit === undefined) {
            this._canEdit = config && config.claimType ? this.claimsService.hasClaim(config.claimType, [ClaimValues.FullAccess]) : false;
        }
        if (this._canAdd === undefined) {
            this._canAdd = this._canEdit;
        }
        if (this._seeAllUrl === undefined) {
            this._seeAllUrl = (config && config.documentsPath) || 'documents';
        }
        if (this._documentUploadControlConfig === undefined) {
            if (this._service && this._service.documentUploadControlConfig) {
                this._documentUploadControlConfig = this._service.documentUploadControlConfig;
            } else {
                this._documentUploadControlConfig = {};
            }
        }
        this._documentUploadControlConfig.allowedMimeType = this._allowedMimeType ||
            this.documentUploadControlConfig.allowedMimeType || ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'application/zip'];
    }

    get showUploadArea(): boolean {
        return this._isEditing && this._canEdit;
    }

    get showEditButton(): boolean {
        return this._canEdit;
    }

    get showTotal(): boolean {
        return this._total && this._total > this._max;
    }

    seeAll(): void {
        this.router.navigate([`${this._seeAllUrl}`], {
            relativeTo: this.route,
        });
    }

    getDocuments(): void {
        if (this.service) {
            const searchparamProperties: IEntitySearchParams = {
                order: 'DateUpload',
                orderDirection: SortDirection.Desc,
                query: '',
                take: this._max,
            };
            const searchparams = new SearchParams(searchparamProperties);

            this.service.getDocuments(this.parentId, searchparams).subscribe((response) => {
                this._documentArray = response.body;
                this._total = +response.headers.get('X-List-Count');
            });
        }
    }

    onWhenAddingFileFailed(message: string): void {
        if (!this._documentsPassedIn) {
            this.notificationsService.error(message);
        }
        this.onValidateFailed.emit(message);
    }

    addDocument(file: FileItem): void {
        this.isEditing = false;
        if (this._documentsPassedIn) {
            this.onAddDocument.emit(file);
        } else {
            this.saveDocument(file);
        }
    }

    saveDocument(file: FileItem): void {
        if (file) {
            this._service.saveDocument(this.parentId, file._file).subscribe((x: IDocument) => {
                if (this._documentArray.length >= 3) {
                    this._documentArray.splice(0, 1);
                }
                this._documentArray.push(x);
                this._total += 1;
                this.onAddDocument.emit(file);
            });
        }
    }

    cancel(): void {
        this.isEditing = false;
    }

    deleteDoc(index): void {
        if (!this._documentsPassedIn) {
            this._service.deleteDocument(this.parentId, this._documentArray[index].Id).subscribe(() => {
                if (this._total > 3) {
                    this.getDocuments();
                } else {
                    this._documentArray.splice(index, 1);
                    this._total -= 1;
                }
            });
        }
        this.onDeleteDocument.emit(this._documentArray[index]);
    }

    downloadDoc(index): void {
        if (!this._documentsPassedIn) {
            this._service.getDocument(this.parentId, this._documentArray[index].Id).subscribe((x: any) => {
                const thefile = new Blob([<ArrayBuffer>x], {
                    type: 'application/octet-stream',
                });
                saveAs(thefile, this._documentArray[index].Name);
            });
        } else {
            this.onDownloadDocument.emit(this._documentArray[index]);
        }
    }
}
