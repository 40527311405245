import { Component, OnChanges, Input, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { PageTitlesService } from '../services/page-titles.service';

@Component({
    // OnPush ensures only changes in @Input properties cause change detection to run
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'mt-page-title',
    template: `
        {{ pageTitle }}
    `,
})
export class PageTitleComponent implements OnChanges {
    /**
     * the pageTitle to display.  Changes in this input value will trigger
     * calls to the pageTitlesService.setPageTitle() method.
     */
    @Input() pageTitle: string;

    constructor(private pageTitlesService: PageTitlesService) {}

    ngOnChanges(changes: SimpleChanges): void {
        // if the changes include changes to the pageTitle property
        if (changes.pageTitle) {
            // and if there is a value
            if (this.pageTitle) {
                // then call the setPageTitle meothod
                this.pageTitlesService.setPageTitle(this.pageTitle);
            }
        }
    }
}
