import { SweetAlertCustomClass, SweetAlertIcon, SweetAlertOptions, SweetAlertResult } from 'sweetalert2';

export interface IModalOptions extends SweetAlertOptions {
    cancelButtonClass?: string;
    confirmButtonClass?: string;
    denyButtonClass?: string;
    customContainerClass?: string;
    type?: SweetAlertIcon;
}

export class ModalOptions implements IModalOptions {
    customClass: SweetAlertCustomClass;
    text: string;
    title: string;
    icon: SweetAlertIcon;
    cancelButtonText: string;
    confirmButtonText: string;
    denyButtonText: string;
    showCancelButton: boolean;
    showConfirmButton: boolean;
    showDenyButton: boolean;

    constructor(options: IModalOptions = {}) {
        this.customClass = {};
        Object.assign(this, options);
    }

    get cancelButtonClass(): string {
        return this.customClass?.cancelButton;
    }

    set cancelButtonClass(value: string) {
        Object.assign(this.customClass, { cancelButton: value });
    }

    get confirmButtonClass(): string {
        return this.customClass?.confirmButton;
    }

    set confirmButtonClass(value: string) {
        Object.assign(this.customClass, { confirmButton: value });
    }

    get denyButtonClass(): string {
        return this.customClass?.denyButton;
    }

    set denyButtonClass(value: string) {
        Object.assign(this.customClass, { denyButton: value });
    }

    get customContainerClass(): string {
        return this.customClass?.container;
    }

    set customContainerClass(value: string) {
        Object.assign(this.customClass, { container: value });
    }

    get type(): SweetAlertIcon {
        return this.icon;
    }

    set type(value: SweetAlertIcon) {
        this.icon = value;
    }
}

export interface IModalResult extends SweetAlertResult {}

export interface IModalWrapperApi {
    show: () => void;
    close: () => void;
}
