import { DynamicFormModuleConfig } from './../../libraries/dynamic-form-module.config';
import { Component, Injector } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomFormControlComponentBase } from '../custom-form-component.base';
import { DynamicField, DynamicFieldType } from '../../libraries/dynamic-field.library';
import { InputTypes } from '../form-elements.library';
import { DynamicFieldTypes, IDynamicFieldType } from '../../libraries/interfaces/dynamic-field-type';
import { IDynamicField } from '../../libraries/interfaces/dynamic-field';

@Component({
    styles: [
        `
            .indented-date-time-picker {
                margin-left: 15px;
            }
            .inside-box-maxlength {
                position: relative !important;
                bottom: -13px;
                top: -13px;
                margin-bottom: -13px;
                right: 3px;
                font-size: 70%;
            }
        `,
    ],
    templateUrl: './form-datetime-picker.component.html',
})
export class FormDatetimePickerComponent extends CustomFormControlComponentBase {
    /*
        The goal of this component is to make use of the already existing Date and Time pickers.
        Our solution is to render them as two separate dynamic fields by using a wrapper form.
        The wrapper form is not actually exposed to the devs, but instead the control that
        they interface with will have both date and time information.
    */
    wrapperForm: FormGroup;
    dateField: DynamicField;
    timeField: DynamicField;
    disabled = false;

    constructor(injector: Injector, private formBuilder: FormBuilder, private moduleConfig: DynamicFormModuleConfig) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (this.config.value && !(this.config.value instanceof Date)) {
            this.config.value = new Date(this.config.value.toString());
        }

        this.wrapperForm = this.formBuilder.group({
            [this.config.formGroup]: this.formBuilder.group({}),
        });

        const dateVal = (this.config.value as Date) || new Date();
        const timeVal = dateVal.toLocaleTimeString('default', {
            hour12: false,
        });

        this.dateField = new DynamicField({
            formGroup: this.config.formGroup,
            label: 'Date', // TODO LAS: Should these be labeled differently? How could we get separate labels for date and time from the config
            name: 'Date',
            type: new DynamicFieldType({
                datepickerOptions: this.config.type.datepickerOptions,
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Datepicker,
            } as IDynamicFieldType),
            value: dateVal,
        } as IDynamicField);

        this.timeField = new DynamicField({
            formGroup: this.config.formGroup,
            label: 'Time', // TODO LAS: Should these be labeled differently? How could we get separate labels for date and time from the config
            name: 'Time',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Timepicker,
                timepickerOptions: this.config.type.timepickerOptions,
            } as IDynamicFieldType),
            value: timeVal,
        } as IDynamicField);

        this.subscriptions.add(
            this.getControl().statusChanges.subscribe((status) => {
                const isDisabled = status === 'DISABLED';
                if (isDisabled && !this.disabled) {
                    setTimeout(() => {
                        this.wrapperForm.disable();
                    }, 0);
                    this.disabled = true;
                } else if (!isDisabled && this.disabled) {
                    setTimeout(() => {
                        this.wrapperForm.enable();
                    }, 0);
                    this.disabled = false;
                }
            }),
        );
    }

    overrideValueChanged(): void {
        // we should emit the date and time values when either control changes
        let dateValue = this.wrapperForm.get(this.config.formGroup).get('Date').value as Date;
        let timeValue = this.wrapperForm.get(this.config.formGroup).get('Time').value as string;
        if (timeValue) {
            let [hours, minutes, seconds] = timeValue
                .split(':')
                // tslint:disable-next-line:radix
                .map((item) => parseInt(item));
            dateValue.setHours(hours);
            dateValue.setMinutes(minutes);
            dateValue.setSeconds(seconds);
        }

        this.getControl().patchValue(dateValue);
    }
}
