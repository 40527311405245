import { Component, Injector } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { CustomFormControlComponentBase } from '../custom-form-component.base';

@Component({
    styles: [
        `
            .inside-box-maxlength {
                position: relative !important;
                bottom: -13px;
                top: -13px;
                margin-bottom: -13px;
                right: 3px;
                font-size: 70%;
            }

            .tag {
                margin: 5px;
                background-color: #077aff;
                color: white;
                padding: 5px;
                border-radius: 5px;
                cursor: pointer;
            }

            .tag.disabled {
                background-color: #8aa3ff;
                cursor: default;
            }

            i {
                margin-left: 5px;
            }
        `,
    ],
    templateUrl: './form-tags.component.html',
})
export class FormTagsComponent extends CustomFormControlComponentBase {
    tagsArray: string[];
    control: AbstractControl;
    seperator = ',';

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.control = this.getControl();
        this.mapFormControlToArray();
    }

    mapFormControlToArray(): void {
        this.tagsArray = this.control.value ? (this.control.value as string).split(this.seperator) : [];
    }

    removeTag(index: number): void {
        if (this.control.disabled) {
            return;
        }
        this.tagsArray.splice(index, 1);
        this.control.setValue(this.tagsArray.join());
    }
}
