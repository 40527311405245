import { Component, Injector, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CustomFormControlComponentBase } from '../custom-form-component.base';
import { DatePipe } from '@angular/common';

@Component({
    templateUrl: './form-date-input.component.html',
})
export class FormDateInputComponent extends CustomFormControlComponentBase implements OnInit {
    @ViewChild('input', { static: false }) inputElement: ElementRef;
    displayValue: string;

    constructor(injector: Injector, private datePipe: DatePipe) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.config.value = this.setValueAndDisplayValue(this.config.value as string | Date);

        this.valueChanges.subscribe(this.setValueAndDisplayValue.bind(this));
    }

    setValueAndDisplayValue(value: string | Date): Date {
        if (value) {
            value = new Date(value.toString());
            if (!isNaN(value.getTime())) {
                this.displayValue = this.datePipe.transform(value, 'yyyy-MM-dd', 'UTC');
            }
        } else {
            this.displayValue = null;
        }
        return value as Date;
    }

    onBlur(): void {
        this.updateDateValue();
        this.blur.emit();
    }

    updateDateValue(): void {
        const dateControl = this.inputElement;
        const control = this.getControl();
        let valueToSet = dateControl.nativeElement.value ? new Date(dateControl.nativeElement.value) : null;
        control.patchValue(valueToSet);
        control.markAsDirty();
    }
}
