import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Subject, Subscription } from 'rxjs';
import { IPhone, IPhoneType, DefaultPhoneTypes } from '@mt-ng2/phone-control';
import { IEntityRouteConfig } from '@mt-ng2/entity-components-base';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

@Component({
    selector: 'app-common-phone',
    template: `
        <div class="miles-editable miles-card padded" *ngIf="!isEditing" (click)="selectPhone()" [style.cursor]="canEdit ? 'pointer' : 'default'">
            <h4>
                Phone
                <span class="pull-right show-on-hover" *ngIf="canEdit">
                    <i class="fa fa-lg fa-edit"></i>
                </span>
            </h4>
            <div *ngIf="phones | primary" class="display-padded">
                <span>
                    <b>Primary Phone:</b>
                    {{ phones | primary | phone }}
                </span>
            </div>
            <div *ngIf="!phones || phones.length === 0">
                <i>No Phones</i>
            </div>
        </div>
        <div class="miles-form padded" *ngIf="isEditing">
            <form [formGroup]="phoneForm" (ngSubmit)="savePhones()">
                <h4>Phone</h4>
                <div>
                    <label for="phoneNumbers">Phone Number(s)</label>
                    <app-mt-phone [PhoneArray]="PhoneArray" [PhoneTypes]="PhoneTypes" [parentForm]="phoneForm" [canAdd]="canAdd"></app-mt-phone>
                    <br />
                </div>
                <button type="submit" class="btn btn-flat btn-success">Save</button>
                <button (click)="cancel()" class="btn btn-default btn-flat">Cancel</button>
            </form>
        </div>
    `,
})
export class CommonPhoneComponent implements OnInit, OnDestroy {
    @Input() editingComponent: Subject<string>;
    _canEdit: boolean;
    @Input('canEdit')
    get canEdit(): boolean {
        return this._canEdit;
    }
    set canEdit(value: boolean) {
        this._canEdit = value;
    }
    _canAdd: boolean;
    @Input('canAdd')
    get canAdd(): boolean {
        return this._canAdd;
    }
    set canAdd(value: boolean) {
        this._canAdd = value;
    }
    @Input('PhoneArray')
    set PhoneArray(value: any[]) {
        this._phones = value;
        this.phones = value;
        // this.selectedPhone = null;
    }
    get PhoneArray(): any[] {
        return this._phones;
    }

    @Input('PhoneTypes') PhoneTypes: IPhoneType[]; // phone types input
    @Output('onSave') onSave: EventEmitter<any> = new EventEmitter<any>();

    protected _phones: any[];

    protected _isEditing = false;
    get isEditing(): boolean {
        return this._isEditing && this._canEdit;
    }
    set isEditing(value: boolean) {
        this._isEditing = value;
    }

    newPhone: IPhone = {
        Extension: '',
        IsPrimary: true,
        Phone: '',
        PhoneTypeId: 2,
    };

    phones = [];
    subscriber: Subscription = new Subscription();
    phoneForm: FormGroup;

    protected fb: FormBuilder;
    protected route: ActivatedRoute;
    protected claimsService: ClaimsService;
    protected notificationsService: NotificationsService;

    constructor(injector: Injector) {
        this.fb = injector.get(FormBuilder);
        this.route = injector.get(ActivatedRoute);
        this.claimsService = injector.get(ClaimsService);
        this.notificationsService = injector.get(NotificationsService);
    }

    ngOnInit(): void {
        this.phoneForm = new FormGroup({});
        this.setVariables();
    }

    ngOnDestroy(): void {
        this.subscriber.unsubscribe();
    }

    setVariables(): void {
        const config: IEntityRouteConfig = <IEntityRouteConfig>this.route.parent.snapshot.data;
        if (this._canEdit === undefined) {
            this._canEdit = config?.claimType ? this.claimsService.hasClaim(config.claimType, [ClaimValues.FullAccess]) : false;
        }
        if (this._canAdd === undefined) {
            this._canAdd = this._canEdit;
        }
        if (!this.PhoneTypes) {
            this.PhoneTypes = DefaultPhoneTypes;
        }
    }

    savePhones(): void {
        // TODO: is this how we handle touched?
        if (this.phoneForm.valid) {
            const phoneCollection = {
                Phones: this.getPhonesFromForm(this.phoneForm),
            };
            this.onSave.emit(phoneCollection);
            const phoneGroups = phoneCollection.Phones.sort((a, b) => {
                return (b.IsPrimary ? 1 : 0) - (a.IsPrimary ? 1 : 0);
            }).map((phone) => this.fb.group(phone));
            const phones = this.fb.array(phoneGroups);
            this.phoneForm.setControl('phones', phones);
            this.isEditing = false;
        } else {
            this.notificationsService.error('Save Failed');
            markAllFormFieldsAsTouched(this.phoneForm);
        }
    }

    protected getPhonesFromForm(form: FormGroup): IPhone[] {
        const Phones: IPhone[] = [];
        form.value.phones.forEach((phone: IPhone) => {
            phone.Phone = phone.Phone.replace(/\D/g, '');
            if (phone.Phone?.length >= 10) {
                phone.Phone = phone.Phone.substring(0, 10);
                Phones.push(phone);
            }
        });
        return Phones;
    }

    selectPhone(): void {
        this.isEditing = true;
    }

    cancel(): void {
        this.isEditing = false;
    }
}
