import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';

import { SwalComponent, SwalPortalTargets, SweetAlert2LoaderService } from '@sweetalert2/ngx-sweetalert2';
import { from } from 'rxjs';
import { SweetAlertResult } from 'sweetalert2';

import { IModalWrapperApi, IModalOptions, ModalOptions } from '../libraries/modal.library';

@Component({
    selector: 'modal-wrapper',
    templateUrl: './modal-wrapper.component.html',
})
export class ModalWrapperComponent implements OnInit, OnDestroy {
    @Input() allowOutsideClick = false;
    @Input() title = '';

    /**
     * options for the modal
     *
     * Note: this setting can be updated between modal show calls, but this property does NOT erase
     * what has been set before unless you specify the previous properties you want to erase again.
     * Ie. setting { title: 'Title' } and then { text: 'Text' } will give { title: 'Title', text: 'Text' }.
     */
    @Input() options: IModalOptions;

    @Input() autoShow = true;
    @Input() showActions = true;

    @Output() ready = new EventEmitter<IModalWrapperApi>();
    @Output() okClick = new EventEmitter();
    @Output() cancelClick = new EventEmitter();

    @ViewChild('swal', { static: true }) swal: SwalComponent;

    constructor(public swalTargets: SwalPortalTargets, private loaderService: SweetAlert2LoaderService) {}

    handleSwalClick(response: SweetAlertResult): void {
        if (response.value) {
            this.okClick.emit();
        } else {
            this.cancelClick.emit();
        }
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.autoShow) {
                this.openSwal();
            }
            this.ready.emit({
                close: () => {
                    this.swal.dismiss().then();
                },
                show: () => {
                    this.openSwal();
                },
            });
        }, 0);
    }

    ngOnDestroy(): void {
        this.swal.dismiss().then();
    }

    private openSwal(): void {
        this.swal.swalOptions = new ModalOptions(this.options);
        this.swal.fire().then((response) => {
            if (response.value) {
                this.okClick.emit();
            } else {
                this.cancelClick.emit();
            }
        });
    }
}
