import { Component, Input, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { DynamicField, DynamicFieldType, DynamicFieldTypes, InputTypes, IDynamicFieldType } from '@mt-ng2/dynamic-form';

/**
 * Interface defining the note object
 * @property Id
 * @property Title
 * @property NoteText
 */
export interface INote {
    Id: number;
    Title: string;
    NoteText: string;
}

export interface INoteComponentApi {
    form: FormGroup;
}

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'mt-note',
    templateUrl: './mt-note.component.html',
})
export class MtNoteComponent implements OnInit {
    /**
     * note object to be displayed
     */
    @Input() note: INote;
    /**
     * form object to attach the note component to
     */
    @Input() parentForm: FormGroup;
    /**
     * boolean to toggle auto focus to the note title
     */
    @Input() focus = false;

    @Output() ready = new EventEmitter<INoteComponentApi>();

    titleField: DynamicField;
    noteTextField: DynamicField;
    readyToShow = false;

    constructor() {}

    /**
     * load the form
     */
    ngOnInit(): void {
        this.loadForm();
        this.ready.emit({ form: this.parentForm });
    }

    ngAfterViewInit(): void {
        if (this.focus) {
            setTimeout(() => {
                this.parentForm.get('Title').mtFocus();
            }, 0);
        }
    }

    /**
     * build the form based on the INote Object and attach
     * the new group to the parent form
     */
    loadForm(): void {
        if (!this.parentForm) {
            this.parentForm = new FormGroup({});
        }

        this.parentForm.addControl('Id', new FormControl(this.note.Id));

        this.titleField = new DynamicField({
            formGroup: '',
            label: 'Title',
            name: 'Title',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
            } as IDynamicFieldType),
            value: this.note.Title,
        });
        this.titleField.setRequired(true);

        this.noteTextField = new DynamicField({
            formGroup: '',
            label: 'Note Text',
            name: 'NoteText',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.WYSIWYG,
            } as IDynamicFieldType),
            value: this.note.NoteText,
        });
        this.noteTextField.setRequired(true);
    }
}
