export enum DaysOfTheWeek {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
}

export enum Months {
    January,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December,
}

export interface ISearchFilterDaterangeValue {
    startDate: Date;
    endDate: Date;
}

export type ContextualDateFunction = (firstDayOfTheWeek: DaysOfTheWeek) => ISearchFilterDaterangeValue;

export interface IContextualDate {
    GetDates: ContextualDateFunction;
    Name: string;
}
