import { Component, Injector } from '@angular/core';

import { ISelectionChangedEvent, MultiselectItem } from '@mt-ng2/multiselect-control';
import { pluralize } from '@mt-ng2/common-functions';
import { FormSelectBaseWithNullableOption } from '../form-select-wrapper/form-select-base-with-nullable-option';

@Component({
    styles: [
        `
            .inside-box-maxlength {
                position: relative !important;
                bottom: -13px;
                top: -13px;
                margin-bottom: -13px;
                right: 3px;
                font-size: 70%;
            }
        `,
    ],
    templateUrl: './form-multi-select-dropdown.component.html',
})
export class FormMultiSelectDropdownComponent extends FormSelectBaseWithNullableOption {
    multiselectItems: MultiselectItem[];
    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();
        const valueIds = <number[]>this.config.value || [];
        this.multiselectItems = this.config.options.map(
            (option) => new MultiselectItem(option, valueIds?.length && valueIds.indexOf(option.Id) >= 0 ? true : false),
        );

        this.subscriptions.add(
            this.getControl().valueChanges.subscribe((value) => {
                const valueIds = <number[]>value || [];
                this.multiselectItems.forEach((item) => (item.Selected = valueIds.some((id) => item.Item.Id === id)));
            }),
        );
    }

    multiSelectSelectionChanged(event: ISelectionChangedEvent): void {
        const selectedIds = event.selectedItems.map((item) => item.Id);
        this.getControl().patchValue(selectedIds.length ? selectedIds : null);
        this.getControl().markAsDirty();
    }

    getNoItemsErrorMessage(): string {
        return `no ${pluralize(this.config.label.toLowerCase())} available`;
    }
}
