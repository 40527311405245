import { Injectable } from '@angular/core';

import { NotificationStrategy } from '../strategies/notification-strategy';

@Injectable()
export class NotificationsService {
    private _notificationStrategy: NotificationStrategy;

    setNotificationStrategy(notificationStrategy: NotificationStrategy): void {
        this._notificationStrategy = notificationStrategy;
    }

    success(message: string, options: any = {}): void {
        this._notificationStrategy.success(message, options);
    }

    error(message: string, title?: string, options: any = {}): void {
        this._notificationStrategy.error(message, title, options);
    }

    warning(message: string, options: any = {}): void {
        this._notificationStrategy.warning(message, options);
    }

    info(message: string, options: any = {}): void {
        this._notificationStrategy.info(message, options);
    }
}
