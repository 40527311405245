import { QuillToolbarConfig } from 'ngx-quill';
import Quill from 'quill';

export interface IWysiwygComponentApi {
    focus: () => void;
}

export interface IWysiwygComponentConfig {
    toolbar?: ToolbarOption[] | ToolbarOption[][];
    height?: number;
    imagePasteHandler?: (imageUrl: string) => void;
}

export type ToolbarOption =
    | 'bold'
    | 'italic'
    | 'underline'
    | 'strike'
    | 'link'
    | 'bullet-list'
    | 'ordered-list'
    | 'align'
    | 'font-size'
    | 'code-block'
    | 'image';

export function buildQuillToolbarConfig(toolbarOptions: ToolbarOption[] | ToolbarOption[][]): QuillToolbarConfig {
    const moduleConfig: QuillToolbarConfig = [];
    buildQuillToolbar(moduleConfig, toolbarOptions as ToolbarOption[]);
    return moduleConfig;
}

function buildQuillToolbar(moduleConfig: any[], toolbarOptions: ToolbarOption[]): void {
    toolbarOptions.forEach((option: ToolbarOption) => {
        if (Array.isArray(option)) {
            const newConfigArray = [];
            buildQuillToolbar(newConfigArray, option);
            moduleConfig.push(newConfigArray);
        } else {
            switch (option) {
                case 'bullet-list':
                    moduleConfig.push({ list: 'bullet' });
                    break;
                case 'ordered-list':
                    moduleConfig.push({ list: 'ordered' });
                    break;
                case 'align':
                    moduleConfig.push({ align: [] });
                    break;
                case 'font-size':
                    moduleConfig.push({ size: ['small', false, 'large', 'huge'] });
                    break;
                default:
                    moduleConfig.push(option);
            }
        }
    });
}
