import { IMtCropperSettings } from './mt-cropper-settings';
import { IMtFileUploaderSettings } from './mt-file-uploader-settings';
import { InjectionToken } from '@angular/core';

/**
 * Interface for the PhotoControl module config
 */
export interface IPhotoControlModuleConfig {
    cropperSettings?: IMtCropperSettings;
    uploaderSettings?: IMtFileUploaderSettings;
}

export function getSettingsFromConfigs<T>(moduleLevelSettings: T, componentLevelSettings: T): T {
    let settings: T;
    if (moduleLevelSettings || componentLevelSettings) {
        settings = Object.assign({}, moduleLevelSettings, componentLevelSettings);
    }
    return settings || ({} as T);
}

export const PhotoControlModuleConfigToken = new InjectionToken<IPhotoControlModuleConfig>('photo_control_config');
