import { Router } from '@angular/router';

export class BackButtonRouterLinks {
    url: string;
    title: string;
}

export class RouterLinkBuilder {
    constructor(private router: Router) {}

    getAllRouterLinksBasedOnRoute(): BackButtonRouterLinks[] {
        const url = this.router.parseUrl(this.router.url);
        if (!(url && url.root && url.root.children && url.root.children.primary)) {
            return [];
        }
        let segments = url.root.children.primary.segments;
        segments = segments.slice(0, segments.length - 1);
        let routerLinks: BackButtonRouterLinks[] = [];
        segments.forEach((segment, i) => {
            const url = segments
                .slice(0, i + 1)
                .map((item) => item.path)
                .join('/');
            const routerLink = this.getRouterLink(url);
            routerLinks.unshift(routerLink);
        });
        return routerLinks;
    }

    getRouterLink(url: string): BackButtonRouterLinks {
        let urlWithoutSlash = url;
        if (url.startsWith('/')) {
            urlWithoutSlash = url.substring(1);
        } else {
            url = '/' + url;
        }
        return {
            title: this.getRouteTitle(urlWithoutSlash),
            url: url,
        };
    }

    getRouteTitle(url: string): string {
        let urlSplit = url.split('/');
        let configs = [...this.router.config];
        let configsToEnumerate = [...this.router.config];
        configsToEnumerate.forEach((c) => {
            this.addChildren(configs, c);
        });
        let config = configs.find((c) => {
            let pathSplit = c.path.split('/');
            if (pathSplit.length !== urlSplit.length) {
                return false;
            }
            let match = true;
            pathSplit.forEach((p, i) => {
                let matchingUrlSegment = urlSplit[i];
                if (!matchingUrlSegment) {
                    match = false;
                }
                if (p !== matchingUrlSegment) {
                    if (!p.startsWith(':')) {
                        match = false;
                    }
                    // tslint:disable-next-line:radix
                    let isNumber = parseInt(matchingUrlSegment);
                    if (!isNumber) {
                        match = false;
                    }
                }
            });
            return match;
        });
        return (config && config.data && config.data.title) || '';
    }

    private addChildren(configs: any[], config: any): void {
        if (!config.children) {
            return;
        }
        config.children.forEach((c) => {
            let copy = { ...c };
            copy.path = config.path + '/' + copy.path;
            configs.push(copy);
            this.addChildren(configs, c);
        });
    }
}
