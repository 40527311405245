import { Pipe, PipeTransform } from '@angular/core';
import { getFontAwesomeFileImage } from '@mt-ng2/format-functions';

@Pipe({
    name: 'fileicon',
})
export class FileIconPipe implements PipeTransform {
    constructor() {}
    transform(filename): string {
        return getFontAwesomeFileImage(filename);
    }
}
