import { Component, Injector, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CustomFormControlComponentBase } from '../custom-form-component.base';
import { DatePipe } from '@angular/common';

@Component({
    templateUrl: './form-datetime-input.component.html',
})
export class FormDateTimeInputComponent extends CustomFormControlComponentBase implements OnInit {
    @ViewChild('input', { static: false }) inputElement: ElementRef;
    displayValue: string;

    constructor(injector: Injector, private datePipe: DatePipe) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (this.config.value) {
            this.config.value = new Date(this.config.value.toString());
            if (!isNaN(this.config.value.getTime())) {
                this.setDisplayValue(this.config.value);
            }
        }
        this.subscriptions.add(
            this.getControl().valueChanges.subscribe((value) => {
                this.setDisplayValue(value);
            }),
        );
    }

    setDisplayValue(value: Date): void {
        this.displayValue = this.datePipe.transform(value, 'yyyy-MM-ddTHH:mm');
    }

    onBlur(): void {
        this.updateDateValue();
        this.blur.emit();
    }

    updateDateValue(): void {
        const dateControl = this.inputElement;
        const control = this.getControl();

        let valueToSet = dateControl.nativeElement.value ? new Date(dateControl.nativeElement.value) : null;
        control.patchValue(valueToSet);
        control.markAsDirty();
    }
}
