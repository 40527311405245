import { Component } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { getControlFromFormGroup, getGroupFromConfig } from '../form-elements.library';
import { DynamicField } from '../../libraries/dynamic-field.library';

@Component({
    selector: 'app-form-mt-address',
    templateUrl: './form-mt-address.component.html',
})
export class FormMtAddressComponent {
    config: DynamicField;
    parentGroup: FormGroup;
    isFocused = false;

    getControl(): AbstractControl {
        return getControlFromFormGroup(this.parentGroup, this.config);
    }

    getGroup(): FormGroup {
        return getGroupFromConfig(this.parentGroup, this.config);
    }

    hasError(): boolean {
        const control = this.getControl();
        return control.errors && (control.touched || control.errors.maxlength);
    }
}
