import { Validators } from '@angular/forms';
import { IAddress } from './mt-address.component';
import { DynamicField, DynamicFieldType } from '../libraries/dynamic-field.library';
import { DynamicFieldTypes } from '../libraries/interfaces/dynamic-field-type';
import { SelectInputTypes } from '../form-elements/form-elements.library';
import { IMetaItem } from '../libraries/interfaces/meta-item';

export interface IAddressDynamicFields {
    Address1: DynamicField;
    Address2: DynamicField;
    City: DynamicField;
    InternationalCity: DynamicField;
    Province: DynamicField;
    Zip: DynamicField;
    InternationalZip: DynamicField;
    State: DynamicField;
    Country: DynamicField;
}

export class AddressDynamicFields implements IAddressDynamicFields {
    Address1: DynamicField;
    Address2: DynamicField;
    City: DynamicField;
    InternationalCity: DynamicField;
    Province: DynamicField;
    Zip: DynamicField;
    InternationalZip: DynamicField;
    State: DynamicField;
    Country: DynamicField;

    constructor(address: IAddress, states: IMetaItem[], countries: IMetaItem[], private formGroup = 'Address') {
        this.Address1 = this.getDynamicFieldAddress1(address);
        this.Address2 = this.getDynamicFieldAddress2(address);
        this.City = this.getDynamicFieldCity(address);
        this.InternationalCity = this.getDynamicFieldCity(address, false);
        this.Province = this.getDynamicFieldProvince(address);
        this.Zip = this.getDynamicFieldZip(address);
        this.InternationalZip = this.getDynamicFieldZip(address, false);
        this.State = this.getDynamicFieldState(address, states);
        this.Country = this.getDynamicFieldCountry(address, countries);
    }

    getDynamicFieldAddress1(value: IAddress, required = true, maxLength = 50): DynamicField {
        return new DynamicField({
            formGroup: this.formGroup,
            label: 'Address 1',
            name: 'Address1',
            type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input }),
            validation: required ? [Validators.required, Validators.maxLength(maxLength)] : [Validators.maxLength(maxLength)],
            validators: required ? { required: true, maxlength: maxLength } : { maxlength: maxLength },
            value: value.Address1,
        });
    }

    getDynamicFieldAddress2(value: IAddress, required = false, maxLength = 50): DynamicField {
        return new DynamicField({
            formGroup: this.formGroup,
            label: 'Address 2',
            name: 'Address2',
            type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input }),
            validation: required ? [Validators.required, Validators.maxLength(maxLength)] : [Validators.maxLength(maxLength)],
            validators: required ? { required: true, maxlength: maxLength } : { maxlength: maxLength },
            value: value.Address2,
        });
    }

    getDynamicFieldCity(value: IAddress, required = true, maxLength = 50): DynamicField {
        return new DynamicField({
            formGroup: this.formGroup,
            label: 'City',
            name: 'City',
            type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input }),
            validation: required ? [Validators.required, Validators.maxLength(maxLength)] : [Validators.maxLength(maxLength)],
            validators: required ? { required: true, maxlength: maxLength } : { maxlength: maxLength },
            value: value.City,
        });
    }

    getDynamicFieldZip(value: IAddress, required = true, maxLength = 10): DynamicField {
        return new DynamicField({
            formGroup: this.formGroup,
            label: 'Zip',
            name: 'Zip',
            type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input }),
            validation: required ? [Validators.required, Validators.maxLength(maxLength)] : [Validators.maxLength(maxLength)],
            validators: required ? { required: true, maxlength: maxLength } : { maxlength: maxLength },
            value: value.Zip,
        });
    }

    getDynamicFieldProvince(value: IAddress, required = false, maxLength = 50): DynamicField {
        return new DynamicField({
            formGroup: this.formGroup,
            label: 'Province',
            name: 'Province',
            type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input }),
            validation: required ? [Validators.required, Validators.maxLength(maxLength)] : [Validators.maxLength(maxLength)],
            validators: required ? { required: true, maxlength: maxLength } : { maxlength: maxLength },
            value: value.Province,
        });
    }

    getDynamicFieldState(value: IAddress, states: IMetaItem[]): DynamicField {
        return new DynamicField({
            formGroup: this.formGroup,
            label: 'State',
            name: 'StateCode',
            options: states,
            type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Select, inputType: SelectInputTypes.Dropdown }),
            validation: [Validators.required],
            validators: { required: true },
            value: value.StateCode,
        });
    }

    getDynamicFieldCountry(value: IAddress, countries: IMetaItem[]): DynamicField {
        return new DynamicField({
            formGroup: this.formGroup,
            label: 'Country',
            name: 'CountryCode',
            options: countries,
            type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Select, inputType: SelectInputTypes.Dropdown }),
            validation: [Validators.required],
            validators: { required: true },
            value: value.CountryCode,
        });
    }
}
