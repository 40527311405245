import { Component, Input, OnInit, Inject } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { formatPhone } from '@mt-ng2/format-functions';

import { NumericInputTypes } from '../form-elements/form-numeric/form-numeric.component';
import { DynamicLabel } from '../libraries/dynamic-label.library';
import { InputTypes } from '../form-elements/form-elements.library';
import { DynamicFormModuleConfig } from '../libraries/dynamic-form-module.config';
import { DynamicFieldTypes } from '../libraries/interfaces/dynamic-field-type';

@Component({
    selector: 'app-dynamic-view',
    templateUrl: './dynamic-view.component.html',
})
export class DynamicViewComponent {
    @Input()
    config: DynamicLabel[] = [];

    constructor(
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe,
        private sanitizer: DomSanitizer,
        public moduleConfig: DynamicFormModuleConfig,
    ) {}

    getValue(field: DynamicLabel): string | SafeHtml {
        if (this.moduleConfig?.customFormComponentDisplayValueFunction) {
            const customFormComponentValueFunction = this.moduleConfig.customFormComponentDisplayValueFunction(field);
            if (customFormComponentValueFunction) {
                return customFormComponentValueFunction;
            }
        }
        if (!field || (!field.value && field.value !== 0 && field.type.fieldType !== DynamicFieldTypes.Checkbox)) {
            return '';
        }
        if (field.valueHtml != null) {
            return this.sanitizer.bypassSecurityTrustHtml(field.valueHtml);
        }
        if (field.type.fieldType === DynamicFieldTypes.Numeric && field.type.inputType === NumericInputTypes.Currency) {
            return this.currencyPipe.transform(field.value);
        }
        if (
            field.type.fieldType === DynamicFieldTypes.Input &&
            (field.type.inputType === InputTypes.Datepicker || field.type.inputType === InputTypes.DateTimeInput)
        ) {
            return this.datePipe.transform(field.value);
        }
        if (field.type.fieldType === DynamicFieldTypes.Input && field.type.inputType === InputTypes.Phone) {
            return formatPhone(<string>field.value);
        }
        if (field.type.fieldType === DynamicFieldTypes.Checkbox) {
            return field.value ? 'Yes' : 'No';
        }
        if (field.type.fieldType === DynamicFieldTypes.Input && field.type.inputType === InputTypes.DateInput) {
            return this.datePipe.transform(field.value, 'mediumDate', 'UTC');
        }
        return field.value.toString();
    }
}
