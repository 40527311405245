import { Pipe, PipeTransform } from '@angular/core';

import { format } from '@mt-ng2/format-functions';

@Pipe({
    name: 'address',
})
export class AddressPipe implements PipeTransform {
    constructor() {}

    transform(value: any, args?: any): string {
        let addr = value?.Address ?? '';
        if (addr) {
            addr = format.address(addr);
        }
        return addr;
    }
}
