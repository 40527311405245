import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { NavService } from '@mt-ng2/nav-module';
import { AuthService } from '@mt-ng2/auth-module';
import { environment } from './environments/environment';
import { PrintModeService } from '@mt-ng2/entity-list-module';
import { WindowSizeService } from '@common/services/window-size.service';

@Component({
    selector: 'app-root',
    template: `
        <div
            [hidden]="appReady === null"
            class="skin-blue sidebar-mini"
            [class.sidebar-open]="!sidebarCollapsed && showNav"
            [class.sidebar-collapse]="sidebarCollapsed || !showNav"
            [class.sidebar-mini]="showNav"
        >
            <div class="wrapper">
                <app-nav></app-nav>
                <div class="container-fluid content-wrapper" [style.min-height]="showFooter ? null : '100vh'">
                    <ng-progress></ng-progress>
                    <router-outlet></router-outlet>
                    <br />
                </div>
            </div>
        </div>
        <div [hidden]="appReady !== null" [style.position]="'relative'">
            <div class="pulse">
                <span></span>

                <img class="logo-img" src="{{ logoFull }}" alt="Logo" />
            </div>
        </div>
    `,
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'app';

    sidebarCollapsed: boolean;
    showNav: boolean;
    showFooter: boolean;
    appReady: boolean = null;
    logoFull = `${environment.imgPath}logo-full.png`;
    subscriptions: Subscription = new Subscription();
    constructor(
        private navService: NavService,
        private authService: AuthService,
        private printModeService: PrintModeService,
        private windowSizeService: WindowSizeService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
          this.subscriptions.add(
            this.printModeService.printMode.subscribe((inPrintMode) => {
                this.navService.showNav.next(!inPrintMode);
                this.navService.showFooter.next(!inPrintMode);
            }),
        );
          this.subscriptions.add(
            this.authService.appReady.subscribe((answer) => {
                this.appReady = answer;
                this.cdr.detectChanges();
            }),
        );
          this.sidebarCollapsed = this.navService.sidebarCollapsed.getValue();
          this.subscriptions.add(
            this.navService.sidebarCollapsed.subscribe((sidebarCollapsed: boolean) => {
                this.sidebarCollapsed = sidebarCollapsed;
                this.cdr.detectChanges();
            }),
        );

          this.showNav = this.navService.showNav.getValue();
          this.subscriptions.add(
            this.navService.showNav.subscribe((showNav: boolean) => {
                this.showNav = showNav;
                this.cdr.detectChanges();
            }),
        );

          this.showFooter = this.navService.showFooter.getValue();
          this.subscriptions.add(
            this.navService.showFooter.subscribe((showFooter: boolean) => {
                this.showFooter = showFooter;
            }),
        );
          this.setWindowSize();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event): void {
        this.setWindowSize();
    }

    setWindowSize(): void {
        this.windowSizeService.updateWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
