import { InjectionToken } from '@angular/core';

import { IToastableError } from '../libraries/toastable-error';
import { AppError } from '../libraries/app-error';
import { ICurrentUser } from '../libraries/current-user';

/**
 * Interface representing the customizable properties
 * of the errors module.
 * @example
 * const errorsModuleConfig: IErrorsModuleConfig = {
 *     loginErrorMessage: `Looks like you aren't logged in yet`,
 * };
 * @property customErrorHandlerFunction
 * @property loginErrorMessage
 * @property serviceUnavailableMessage
 * @property unhandledErrorMessage
 * @property getCurrentUserOverrideFunction
 */
export interface IErrorsModuleConfig {
    customErrorHandlerFunction?: (error: AppError) => IToastableError;
    loginErrorMessage?: string;
    serviceUnavailableMessage?: string;
    unhandledErrorMessage?: string;
    /**
     * Function returning the current user, which will be used
     * in place of the AuthService.currentUser when determining
     * which user was logged in when an error occurs.
     */
    getCurrentUserOverrideFunction?: () => ICurrentUser;
}

/**
 * Token used for providing the IErrorsModuleConfig
 * @example
 * // in the app or shared modules providers section
 * providers: [
 *     { provide: ErrorsModuleConfigToken, useValue: errorsModuleConfig },
 * ],
 */
export const ErrorsModuleConfigToken = new InjectionToken<IErrorsModuleConfig>('errors_module_config');
