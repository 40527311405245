import { Component, Input } from '@angular/core';

@Component({
    selector: 'dynamic-form-validation-message',
    styles: [
        `
            .inside-box-validation {
                position: relative !important;
                top: -35px;
                bottom: -35px;
                margin-bottom: -35px;
                right: 3px;
                float: right;
                font-size: 70%;
            }
        `,
    ],
    templateUrl: './form-validation-message.component.html',
})
export class FormValidationMessageComponent {
    @Input() errorMessage: string;
    @Input() insideBoxValidation: boolean;
}
