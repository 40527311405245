export function getFontAwesomeFileImage(filename: string): string {
    let ext = filename.substring(filename.indexOf('.')).substring(1).toLowerCase();
    switch (ext) {
        case 'png':
        case 'jpeg':
        case 'gif':
        case 'jpg':
            return 'fa-file-image-o';
        default:
            return 'fa-file-text-o';
    }
}
