import { Component, EventEmitter, Injector, Input, OnInit, Output, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { IEntityRouteConfig } from '@mt-ng2/entity-components-base';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { pluralize, markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { INote } from '@mt-ng2/note-control';
import { SortDirection } from '@mt-ng2/entity-list-module';

import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EntityComponentsNotesModuleConfig } from './libraries/module-config.library';

export interface IHasNotes {
    getNotes(entityId: number, searchParams: SearchParams): Observable<HttpResponse<INote[]>>;

    saveNote(entityId: number, note: INote): Observable<number>;

    deleteNote(entityId: number, noteId: number): Observable<object>;
}

@Component({
    selector: 'app-common-notes',
    template: `
        <div class="miles-card padded" *ngIf="!isEditing">
            <h4>{{ _componentName }}</h4>

            <ul *ngIf="_notes.length" class="list-group">
                <li *ngFor="let note of _notes" (click)="selectNote(note)" class="list-group-item">
                    <span>{{ note.Title }}</span>
                    <div *ngIf="selectedNote === note" [innerHTML]="note.NoteText"></div>
                </li>
            </ul>
            <i *ngIf="!_notes.length">No {{ getPluralObjectName() }}</i>
            <div [hidden]="!_canAdd" class="fab-wrap">
                <button type="button" class="btn btn-primary btn-fab-md btn-fab-center" (click)="addNote()">
                    <span class="fa fa-plus"></span>
                </button>
            </div>
            <div class="pull-right max-of-total">
                <span *ngIf="showTotal()">{{ _max }} of {{ _total }}</span>
            </div>
            <div class="show-on-hover" *ngIf="showTotal()">
                <a (click)="seeAll()" class="btn btn-primary btn-flat see-all"
                    >see all
                    <span class="badge">{{ _total }}</span>
                </a>
            </div>
        </div>
        <div class="miles-form padded" *ngIf="isEditing">
            <h4>{{ selectedNote.Id > 0 ? 'Edit' : 'Add' }} {{ _objectName }}</h4>
            <div>
                <mt-note [note]="selectedNote" [focus]="true" (ready)="noteForm = $event.form"></mt-note>
                <button type="submit" class="btn btn-flat btn-success" (click)="save()">Save</button>
                <button type="button" class="btn btn-flat btn-default" (click)="cancel()">Cancel</button>
                <button *ngIf="selectedNote.Id > 0" type="button" class="btn btn-flat btn-danger pull-right" (mtConfirm)="delete()">Delete</button>
            </div>
        </div>
    `,
})
export class CommonNotesComponent implements OnInit {
    _notes: INote[] = [];
    @Input('NoteArray')
    set NoteArray(value: INote[]) {
        this.notesPassedIn = true;
        this._notes = value;
        this.selectedNote = null;
        // NEED THIS for closing control
    }
    notesPassedIn = false;

    @Input('service') service: IHasNotes;

    _total: number;
    @Input('total')
    set total(value: number) {
        this._total = value;
    }

    _canAdd: boolean;
    @Input('canAdd')
    set canAdd(value: boolean) {
        this._canAdd = value;
    }

    _canEdit: boolean;
    @Input('canEdit')
    set canEdit(value: boolean) {
        this._canEdit = value;
    }

    _seeAllUrl: string;
    @Input('seeAllUrl')
    set seeAllUrl(value: string) {
        this._seeAllUrl = value;
    }

    _objectName: string;
    @Input('objectName')
    set objectName(value: string) {
        this._objectName = value;
    }

    _componentName: string;
    @Input('componentName')
    set componentName(value: string) {
        this._componentName = value;
    }

    _max: number;
    @Input('max')
    set max(value: number) {
        this._max = value;
    }

    @Output('onSave') onSave: EventEmitter<INote> = new EventEmitter<INote>();
    @Output('onDelete') onDelete: EventEmitter<INote> = new EventEmitter<INote>();

    private _selectedNote: INote = null;
    get selectedNote(): INote {
        return this._selectedNote;
    }
    set selectedNote(value: INote) {
        if (value === null) {
            this.noteForm = null;
        }
        this._selectedNote = value;
    }

    parentId: number;
    noteForm: FormGroup;

    get isEditing(): boolean {
        return this.selectedNote && this._canEdit;
    }

    newNote: INote = {
        Id: 0,
        NoteText: '',
        Title: '',
    };

    protected route: ActivatedRoute;
    protected router: Router;
    protected notificationsService: NotificationsService;
    protected claimsService: ClaimsService;
    protected config: EntityComponentsNotesModuleConfig;

    constructor(protected injector: Injector) {
        this.route = injector.get(ActivatedRoute);
        this.router = injector.get(Router);
        this.notificationsService = injector.get(NotificationsService);
        this.claimsService = injector.get(ClaimsService);
        this.config = injector.get(EntityComponentsNotesModuleConfig);
        this._max = this.config.max;
    }

    ngOnInit(): void {
        this.setVariables();
        this.getNotes();
    }

    /**
     * Assigns local variables to defaults or config properties.
     */
    setVariables(): void {
        const config: IEntityRouteConfig = <IEntityRouteConfig>this.route.parent.snapshot.data;
        if (config?.entityIdParam) {
            this.parentId = +this.route.parent.snapshot.paramMap.get(config.entityIdParam);
        }
        const service = this.service || config?.service || null;
        if (service) {
            if (typeof service === 'function') {
                this.service = this.injector.get(service);
            }
        }
        if (this._canEdit === undefined) {
            this._canEdit = config?.claimType ? this.claimsService.hasClaim(config.claimType, [ClaimValues.FullAccess]) : false;
        }
        if (this._canAdd === undefined) {
            this._canAdd = this._canEdit;
        }
        if (this._seeAllUrl === undefined) {
            this._seeAllUrl = config?.notesPath ?? 'notes';
        }
        if (this._objectName === undefined) {
            this._objectName = config?.notesObjectName ?? 'Note';
        }
        if (this._componentName === undefined) {
            this._componentName = config?.notesComponentName ?? 'Notes';
        }
    }

    getNotes(): void {
        if (!this.notesPassedIn && this.hasService()) {
            const searchparamProperties: IEntitySearchParams = {
                order: 'Title',
                orderDirection: SortDirection.Asc,
                query: '',
                take: this._max,
            };

            const searchparams = new SearchParams(searchparamProperties);

            this.service.getNotes(this.parentId, searchparams).subscribe((response) => {
                this._notes = response.body;
                this._total = +response.headers.get('X-List-Count');
            });
        }
    }

    getPluralObjectName(): string {
        return pluralize(this._objectName);
    }

    showTotal(): boolean {
        return this._total && this._total > this._max;
    }

    hasService(): boolean {
        return this.service && this.parentId && this.parentId > 0;
    }

    seeAll(): void {
        this.router.navigate([`${this._seeAllUrl}`], {
            relativeTo: this.route,
        });
    }

    addNote(): void {
        if (!this._canAdd) {
            return;
        }
        this.selectedNote = { ...this.newNote };
    }

    save(): void {
        if (this.notesPassedIn) {
            this.onSave.emit(this.noteForm.value);
        } else {
            this.saveNote(this.noteForm);
        }
    }

    protected saveNote(noteForm: FormGroup): void {
        if (noteForm.valid) {
            const note = noteForm.value;
            this.service.saveNote(this.parentId, note).subscribe(
                (success) => {
                    this.notificationsService.success('Note Saved Successfully');
                    if (this.selectedNote.Id === 0) {
                        this.selectedNote.Id = success;
                    }
                    this.onSave.emit(this.selectedNote);
                    this.selectedNote = null;
                    this.getNotes();
                },
                (error) => this.notificationsService.error('Save Failed'),
            );
        } else {
            this.notificationsService.error('Save Failed');
            markAllFormFieldsAsTouched(noteForm);
        }
    }

    cancel(): void {
        this.selectedNote = null;
    }

    delete(): void {
        if (this.notesPassedIn) {
            this.onDelete.emit(this.noteForm.value);
        } else {
            this.deleteNote(this.noteForm);
        }
    }

    protected deleteNote(noteForm: FormGroup): void {
        this.service.deleteNote(this.parentId, noteForm.value.Id).subscribe(
            (success) => {
                this.notificationsService.success('Note Deleted');
                this.onDelete.emit(this.selectedNote);
                this.selectedNote = null;
                this.getNotes();
            },
            (error) => this.notificationsService.error('Delete Failed'),
        );
    }

    selectNote(note: any): void {
        if (this.selectedNote === note) {
            this.selectedNote = null;
        } else {
            this.selectedNote = note;
        }
    }
}
