import { debounceTime } from 'rxjs/operators';
import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { FormControl } from '@angular/forms';

import { saveAs } from 'file-saver';

import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IEntityRouteConfig } from '@mt-ng2/entity-components-base';
import { MtDocumentComponent } from '@mt-ng2/document-control';
import { FileItem } from 'ng2-file-upload';

import { IDocument, IHasDocuments, IDocumentUploadControlConfig } from '../common-documents.component';
import { IItemDeletedEvent, IColumnSelectedEvent, SortDirection, IColumnSortedEvent } from '@mt-ng2/entity-list-module';
import { DocumentsEntityListConfig } from './common-documents-list.entity-list-config';

@Component({
    selector: 'common-documents-list',
    template: `
        <div *ngIf="!showUploadArea">
            <div>
                <div class="input-group">
                    <input type="text" [formControl]="searchControl" class="form-control" placeholder="Search" />
                    <span class="input-group-btn">
                        <button type="button" (click)="clearSearch()" class="btn btn-default btn-nohover btn-flat">
                            <i class="fa"></i>
                            <i class="fa fa-remove" aria-hidden="true"></i>
                            <i class="fa"></i>
                        </button>
                    </span>
                </div>
                <br />
            </div>
            <entity-list
                [entities]="documentArray"
                [itemsPerPage]="itemsPerPage"
                [total]="total"
                [(currentPage)]="currentPage"
                (onPageChanged)="getDocuments()"
                (onColumnSelected)="downloadDocument($event)"
                (onColumnSorted)="columnSorted($event)"
                (onItemDeleted)="deleteDocument($event)"
                [entityListConfig]="entityListConfig"
            >
            </entity-list>
            <a [routerLink]="['../']" class="btn btn-default">Back</a>
            <div *ngIf="canEdit" class="fab-wrap-b-r" (click)="isEditing = true">
                <a class="btn btn-primary btn-fab-lg">
                    <span class="fa fa-plus"></span>
                </a>
            </div>
        </div>
        <div [hidden]="!showUploadArea" class="row">
            <div class="col-md-6">
                <div class="miles-form padded">
                    <mt-document
                        *ngIf="documentUploadControlConfig.maxFileSize"
                        [allowedMimeType]="documentUploadControlConfig.allowedMimeType"
                        [maxFileSize]="documentUploadControlConfig.maxFileSize"
                        (onAfterAddingFile)="addDocument($event)"
                        (onAddingFileFailed)="onWhenAddingFileFailed($event)"
                    ></mt-document>
                    <mt-document
                        *ngIf="!documentUploadControlConfig.maxFileSize"
                        [allowedMimeType]="documentUploadControlConfig.allowedMimeType"
                        (onAfterAddingFile)="addDocument($event)"
                        (onAddingFileFailed)="onWhenAddingFileFailed($event)"
                    ></mt-document>
                    <br />
                    <button type="button" class="btn btn-flat btn-default" (click)="cancel()">Cancel</button>
                </div>
            </div>
        </div>
    `,
})
export class CommonDocumentsListComponent implements OnInit {
    protected _documentArray: IDocument[] = [];
    get documentArray(): IDocument[] {
        return this._documentArray;
    }

    protected _isEditing: boolean;
    set isEditing(value: boolean) {
        this._isEditing = value;
    }

    protected _canEdit: boolean;
    set canEdit(value: boolean) {
        this._canEdit = value;
    }
    get canEdit(): boolean {
        return this._canEdit;
    }

    protected _service: IHasDocuments;
    protected get service(): IHasDocuments {
        if (this._service && this.id && this.id > 0) {
            return this._service;
        }
        return null;
    }
    protected set service(value: IHasDocuments) {
        this._service = value;
    }

    documentUploadControlConfig: IDocumentUploadControlConfig;

    @ViewChild('docComponent') docComponent: MtDocumentComponent;

    id: number;
    searchControl = new FormControl();
    itemsPerPage = 12;
    currentPage = 1;
    total: number;

    entityListConfig = new DocumentsEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    protected route: ActivatedRoute;
    protected notificationsService: NotificationsService;
    protected claimsService: ClaimsService;

    constructor(protected injector: Injector) {
        this.route = injector.get(ActivatedRoute);
        this.notificationsService = injector.get(NotificationsService);
        this.claimsService = injector.get(ClaimsService);
    }

    ngOnInit(): void {
        this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe((value) => this.getDocuments());

        this.setVariables();
        this.getDocuments();
    }

    setVariables(): void {
        const config: IEntityRouteConfig = <IEntityRouteConfig>this.route.parent.snapshot.data;
        this.id = +this.route.parent.snapshot.paramMap.get(config.entityIdParam);
        this.canEdit = this.claimsService.hasClaim(config.claimType, [ClaimValues.FullAccess]);
        const service = this.getServiceFromConfig(config);
        this.service = this.injector.get(service);

        this.documentUploadControlConfig = this.service.documentUploadControlConfig || {};
        this.documentUploadControlConfig.allowedMimeType = this.documentUploadControlConfig.allowedMimeType || [
            'image/png',
            'image/jpg',
            'image/jpeg',
            'image/gif',
            'application/zip',
        ];
    }

    getServiceFromConfig(config: IEntityRouteConfig): any {
        let childRoute: ActivatedRouteSnapshot = this.route.snapshot || null;
        while (childRoute && childRoute.firstChild) {
            childRoute = childRoute.firstChild;
        }
        const childConfig = (childRoute && childRoute.data) || null;
        return childConfig && childConfig.service ? childConfig.service : config && config.service;
    }

    getDocuments(): void {
        const search = this.searchControl.value;
        const searchEntity: IEntitySearchParams = {
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        this.service.getDocuments(this.id, searchparams).subscribe((response) => {
            this._documentArray = response.body;
            this.total = +response.headers.get('X-List-Count');
        });
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getDocuments();
    }
    clearSearch(): void {
        this.searchControl.setValue('');
    }

    get showUploadArea(): boolean {
        return this._isEditing && this._canEdit;
    }

    onWhenAddingFileFailed(message: string): void {
        this.notificationsService.error(message);
    }

    addDocument(file: FileItem): void {
        this.isEditing = false;
        this.saveDocument(file);
    }

    saveDocument(file: FileItem): void {
        if (file) {
            this._service.saveDocument(this.id, file._file).subscribe((x: IDocument) => {
                this.getDocuments();
            });
        }
    }

    cancel(): void {
        this.isEditing = false;
    }

    deleteDocument(event: IItemDeletedEvent): void {
        this._service.deleteDocument(this.id, event.entity.Id).subscribe((x) => {
            this.getDocuments();
        });
    }

    downloadDocument(event: IColumnSelectedEvent): void {
        this._service.getDocument(this.id, event.entity.Id).subscribe((x) => {
            const thefile = new Blob([x], { type: 'application/octet-stream' });
            saveAs(thefile, event.entity.Name);
        });
    }
}
