import { InjectionToken } from '@angular/core';

/**
 * Interface representing the customizable properties
 * of the login module.
 * @example
 * const entityComponentsNotesModuleConfig: IEntityComponentsNotesModuleConfig = {
 *     max: 5,
 * };
 * @property max
 * @property disableSort
 */
export interface IEntityComponentsNotesModuleConfig {
    max: number;
    disableSort: boolean;
}

/**
 * Token used for providing the ILoginModuleConfig
 * @example
 * // in the app or shared modules providers section
 * providers: [
 *     { provide: EntityComponentsNotesModuleConfigToken, useValue: entityComponentsNotesModuleConfig },
 * ],
 */
export const EntityComponentsNotesModuleConfigToken = new InjectionToken<IEntityComponentsNotesModuleConfig>('entity_components_notes_module_config');
