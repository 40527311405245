import { Component, EventEmitter, Output, ElementRef, ViewChild, OnInit, forwardRef, ChangeDetectorRef } from '@angular/core';
import { NgbTimepicker, NgbTimeAdapter, NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ITimepickerControlComponentApi } from '../libraries/timepicker-control.library';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Declaring a value accessor to provide so the interface on the NgbTimepicker
 * component is satisfied.
 */
const NGB_TIMEPICKER_VALUE_ACCESSOR = {
    multi: true,
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimepickerComponent),
};

@Component({
    providers: [NGB_TIMEPICKER_VALUE_ACCESSOR],
    selector: 'mt-timepicker',
    styleUrls: ['./timepicker.less'],
    templateUrl: './timepicker-control.component.html',
})
export class TimepickerComponent extends NgbTimepicker implements OnInit {
    @Output() blur = new EventEmitter<null>();
    @Output() focus = new EventEmitter<null>();
    @Output() ready = new EventEmitter<ITimepickerControlComponentApi>();
    @ViewChild('inputElement', { static: true }) inputElement: ElementRef;

    /**
     * We are calling the constuctor to satisfy the dependencies needed in the base
     * class.  These are just pass through dependencies and we don't actually use them
     * in this component.
     * @param config default values for the timepicker config
     * @param timeAdapter adapter for allowin us to us NgbDateStruct as a value
     * @param cd ChangeDetectorRef
     * @param i18n Internationalization service
     */
    constructor(private config: NgbTimepickerConfig, private timeAdapter: NgbTimeAdapter<any>, private cd: ChangeDetectorRef) {
        super(config, timeAdapter, cd);
    }

    /**
     * Emith the control ready event
     */
    ngOnInit(): void {
        this.ready.emit({
            focus: this.focusMe.bind(this),
        });
    }

    /**
     * Used to override the focusMe event in the CustomFormControlComponentBase of dynamic forms.
     */
    focusMe(): void {
        if (this.inputElement && this.inputElement.nativeElement && this.inputElement.nativeElement.focus) {
            this.inputElement.nativeElement.focus();
        }
    }

    onBlur(): void {
        this.blur.emit();
    }

    onFocus(): void {
        this.focus.emit();
    }
}
