import { Component } from '@angular/core';

@Component({
    selector: 'entity-list-actions',
    styles: [
        `
            .entity-list-actions {
                position: relative;
            }
            .entity-list-actions-container {
                position: absolute;
                top: -20px;
                right: 0;
            }
        `,
    ],
    template: `
        <div class="entity-list-actions">
            <div class="entity-list-actions-container">
                <ng-content></ng-content>
            </div>
        </div>
    `,
})
export class EntityListActionsComponent {}
