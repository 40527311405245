import { InjectionToken } from '@angular/core';

/**
 * Interface representing the customizable properties
 * of the entity components addresses module.
 * @example
 * const entityComponentsAddressesModuleConfig: IEntityComponentsAddressesModuleConfig = {
 *     max: 5,
 * };
 * @property max
 * @property disableSort
 */
export interface IEntityComponentsAddressesModuleConfig {
    max: number;
    disableSort: boolean;
}

/**
 * Token used for providing the IEntityComponentsAddressesModuleConfig
 * @example
 * // in the app or shared modules providers section
 * providers: [
 *     { provide: EntityComponentsAddressesModuleConfigToken, useValue: entityComponentsAddressesModuleConfig },
 * ],
 */
export const EntityComponentsAddressesModuleConfigToken = new InjectionToken<IEntityComponentsAddressesModuleConfig>(
    'entity_components_addresses_module_config',
);
