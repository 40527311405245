import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Form, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ComponentErrorHandler } from '@mt-ng2/component-error-handler';

/**
 * Interface that represents a phone object
 * @property Phone
 * @property Extension
 * @property PhoneTypeId
 * @property IsPrimary
 */
export interface IPhone {
    Phone: string;
    Extension: string;
    PhoneTypeId: number;
    IsPrimary: boolean;
}

export interface IPhoneType {
    IconFaClass: string;
    Id: number;
    Name: string;
}

export const DefaultPhoneTypes = [
    {
        IconFaClass: 'home',
        Id: 1,
        Name: 'Home',
    },
    {
        IconFaClass: 'phone',
        Id: 2,
        Name: 'Work',
    },
    {
        IconFaClass: 'mobile',
        Id: 3,
        Name: 'Mobile',
    },
    {
        IconFaClass: 'fax',
        Id: 4,
        Name: 'Fax',
    },
];

@Component({
    selector: 'app-mt-phone',
    template: `
        <div class="form-group">
            <div [formGroup]="parentForm">
                <div formGroupName="phones">
                    <ngb-panel *ngFor="let phone of phonesControls; let i = index" type="info">
                        <div [formArrayName]="i" class="input-group">
                            <span class="input-group-btn">
                                <span ngbDropdown class="dropdown">
                                    <button ngbDropdownToggle type="button" class="btn btn-default btn-flat dropdown-toggle">
                                        <i class="fa fa-fw fa-{{ phoneTypeClass(phone.value.PhoneTypeId) }}"></i>
                                        <span class="caret"></span>
                                    </button>
                                    <ul ngbDropdownMenu class="dropdown-menu">
                                        <li *ngFor="let phoneType of PhoneTypes" (click)="setPhoneType(i, phoneType.Id)">
                                            <a class="dropdown-item">
                                                <i
                                                    class="fa fa-fw"
                                                    [ngClass]="{
                                                        'fa-check': phone.value.PhoneTypeId === phoneType.Id
                                                    }"
                                                ></i>
                                                <span class="fa fa-no-margin-right fa-{{ phoneTypeClass(phoneType.Id) }}"></span>&#160;
                                                {{ phoneType.Name }}</a
                                            >
                                        </li>
                                    </ul>
                                </span>
                            </span>
                            <div class="row">
                                <div
                                    class="col-md-8"
                                    style="padding-right: 0;"
                                    [ngClass]="{
                                        'has-error':
                                            parentForm.get('phones')['controls'][i].touched &&
                                            parentForm.get('phones')['controls'][i].dirty &&
                                            !parentForm.get('phones')['controls'][i].valid
                                    }"
                                >
                                    <input
                                        #input
                                        name="Phone"
                                        minlength="10"
                                        type="tel"
                                        class="form-control"
                                        id="inputPhone"
                                        formControlName="Phone"
                                        phoneMask
                                    />
                                </div>
                                <div class="col-md-4" style="padding-left: 0;">
                                    <div class="input-group" style="width: 100%">
                                        <span class="input-group-addon" id="ext-addon">Ext:</span>
                                        <input
                                            aria-describedby="ext-addon"
                                            type="tel"
                                            name="ext"
                                            class="form-control"
                                            id="inputExt"
                                            formControlName="Extension"
                                        />
                                    </div>
                                </div>
                            </div>
                            <span class="input-group-btn">
                                <button
                                    type="button"
                                    class="btn btn-default btn-flat"
                                    (click)="setPhonePref(i)"
                                    [ngClass]="{
                                        selected: phone.value.IsPrimary
                                    }"
                                >
                                    <i class="fa"></i>
                                    <i class="fa fa-fw fa-star"></i>
                                    <i class="fa"></i>
                                </button>
                            </span>
                            <span class="input-group-btn">
                                <button type="button" class="btn btn-default btn-flat" (click)="removePhone(i)">
                                    <i class="fa"></i>
                                    <i class="fa fa-fw fa-remove"></i>
                                    <i class="fa"></i>
                                </button>
                            </span>
                        </div>
                    </ngb-panel>
                </div>
            </div>
            <div *ngIf="canAdd" class="fab-wrap" ng-show="phoneHover === true">
                <button type="button" class="btn btn-primary btn-fab-md btn-fab-center" (click)="addEmptyPhone()">
                    <i class="fa fa-plus"></i>
                </button>
            </div>
            <br />
            <br />
        </div>
    `,
})
export class MtPhoneComponent implements OnInit, AfterViewInit, OnDestroy {
    public errorHandler = new ComponentErrorHandler('MtPhoneComponent', '@mt-ng2/phone-control');

    _PhoneArray: any[] = [];
    get PhoneArray(): any[] {
        return this._PhoneArray;
    }
    @Input('PhoneArray')
    set PhoneArray(value: any[]) {
        this._PhoneArray = value;
        if (this.parentForm) {
            this.loadForm();
        }
    }

    @Input('PhoneTypes') PhoneTypes: IPhoneType[] = DefaultPhoneTypes;

    @Input() parentForm: FormGroup;
    @ViewChildren('input') rows: QueryList<ElementRef>;

    focusSubscription: Subscription;
    isEditing: Boolean = true;

    @Input() defaultPhoneTypeId: number;

    @Input() canAdd = true;

    getDefaultPhoneTypeId(): number {
        if (typeof this.defaultPhoneTypeId !== 'undefined') {
            return this.defaultPhoneTypeId;
        }
        if (this.PhoneTypes === DefaultPhoneTypes) {
            return 2;
        }
        return this.PhoneTypes[0].Id;
    }

    /**
     * Function for flipping the phone icon based on the type
     * of phone it is displaying
     * @param phoneTypeId
     */
    phoneTypeClass(phoneTypeId: number): string {
        let phoneType = this.PhoneTypes.find((pt) => pt.Id === phoneTypeId);
        return phoneType?.IconFaClass ?? 'phone';
    }

    newPhone(IsPrimary = false): IPhone {
        return {
            Extension: '',
            IsPrimary: IsPrimary,
            Phone: '',
            PhoneTypeId: this.getDefaultPhoneTypeId(),
        };
    }

    constructor(private fb: FormBuilder) {}

    ngOnInit(): void {
        this.loadForm();
    }

    ngAfterViewInit(): void {
        // focus the element the first time
        if (!this.focusSubscription) {
            this.rows.last?.nativeElement?.focus?.();
            // focus on the last item when something changes
            this.focusSubscription = this.rows.changes.subscribe((row) => {
                if (row.last?.nativeElement) {
                    row.last.nativeElement.focus();
                }
            });
        }
    }

    ngOnDestroy(): void {
        if (this.focusSubscription) {
            this.focusSubscription.unsubscribe();
        }
    }

    loadForm(): void {
        if (!this.parentForm) {
            this.parentForm = this.fb.group({});
        }
        // map individual phones in phone array input as separate form groups
        const phoneFGs = this._PhoneArray
            .sort((a, b) => {
                return b.IsPrimary - a.IsPrimary;
            })
            .map((phone) => this.fb.group(phone));
        const phoneFormArray = this.fb.array(phoneFGs);
        if (phoneFormArray.length === 0) {
            // First phone so show it as primary
            phoneFormArray.push(this.fb.group(this.newPhone(true)));
        }

        if (this.parentForm.contains('phones')) {
            this.parentForm.setControl('phones', phoneFormArray);
        } else {
            this.parentForm.addControl('phones', phoneFormArray);
        }
    }

    get phones(): any {
        return this.parentForm.get('phones');
    }

    get phonesControls(): any {
        const controlsProperty = 'controls';
        return this.parentForm.get('phones')[controlsProperty];
    }

    setPhoneType(index: number, type: number): void {
        const phoneArray = this.getPhoneArray();
        if (!phoneArray[index]) {
            this.errorHandler.addError('index parameter in setPhoneType is out of range');
        }
        if (!this.PhoneTypes.find((pt) => pt.Id === type)) {
            this.errorHandler.addError('type parameter in setPhoneType is not a valid phoneTypeId');
        }
        phoneArray[index].PhoneTypeId = type;
        (this.parentForm.get('phones') as FormArray).patchValue(phoneArray);
    }

    setPhonePref(index: number): void {
        const phoneArray = this.getPhoneArray();
        if (!phoneArray[index]) {
            this.errorHandler.addError('index parameter in setPhonePref is out of range');
        }
        for (let i = 0; i < phoneArray.length; i++) {
            phoneArray[i].IsPrimary = i === index;
        }
        (this.parentForm.get('phones') as FormArray).patchValue(phoneArray);
    }

    removePhone(index: number): void {
        let phoneArray = this.getPhoneArray();
        if (!phoneArray[index]) {
            this.errorHandler.addError('index parameter in removePhone is out of range');
        }
        phoneArray = <FormArray>this.parentForm.get('phones');
        if (phoneArray.at(index).value.IsPrimary) {
        }
        phoneArray.removeAt(index);
        if (phoneArray.length === 0) {
            phoneArray.push(this.fb.group(this.newPhone(true)));
        } else {
            phoneArray.at(0).patchValue({ IsPrimary: true });
        }
    }

    addEmptyPhone(): void {
        if (!this.canAdd) {
            return;
        }
        // This at some point calls setPhoneArray and updating that fixed this issue.
        (this.phones as FormArray).push(this.fb.group(this.newPhone()));
    }

    private getPhoneArray(): any {
        return this.parentForm.get('phones').value;
    }
}
