import { Directive, ElementRef, OnInit, HostListener } from '@angular/core';
import { PhonePipe } from './phone.pipe';

@Directive({
    selector: '[phoneMask]',
})
export class MtPhoneMaskDirective implements OnInit {
    private el: HTMLInputElement;
    private phonePipe: PhonePipe;
    constructor(private elementRef: ElementRef) {
        this.phonePipe = new PhonePipe();
        this.el = this.elementRef.nativeElement;
    }

    ngOnInit(): void {
        this.el.value = this.phonePipe.transform(this.el.value);
    }

    @HostListener('ngModelChange', ['$event'])
    onInput(value): void {
        this.el.value = this.phonePipe.transform(value.replace(/\D/g, ''));
    }
}
