import { Component, Input } from '@angular/core';

import { CustomFormControlComponentBase } from '../custom-form-component.base';
import { DynamicField } from '../../libraries/dynamic-field.library';

@Component({
    selector: 'dynamic-form-select-wrapper',
    styles: [
        `
            .space-for-validation {
                margin-bottom: 15px;
            }
        `,
    ],
    templateUrl: './form-select-wrapper.component.html',
})
export class FormSelectWrapperComponent {
    @Input() parentControl: CustomFormControlComponentBase;

    get config(): DynamicField {
        return this.parentControl.config;
    }

    get hasError(): boolean {
        return this.parentControl.hasError();
    }

    constructor() {}
}
