import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { RouterLinkBuilder, BackButtonRouterLinks } from './router-links.library';

@Injectable({
    providedIn: 'root',
})
export class BackButtonService {
    routerLinks: BehaviorSubject<BackButtonRouterLinks[]> = new BehaviorSubject(null);

    currentNavigationRouterLink: BehaviorSubject<BackButtonRouterLinks> = new BehaviorSubject(null);
    previousNavigationRouterLink: BehaviorSubject<BackButtonRouterLinks> = new BehaviorSubject(null);

    routerLinkBuilder: RouterLinkBuilder;

    constructor(private router: Router) {
        this.routerLinkBuilder = new RouterLinkBuilder(this.router);
        this.setRouterLinks();
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                if (this.currentNavigationRouterLink.getValue()) {
                    this.previousNavigationRouterLink.next(this.currentNavigationRouterLink.getValue());
                }
                this.currentNavigationRouterLink.next(this.routerLinkBuilder.getRouterLink(val.url));
                this.setRouterLinks();
            }
        });
    }

    private setRouterLinks(): void {
        this.routerLinks.next(this.routerLinkBuilder.getAllRouterLinksBasedOnRoute());
    }

    goBack(): void {
        const routerLinks = this.routerLinks.getValue();
        const routerLink = (routerLinks && routerLinks[0]) || null;
        if (routerLink) {
            this.router.navigateByUrl(routerLink.url);
        }
    }
}
