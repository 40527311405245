import { ImageCropperComponent } from 'ngx-img-cropper';

export class ImageCropperHelper {
    public static loadImageInCropper(file: File, cropper: ImageCropperComponent): void {
        const image: any = new Image();
        const myReader: FileReader = new FileReader();
        myReader.onloadend = function(loadEvent: any): void {
            image.src = loadEvent.target.result;
            cropper.setImage(image);
        };
        myReader.readAsDataURL(file);
    }
}
