import { Component, EventEmitter, Input, OnInit, Output, AfterContentInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicField } from '../libraries/dynamic-field.library';
import { ClaimsService } from '@mt-ng2/auth-module';
import { IDynamicField } from '../libraries/interfaces/dynamic-field';

@Component({
    selector: 'app-dynamic-form',
    templateUrl: './dynamic-form.component.html',
})
export class DynamicFormComponent implements OnInit, AfterContentInit, OnDestroy {
    @Input() config: DynamicField[] = [];
    @Input() form: FormGroup;
    @Input() removeControlsOnDestroy = true;

    @Output() submitted: EventEmitter<any> = new EventEmitter<any>();
    @Output() formCreated: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

    constructor(private fb: FormBuilder, public claimsService: ClaimsService) {}

    ngOnInit(): void {
        this.form = this.createGroup();
        this.formCreated.emit(this.form);
    }

    ngOnDestroy(): void {
        if (this.removeControlsOnDestroy) {
            const group = !this.form ? this.fb.group({}) : this.form;
            this.config.forEach((control) => {
                let currentGroup = control.formGroup ? <FormGroup>group.get(control.formGroup) : group;
                currentGroup.removeControl(control.name);
            });
        }
    }

    createGroup(): FormGroup {
        const group = !this.form ? this.fb.group({}) : this.form;
        let formGroups = Array.from(new Set(this.config.map((control) => control.formGroup)));
        formGroups.forEach((fg) => {
            const exists = group.get(fg);
            if (!exists) {
                group.addControl(fg, this.fb.group({}));
            }
        });
        this.config.forEach((control) => {
            if (control.shouldCreateControl(this.claimsService)) {
                let currentGroup = control.formGroup ? <FormGroup>group.get(control.formGroup) : group;
                currentGroup.addControl(control.name, control.createControl(this.fb, this.claimsService));
            }
        });
        return group;
    }

    getFormGroup(field: IDynamicField): FormGroup {
        return field.formGroup ? <FormGroup>this.form.get(field.formGroup) : this.form;
    }

    ngAfterContentInit(): void {
        const temp = this.form.value;
        for (const field in temp) {
            if (!temp[field]) {
                temp[field] = '';
            }
        }
        this.form.patchValue(temp);
    }
}
