import {
    Directive,
    HostListener,
    ElementRef,
    Input,
    Renderer2,
    OnChanges,
    Output,
    EventEmitter,
} from '@angular/core';

/**
 * [mt-doubleClickDisabled] directive is used to disable a button while waiting for an asynchronous action to complete.
 */
@Directive({
    selector: '[mt-doubleClickDisabled]',
})
export class MtPreventDoubleClickDirective implements OnChanges {
    /**
     * Pass in a boolean variable that will define if the element should be disabled.
     *
     * Example:
     *       doubleClickIsDisabled = false;
     */
    @Input() doubleClickIsDisabled: boolean;

    /**
     * Emit an event to the parent component so that the onchanges will fire when overriding the click
     */
    @Output() doubleClickIsDisabledChange = new EventEmitter<any>();

    /**
     * Override the click and set disabled to true until handle via the promise by the developer.
     */
    @HostListener('click', [])
    onClick(): void {
        // Need to set a default time out here.  With out this when the button triggers a form submit
        // disabling it to fast will stop the form submit event
        // Reference from comment here https://stackoverflow.com/q/46317618/5997923
        setTimeout(() => {
            this.doubleClickIsDisabled = true;
            this.doubleClickIsDisabledChange.emit(this.doubleClickIsDisabled);
        });
    }

    constructor(private el: ElementRef, private renderer: Renderer2) {}

    /**
     * Listens for updates on the input property and toggles it's disabled status.
     * @param changes
     */
    ngOnChanges(changes): void {
        if (changes.doubleClickIsDisabled) {
            this.toggleButton(changes.doubleClickIsDisabled.currentValue);
        }
    }

    /**
     * Takes in a boolean variable and toggles the elements disabled status.
     * @param isDisabled
     */
    toggleButton(isDisabled: boolean): void {
        if (isDisabled) {
            this.renderer.setAttribute(
                this.el.nativeElement,
                'disabled',
                'true',
            );
        } else {
            this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
        }
    }
}
