import { InjectionToken } from '@angular/core';

import { IModalOptions } from '@mt-ng2/modal-module';

/**
 * Interface representing the customizable properties
 * of the alerts module.
 * @example
 * ```typescript
 * const alertsModuleConfig: IAlertsModuleConfig = {
 *     mtConfirmOptions: { allowOutsideClick: false },
 * };
 * ```
 */
export interface IAlertsConfig {
    mtConfirmOptions?: IModalOptions;
}

/**
 * Token used for providing the IAlertsModuleConfig
 * @example
 * ```typescript
 * // in the app or shared modules providers section
 * providers: [
 *     { provide: AlertsModuleConfigToken, useValue: alertsModuleConfig },
 * ],
 * ```
 */
export const AlertsModuleConfigToken = new InjectionToken<IAlertsConfig>('alerts_module_config');
