import { Injectable } from '@angular/core';

import { EntityListConfig } from '../libraries/entity-list.library';
import { buildDataForExport } from '../libraries/build-data-for-export.library';
import { EntityListPipeService } from './entity-list-pipe.service';

import { common } from '@mt-ng2/common-functions';

@Injectable()
export class EntityListExportService {
    constructor(private entityListPipeService: EntityListPipeService) {}

    export(data: any[], entityListConfig: EntityListConfig): void {
        if (!entityListConfig.export) {
            console.warn('EntityListExportService :: export :: entityListConfig is missing export property');
            return;
        }
        const dataToExport = buildDataForExport(data, entityListConfig, this.entityListPipeService);
        common.exportAsCsv(dataToExport, entityListConfig.export.exportName);
    }
}
