import { Directive, ElementRef, TemplateRef, ViewContainerRef, Input, OnDestroy, OnInit, isDevMode, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';

import { ComponentErrorHandler } from './component-error-handler.library';

@Directive({
    selector: '[componentErrorHandler]',
})
export class ComponentErrorHandlerDirective implements OnInit, OnDestroy {
    @Input() public readonly componentErrorHandler: ComponentErrorHandler;

    private subscriptions = new Subscription();
    private hasAddedVisualErrorMessage = false;

    public constructor(
        private element: ElementRef,
        private templateRef: TemplateRef<any>,
        private renderer: Renderer2,
        private viewContainer: ViewContainerRef,
    ) {}

    ngOnInit(): void {
        this.updateView(this.componentErrorHandler.hasErrors.getValue());
        this.subscriptions.add(this.componentErrorHandler.hasErrors.subscribe((hasErrors) => this.updateView(hasErrors)));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private updateView(hasErrors: boolean): void {
        if (isDevMode() && hasErrors) {
            this.viewContainer.clear();
            this.addVisualErrorMessage();
        } else {
            if (!this.viewContainer.length) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
        }
    }

    private addVisualErrorMessage(): void {
        if (this.hasAddedVisualErrorMessage) {
            return;
        }

        const div = this.renderer.createElement('div');
        const text = this.renderer.createText(this.componentErrorHandler.getErrorMessage());
        this.renderer.appendChild(div, text);
        this.renderer.appendChild(this.element.nativeElement.parentElement, div);
        this.hasAddedVisualErrorMessage = true;
    }
}
