import { CustomFormControlComponentBase } from '../form-elements/custom-form-component.base';
import { DynamicField } from './dynamic-field.library';
import { DynamicLabel } from './dynamic-label.library';
import { IDynamicFormModuleConfig, DynamicFormModuleConfigToken, CustomErrorMessageHandler } from '@mt-ng2/dynamic-form-config';
import { Injectable, Inject, Optional } from '@angular/core';

export type CustomFormComponentFunction = (dynamicField: DynamicField) => typeof CustomFormControlComponentBase;

export type CustomFormComponentDisplayValueFunction = (dynamicLabel: DynamicLabel) => typeof CustomFormControlComponentBase;

@Injectable({
    providedIn: 'root',
})
export class DynamicFormModuleConfig implements IDynamicFormModuleConfig {
    commonService: any;
    customFormComponentFunction?: CustomFormComponentFunction;
    customFormComponentDisplayValueFunction?: CustomFormComponentDisplayValueFunction;
    markAsTouchedOnFocus?: boolean;
    datePickerShowClearButton?: boolean;
    errorMessageHandler?: CustomErrorMessageHandler;
    timePickerShowClearButton?: boolean;

    constructor(@Optional() @Inject(DynamicFormModuleConfigToken) config: IDynamicFormModuleConfig) {
        // we must have a config passed in, since we require the common service
        if (!config) {
            throw new Error(
                'DynamicForm requires a config to be passed in, but none was found.  Please provide a config using the DynamicFormModuleConfigToken. Check the ReadMe of this package for detailed instructions.',
            );
        }

        // we may have received just the CommonService as that is how it use to be passed in
        // if so, construct a proper config for it
        // either way, assign the final value to the moduleConfig
        if (config && !config.commonService) {
            this.commonService = config;
        } else {
            Object.assign(this, config);
        }
    }
}
