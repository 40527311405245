import { Injectable, Injector } from '@angular/core';
import { AuthService } from '@mt-ng2/auth-module';

import { ICurrentUser } from '../libraries/current-user';
import { IErrorsModuleConfig, ErrorsModuleConfigToken } from '../config/errors-module-config';

@Injectable({ providedIn: 'root' })
export class CurrentUserService {
    get errorsModuleConfig(): IErrorsModuleConfig {
        return this.injector.get(ErrorsModuleConfigToken, null);
    }

    get authService(): AuthService {
        return this.injector.get(AuthService);
    }

    constructor(private injector: Injector) {}

    getCurrentUser(): ICurrentUser {
        if (this.errorsModuleConfig && this.errorsModuleConfig.getCurrentUserOverrideFunction) {
            return this.errorsModuleConfig.getCurrentUserOverrideFunction();
        }
        return this.authService.currentUser.getValue();
    }
}
