import { Component, Injector, Input } from '@angular/core';

import { EntityListConfig } from '../libraries/entity-list.library';
import { EntityListExportService } from '../services/entity-list-export.service';

@Component({
    selector: 'entity-list-export',
    styles: [
        `
            .export-button .btn {
                border-radius: 3px 3px 0 0;
                background-color: #ccc;
                border: 0;
            }
        `,
    ],
    template: `
        <span class="export-button">
            <button type="button" class="btn btn-default btn-xs btn-flat" (click)="export()"><i class="fa fa-xs fa-file-excel-o"></i> Export</button>
        </span>
    `,
})
export class EntityListExportComponent {
    @Input() entityListConfig: EntityListConfig;

    protected entityListExportService: EntityListExportService;

    constructor(injector: Injector) {
        this.entityListExportService = injector.get(EntityListExportService);
    }

    export(): void {
        if (!this.entityListConfig.hasExport) {
            return;
        }

        if (this.entityListConfig.export.onExportClick) {
            this.entityListConfig.export.onExportClick();
        }

        if (this.entityListConfig.export.getDataForExport) {
            this.entityListConfig.export.getDataForExport().subscribe((data) => {
                this.entityListExportService.export(data, this.entityListConfig);
            });
        }
    }
}
