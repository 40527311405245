import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { BackButtonService } from './back-button.service';
import { BackButtonRouterLinks } from './router-links.library';

@Component({
    selector: 'route-back-button',
    styles: [
        `
            .show.dropdown {
                display: inline !important;
            }
            .back-button {
                padding-left: 4px;
                padding-right: 6px;
                padding-top: 3px;
                padding-bottom: 0;
                margin-top: -3px;
            }
            .dropdown-toggle {
                padding-left: 2px;
                padding-right: 2px;
                padding-top: 2px;
                padding-bottom: 1px;
            }
            .dropdown-menu > li > a {
                cursor: pointer;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 6px;
                padding-bottom: 3px;
                line-height: 0.8;
            }
            .btn-group {
                margin-top: -3px;
            }
        `,
    ],
    template: ` <span *ngIf="routerLinks && routerLinks.length > 0" class="btn-group">
        <a class="btn btn-primary back-button" [routerLink]="getBackRouterLink()" [attr.title]="getBackRouteTitle()">
            <i class="fa fa-chevron-left"></i>
        </a>
        <span ngbDropdown class="btn-group dropdown">
            <button ngbDropdownToggle class="btn btn-primary dropdown-toggle" type="button">
                <span class="caret"></span>
            </button>
            <ul ngbDropdownMenu class="dropdown-menu">
                <li *ngFor="let link of routerLinks">
                    <a [routerLink]="link.url">
                        {{ link.title }}<br />
                        <span class="small">{{ link.url }}</span>
                    </a>
                </li>
            </ul>
        </span>
    </span>`,
})
export class BackButtonComponent implements OnDestroy, OnInit {
    @Input('urlSegmentsToIgnore') urlSegmentsToIgnore: string[];

    subscriptions: Subscription = new Subscription();
    routerLinks: BackButtonRouterLinks[];

    constructor(private router: Router, private backButtonService: BackButtonService) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.backButtonService.routerLinks.subscribe((routerLinks) => {
                if (!this.urlSegmentsToIgnore || !this.urlSegmentsToIgnore.length) {
                    this.routerLinks = routerLinks;
                } else {
                    this.routerLinks = routerLinks.filter((link) => {
                        const urlSegments = link.url.split('/');
                        const lastSegment = urlSegments[urlSegments.length - 1];
                        const segmentShouldBeIgnored = this.urlSegmentsToIgnore.some((segment) => segment === lastSegment);
                        return !segmentShouldBeIgnored;
                    });
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    goBack(): void {
        this.navigateByRouterLink(this.routerLinks[0]);
    }

    getBackRouteTitle(): string {
        const title = (this.routerLinks && this.routerLinks[0] && this.routerLinks[0].title) || '';
        return title ? `back to ${title}` : null;
    }

    getBackRouterLink(): string {
        return this.routerLinks[0].url;
    }

    navigateByRouterLink(routerLink: BackButtonRouterLinks): void {
        this.router.navigateByUrl(routerLink.url);
    }
}
