import { ValidationErrors } from '@angular/forms';

import { CustomErrorMessageHandler } from '@mt-ng2/dynamic-form-config';

import { DynamicField } from '../libraries/dynamic-field.library';
import { isString } from '../libraries/utilities.library';

export class CustomFormValidation {
    static getErrorMessageFromKey(
        key: string,
        errors: ValidationErrors,
        config: DynamicField,
        customErrorMessageHandler: CustomErrorMessageHandler,
    ): string {
        // if custom error message handler returns a message, then return that as the answer
        let message = customErrorMessageHandler?.(key, errors, config);
        if (message) {
            return message;
        }

        // else if we have a message being returned by the validator itself, then return that as the answer
        if (isString(errors?.[key])) {
            return errors[key];
        }

        // else use our defaults to create a message
        switch (key) {
            case 'required':
            case 'noZeroRequired':
                // if the required symbol (*) is showing, no need to create a message for required error
                if (this.showRequired(config)) {
                    return '';
                }
                return `${config.label} is required`;
            case 'minlength':
                return `${config.label} must be at least ${config.validators.minlength} characters`;
            case 'email':
                return `Invalid email address. Valid email can contain only letters, numbers and '@'`;
            case 'pattern':
                return config.failedPatternMessage;
            default:
                return '';
        }
    }

    protected static showRequired(config: DynamicField): boolean {
        return config?.validators?.required && config?.validators?.showRequired;
    }
}
