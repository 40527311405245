import { NotificationTypes } from './notification-types';

export interface IToastableError {
    errorMessage: string; // the message to throw in the toast
    notificationType: NotificationTypes; // determines which method of the notifications service to use
}

export function IsToastableErrorGuard(object: any): object is IToastableError {
    // helper function to check if error is of type IToastableError
    return 'errorMessage' in object && 'notificationType' in object;
}
