import { FormControl, Validators } from '@angular/forms';

/**
 * email validator that returns valid when the value is empty
 * @param control
 */
export function emailEmptyAcceptedValidator(control: FormControl): any {
    if (!control.value) {
        return null;
    }
    return Validators.email(control);
}
