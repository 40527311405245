import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { FileUploader, FileLikeObject, FileItem } from 'ng2-file-upload';

import { DocumentControlModuleConfig } from './libraries/module-config.library';

@Component({
    selector: 'mt-document',
    template: ` <h4>{{ headerTitle }}</h4>

        <div class="row" *ngIf="uploader">
            <div class="col-xs-4">
                <div ng2FileDrop [uploader]="uploader" class="drop-box">Drop files here</div>
            </div>
            <div class="col-xs-8">
                <div>or select a file using the file uploader</div>
                <input type="file" ng2FileSelect [uploader]="uploader" />
            </div>
        </div>`,
})
export class MtDocumentComponent implements OnInit {
    // allowedMimeType and maxFileSize are handled using private variables and setters
    // so that when they are set, we can check if the uploader has already been initialized
    // if it has, then we need to reinitialize

    private _allowedMimeType = this.config.allowedMimeType;
    @Input('allowedMimeType')
    set allowedMimeType(value: string[]) {
        this._allowedMimeType = value;
        if (this.afterInitialLoad) {
            this.initializeUploader();
        }
    }
    get allowedMimeType(): string[] {
        return this._allowedMimeType;
    }

    private _maxFileSize = this.config.maxFileSize;
    @Input('maxFileSize')
    set maxFileSize(value: number) {
        this._maxFileSize = value;
        if (this.afterInitialLoad) {
            this.initializeUploader();
        }
    }
    get maxFileSize(): number {
        return this._maxFileSize;
    }

    @Input() headerTitle = 'Upload Document';

    @Output('onAddingFileFailed') onAddingFileFailed: EventEmitter<string> = new EventEmitter<string>();
    @Output('onAfterAddingFile') onAfterAddingFile: EventEmitter<FileItem> = new EventEmitter<FileItem>();

    uploader: FileUploader;

    afterInitialLoad = false;

    constructor(private config: DocumentControlModuleConfig) {}

    ngOnInit(): void {
        this.initializeUploader();
        this.afterInitialLoad = true;
    }

    initializeUploader(): void {
        this.uploader = new FileUploader({
            allowedMimeType: this._allowedMimeType,
            isHTML5: true,
            maxFileSize: this._maxFileSize,
        });
        this.uploader.onWhenAddingFileFailed = (item, filter, options) => this.addingFileFailed(item, filter, options);
        this.uploader.onAfterAddingFile = (item) => this.afterAddingFile(item);
    }

    addingFileFailed(item: FileLikeObject, filter: any, options: any): void {
        let errorMessage: string;
        switch (filter.name) {
            case 'fileSize':
                errorMessage = `Maximum upload size exceeded (${item.size} of ${this._maxFileSize} allowed)`;
                break;
            case 'mimeType':
                const allowedTypes = this._allowedMimeType.join(', ');
                errorMessage = `Type "${item.type}" is not allowed. Allowed types: "${allowedTypes}"`;
                break;
            default:
                errorMessage = `Unknown error (filter is ${filter.name})`;
        }
        this.onAddingFileFailed.emit(errorMessage);
    }

    afterAddingFile(file: FileItem): void {
        this.onAfterAddingFile.emit(<FileItem>file);
    }
}
