import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AccessRequestsModuleConfigService {
    private _accessRequestsEnabled: BehaviorSubject<boolean>;

    /**
     * Used to determine if Access Requests is enabled in the current system.
     * Can be accessed by many different packages such as AccessRequestsModule,
     * NavModule, and AuthModule.
     */
    get accessRequestsEnabled(): Observable<boolean> {
        // if we already have the answer, just return it
        if (this._accessRequestsEnabled) {
            return this._accessRequestsEnabled.asObservable();
        }
        // otherwise set it up for next time
        return this.setupAccessRequestsEnabled();
    }

    public noAccessPageUrl = '/access-requests/no-access-page';

    constructor(private http: HttpClient) {}

    /**
     * Checks with the BackEnd to determine whether AccessRequests are enabled
     * and stores that value for next time in _accessRequestsEnabled
     */
    setupAccessRequestsEnabled(): Observable<boolean> {
        // using a get call to /access-requests/check-enabled to determine if it is enabled
        return this.http.get('/access-requests/check-enabled').pipe(
            map(() => true),
            catchError((error: HttpErrorResponse) => {
                if (error && error.status === 404) {
                    return of(false);
                } else {
                    return of(true);
                }
            }),
            tap((value) => (this._accessRequestsEnabled = new BehaviorSubject<boolean>(value))),
        );
    }
}
