import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { KeyboardKeys, IKeyboardShortcutListenerOptions } from '@mt-ng2/keyboard-shortcuts-module';

import { BackButtonService } from './back-button.service';
import { BackButtonRouterLinks } from './router-links.library';
import { BackButtonModuleConfig } from './libraries/module-config.library';

export enum CloseButtonStrategy {
    Route,
    History,
}

@Component({
    selector: 'close-button',
    template: `
        <ng-container *ngIf="hasShortcutKeys">
            <a
                *ngIf="showButton"
                [routerLink]="getBackRouterLink()"
                class="btn btn-default"
                [keyboardShortcut]="shortcutKey"
                (click)="clicked($event)"
                [attr.title]="getBackRouteTitle()"
                [innerHtml]="buttonInnerHtml | safe: 'html'"
            >
            </a>
        </ng-container>
        <ng-container *ngIf="!hasShortcutKeys">
            <a
                *ngIf="showButton"
                [routerLink]="getBackRouterLink()"
                class="btn btn-default"
                (click)="clicked($event)"
                [attr.title]="getBackRouteTitle()"
                [innerHtml]="buttonInnerHtml | safe: 'html'"
            >
            </a>
        </ng-container>
    `,
})
export class CloseButtonComponent implements OnDestroy, OnInit {
    @Input('buttonInnerHtml') buttonInnerHtml = this.hasShortcutKeys ? '<strong style="text-decoration: underline;">C</strong>lose' : 'Close';
    @Input('shortcutKeyCombination')
    shortcutKey: IKeyboardShortcutListenerOptions = {
        description: 'Close button',
        keyBinding: [KeyboardKeys.Alt, 'C'],
    };
    @Input('stopClickPropagation') stopClickPropagation = false;
    @Input('strategy') strategy = CloseButtonStrategy.Route;
    @Input('urlSegmentsToIgnore') urlSegmentsToIgnore: string[];

    subscriptions: Subscription = new Subscription();
    routerLinks: BackButtonRouterLinks[];
    showButton = false;

    get hasShortcutKeys(): boolean {
        return this.moduleConfig.hasKeyboardShortcutsModule;
    }

    constructor(private router: Router, private backButtonService: BackButtonService, private moduleConfig: BackButtonModuleConfig) {}

    ngOnInit(): void {
        if (this.strategy === CloseButtonStrategy.Route) {
            this.addRouteSubscription();
        } else {
            this.addHistorySubscription();
        }
    }

    private addRouteSubscription(): void {
        this.subscriptions.add(
            this.backButtonService.routerLinks.subscribe((routerLinks) => {
                if (!this.urlSegmentsToIgnore || !this.urlSegmentsToIgnore.length) {
                    this.routerLinks = routerLinks;
                } else {
                    this.routerLinks = routerLinks.filter((link) => {
                        const urlSegments = link.url.split('/');
                        const lastSegment = urlSegments[urlSegments.length - 1];
                        const segmentShouldBeIgnored = this.urlSegmentsToIgnore.some((segment) => segment === lastSegment);
                        return !segmentShouldBeIgnored;
                    });
                }
                this.showButton = routerLinks.length ? true : false;
            }),
        );
    }

    private addHistorySubscription(): void {
        this.subscriptions.add(
            this.backButtonService.previousNavigationRouterLink.subscribe((link) => {
                if (link) {
                    this.routerLinks = [link];
                    this.showButton = true;
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    goBack(): void {
        this.navigateByRouterLink(this.routerLinks[0]);
    }

    getBackRouteTitle(): string {
        const title = (this.routerLinks && this.routerLinks[0] && this.routerLinks[0].title) || '';
        return title ? `back to ${title}` : null;
    }

    getBackRouterLink(): string {
        return this.routerLinks[0].url;
    }

    clicked(event: Event): void {
        if (this.stopClickPropagation) {
            event.stopPropagation();
        }
    }

    private navigateByRouterLink(routerLink: BackButtonRouterLinks): void {
        this.router.navigateByUrl(routerLink.url);
    }
}
