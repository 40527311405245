import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PrintModeService {
    printMode = new BehaviorSubject(false);

    constructor() {}

    togglePrintMode(): void {
        const toggledPrintMode = !this.printMode.getValue();
        this.printMode.next(toggledPrintMode);
    }

    closePrintMode(): void {
        if (this.printMode.getValue()) {
            this.togglePrintMode();
        }
    }
}
