import { Component, OnInit, Injector, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { IAddressContainer } from '@mt-ng2/dynamic-form';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IEntityRouteConfig } from '@mt-ng2/entity-components-base';
import { IItemDeletedEvent, IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { IHasAddresses } from '../common-addresses.component';
import { AddressesEntityListConfig } from './common-addresses-list.entity-list-config';
import { EntityComponentsAddressesModuleConfig } from '../libraries/module-config.library';
import { AddressContainer } from '../libraries/address-container';

@Component({
    selector: 'app-common-addresses-list',
    template: `
        <div *ngIf="!selectedAddress || !canEdit">
            <div>
                <div class="input-group">
                    <input type="text" [formControl]="searchControl" class="form-control" placeholder="Search" />
                    <span class="input-group-btn">
                        <button type="button" (click)="clearSearch()" class="btn btn-default btn-nohover btn-flat">
                            <i class="fa"></i>
                            <i class="fa fa-remove" aria-hidden="true"></i>
                            <i class="fa"></i>
                        </button>
                    </span>
                </div>
                <br />
            </div>
            <entity-list
                [entities]="addresses"
                [itemsPerPage]="itemsPerPage"
                [total]="total"
                [(currentPage)]="currentPage"
                (onPageChanged)="getAddresses()"
                (onItemSelected)="selectAddress($event)"
                (onItemDeleted)="deleteAddress($event)"
                (onColumnSorted)="columnSorted($event)"
                [entityListConfig]="entityListConfig"
            >
            </entity-list>
            <a [routerLink]="['../']" class="btn btn-default">Back</a>
            <div *ngIf="canEdit" class="fab-wrap-b-r" (click)="addAddress()">
                <a class="btn btn-primary btn-fab-lg">
                    <span class="fa fa-plus"></span>
                </a>
            </div>
        </div>
        <div *ngIf="selectedAddress && canEdit" class="miles-form padded">
            <h4>
                {{ selectedAddress.AddressId > 0 ? 'Edit ' + getComponentName() : 'Add ' + getComponentName() }}
            </h4>
            <form [formGroup]="addressForm" (ngSubmit)="save()">
                <mt-address
                    [addressContainer]="selectedAddress"
                    [parentForm]="addressForm"
                    [allowInternationalAddresses]="allowInternationalAddresses"
                ></mt-address>
                <button type="submit" class="btn btn-flat btn-success">Save</button>
                <button type="button" class="btn btn-flat btn-default" (click)="cancel()">Cancel</button>
                <button *ngIf="selectedAddress.AddressId > 0" type="button" class="btn btn-flat btn-danger pull-right" (mtConfirm)="delete()">
                    Delete
                </button>
            </form>
        </div>
    `,
})
export class CommonAddressesListComponent implements OnInit {
    id: number;
    service: IHasAddresses;
    searchControl = new FormControl();
    addresses: IAddressContainer[];
    itemsPerPage = 12;
    currentPage = 1;
    total: number;
    canEdit = false;
    addressForm: FormGroup;

    protected _disableSort: boolean;
    @Input('disableSort')
    set disableSort(value: boolean) {
        this._disableSort = value;
    }
    entityListConfig: AddressesEntityListConfig;
    order: string;
    orderDirection: string;

    entityName: string;
    allowInternationalAddresses: boolean;

    protected _selectedAddress: IAddressContainer = null;
    get selectedAddress(): IAddressContainer {
        return this._selectedAddress;
    }
    set selectedAddress(value: IAddressContainer) {
        if (value === null) {
            this.addressForm = new FormGroup({});
        }
        this._selectedAddress = value;
    }

    protected route: ActivatedRoute;
    protected notificationsService: NotificationsService;
    protected claimsService: ClaimsService;
    protected config: EntityComponentsAddressesModuleConfig;

    constructor(protected injector: Injector) {
        this.route = injector.get(ActivatedRoute);
        this.notificationsService = injector.get(NotificationsService);
        this.claimsService = injector.get(ClaimsService);
        this.config = injector.get(EntityComponentsAddressesModuleConfig);
        this._disableSort = this.config.disableSort;
        this.entityListConfig = new AddressesEntityListConfig(false, this._disableSort);
        this.order = this.entityListConfig.getDefaultSortProperty();
        this.orderDirection = this.entityListConfig.getDefaultSortDirection();
    }

    ngOnInit(): void {
        this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe((value) => this.getAddresses());

        this.addressForm = new FormGroup({});

        const config: IEntityRouteConfig = <IEntityRouteConfig>this.route.parent.snapshot.data;
        this.id = +this.route.parent.snapshot.paramMap.get(config.entityIdParam);
        this.canEdit = this.claimsService.hasClaim(config.claimType, [ClaimValues.FullAccess]);
        const service = this.getServiceFromConfig(config);
        this.service = this.injector.get(service);
        this.entityName = (config && (<any>config).adressesEntityName) || null;
        this.allowInternationalAddresses = config?.allowInternationalAddresses ?? false;
        if (this.allowInternationalAddresses) {
            this.entityListConfig = new AddressesEntityListConfig(true, this._disableSort);
        }
        this.getAddresses();
    }

    getServiceFromConfig(config: IEntityRouteConfig): any {
        let childRoute = this.route.snapshot;
        while (childRoute.firstChild) {
            childRoute = childRoute.firstChild;
        }
        const childConfig = childRoute.data;
        return childConfig?.service ?? config?.service;
    }

    getAddresses(): void {
        const search = this.searchControl.value;
        const searchparamProperties: IEntitySearchParams = {
            order: this.order,
            orderDirection: this.orderDirection,
            query: search?.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };
        const searchparams = new SearchParams(searchparamProperties);

        this.service.getAddresses(this.id, searchparams).subscribe((response) => {
            this.addresses = response.body;
            this.total = +response.headers.get('X-List-Count');
        });
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getAddresses();
    }

    getComponentName(): string {
        if (this.entityName) {
            return this.entityName;
        }
        return 'Address';
    }

    clearSearch(): void {
        this.searchControl.setValue('');
    }

    addAddress(): void {
        this.selectedAddress = AddressContainer.getNew();
    }

    save(): void {
        if (this.addressForm.valid) {
            const address = { ...this._selectedAddress };
            address.AddressId = this.addressForm.value.Id;
            address.Address = {
                Address1: this.addressForm.value.Address1,
                Address2: this.addressForm.value.Address2,
                City: this.addressForm.value.City,
                CountryCode: this.addressForm.value.CountryCode,
                Id: this.addressForm.value.Id,
                Province: this.addressForm.value.Province,
                StateCode: this.addressForm.value.StateCode,
                Zip: this.addressForm.value.Zip,
            };
            address.IsPrimary = this.addressForm.value.IsPrimary;
            this.service.saveAddress(this.id, address).subscribe(
                (success) => {
                    this.notificationsService.success('Address Saved Successfully');
                    this.selectedAddress = null;
                    this.getAddresses();
                },
                (error) => this.notificationsService.error('Save Failed'),
            );
        } else {
            this.notificationsService.error('Save Failed');
            markAllFormFieldsAsTouched(this.addressForm);
        }
    }

    cancel(): void {
        this.selectedAddress = null;
    }

    delete(): void {
        if (this.addressForm.value.Id) {
            this.deleteAddressById(this.addressForm.value.Id);
        }
    }

    deleteAddress(event: IItemDeletedEvent): void {
        this.deleteAddressById(event.entity.Address.Id);
    }

    protected deleteAddressById(addressId: number): void {
        this.service.deleteAddress(this.id, addressId).subscribe(
            (success) => {
                this.notificationsService.success('Address Deleted');
                this.selectedAddress = null;
                this.getAddresses();
            },
            (error) => this.notificationsService.error('Delete Failed'),
        );
    }

    selectAddress(event: IItemSelectedEvent): void {
        let address = event.entity;
        if (this.selectedAddress === address) {
            this.selectedAddress = null;
        } else {
            this.selectedAddress = address;
        }
    }
}
