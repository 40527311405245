import { InjectionToken } from '@angular/core';

/**
 * Interface representing the customizable properties
 * of the back button module.
 * @example
 * const backButtonModuleConfig: IBackButtonModuleConfig = {
 *     hasKeyboardShortcutsModule: true,
 * };
 * @property {boolean} hasKeyboardShortcutsModule - default is false
 */
export interface IBackButtonModuleConfig {
    hasKeyboardShortcutsModule: boolean;
}

/**
 * Token used for providing the IBackButtonModuleConfig
 * @example
 * // in the app or shared modules providers section
 * providers: [
 *     { provide: BackButtonModuleConfigToken, useValue: backButtonModuleConfig },
 * ],
 */
export const BackButtonModuleConfigToken = new InjectionToken<IBackButtonModuleConfig>('back_button_module_config');
