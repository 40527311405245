import { InjectionToken } from '@angular/core';

/**
 * Interface representing the customizable properties
 * of the entity list module.
 * @example
 * const entityListModuleConfig: IEntityListModuleConfig = {
 *     itemsPerPage: 2,
 * };
 * @property itemsPerPage
 * @property selectedColor
 */
export interface IEntityListModuleConfig {
    itemsPerPage?: number;
    selectedColor?: string;
}

/**
 * Token used for providing the IEntityListModuleConfig
 * @example
 * // in the app or shared modules providers section
 * providers: [
 *     { provide: EntityListModuleConfigToken, useValue: entityListModuleConfig },
 * ],
 */
export const EntityListModuleConfigToken = new InjectionToken<IEntityListModuleConfig>('entity_list_module_config');
