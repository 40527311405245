import { Injectable, Inject, Optional } from '@angular/core';

import { IModalOptions, ModalService } from '@mt-ng2/modal-module';

import { KeyboardShortcutService } from 'ngx-keyboard-shortcuts';
import { KeyboardShortcutsModuleConfigToken, IKeyboardShortcutsModuleConfig, defaultModuleConfig } from '@mt-ng2/keyboard-shortcuts-module-config';

@Injectable({
    providedIn: 'root',
})
export class KeyboardShortcutHelpWindowService {
    options: IModalOptions = {
        html: '',
        showCancelButton: false,
        showCloseButton: false,
        showConfirmButton: false,
        title: 'Keyboard Shortcuts',
    };

    public constructor(
        private readonly keyboardShortcutsService: KeyboardShortcutService,
        private readonly modalService: ModalService,
        @Inject(KeyboardShortcutsModuleConfigToken)
        @Optional()
        private moduleConfig: IKeyboardShortcutsModuleConfig,
    ) {
        this.setModuleConfig(moduleConfig);
    }

    private setModuleConfig(moduleConfig: IKeyboardShortcutsModuleConfig): void {
        this.moduleConfig = Object.assign({}, defaultModuleConfig, this.moduleConfig);
        this.keyboardShortcutsService.listen({
            description: 'display this help window',
            handler: this.showHelpWindow.bind(this),
            keyBinding: this.moduleConfig.helpKeyBinding,
        });
    }

    public showHelpWindow(): void {
        this.options.html = this.buildHtml();
        this.modalService.showModal(this.options).subscribe();
    }

    private buildHtml(): string {
        let html = `
        <br/>
        <div style="text-align: left; margin-left: 30px;">
        `;
        const shortcutsToShow = this.keyboardShortcutsService.listeners_read_only;
        shortcutsToShow.forEach((s) => {
            html += `
            <div style="margin-bottom: 25px"><strong class="well well-sm">${s.displayShortcutCombination}</strong><span style="margin-left: 15px">${s.description}</span></div>
            `;
        });
        html += `
        </div>
        <br/>
        `;
        return html;
    }
}
