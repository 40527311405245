import { BehaviorSubject } from 'rxjs';

export class ComponentErrorHandler {
    private errors: Error[] = [];
    private warnings: string[] = [];
    public hasErrors = new BehaviorSubject<boolean>(false);

    constructor(private componentName: string, private packageName = '') {}

    addError(message: string): void {
        const error = new Error(this.buildMessage(message));
        if (this.errorAlreadyExists(error)) {
            console.log(`supressed duplicate error '${error.message}'`);
        } else {
            this.errors.push(error);
            if (!this.hasErrors.getValue()) {
                this.hasErrors.next(true);
            }
            throw error;
        }
    }

    private errorAlreadyExists(error: Error): boolean {
        return this.errors.some((e) => e.message === error.message);
    }

    private buildMessage(message: string): string {
        let prependedMessage = this.packageName ? this.packageName + ' : ' : '';
        prependedMessage += this.componentName + ' : ';
        prependedMessage += message;
        return prependedMessage;
    }

    addWarning(message: string): void {
        const warning = this.buildMessage(message);
        if (this.warningAlreadyExists(warning)) {
            console.log(`supressed duplicate warning '${warning}'`);
        } else {
            this.warnings.push(warning);
            console.warn(warning);
        }
    }

    private warningAlreadyExists(warning: string): boolean {
        return this.warnings.some((w) => w === warning);
    }

    checkNull(value: any, propName: string): void {
        if (value === null) {
            this.addError(`${propName} cannot be set to null`);
        }
    }

    getErrorMessage(): string {
        return `${this.componentName} has errors. See the console for details.`;
    }
}
