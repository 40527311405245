import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ConcurrencyService {
    constructor(private http: HttpClient) {}

    overWriteEntity(error: any): Observable<any> {
        return this.http.put(error.originalError.url, error.formObject);
    }
}
