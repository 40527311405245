import { IMatchingStrategy } from './MatcherFactory';
import { MatchingStrategy } from '@mt-ng2/type-ahead-control-config';

import { getValue } from '../type-ahead.library';
import { ComponentErrorHandler } from '@mt-ng2/component-error-handler';

export class StartsWithMatcher implements IMatchingStrategy {
    private _strategy: MatchingStrategy;

    constructor(strategy: MatchingStrategy) {
        this._strategy = strategy;
    }

    getCurrentStrategy(): MatchingStrategy {
        return this._strategy;
    }
    getHighlightedParts(resultStr: string, termLC: string): string[] {
        const resultLC = resultStr.toLowerCase();

        if (resultLC.startsWith(termLC)) {
            let result = [];

            const firstInstance = resultStr.substr(0, resultLC.indexOf(termLC) + termLC.length);
            const restOfTheString = resultStr.substr(resultLC.indexOf(termLC) + termLC.length);
            result.push(''); // They styling of the highlighter (which could use some work) expects the first part to be an empty string.
            result.push(firstInstance);
            result.push(restOfTheString);
            return result;
        }

        return [];
    }

    getItems(items: any[], searchText: string, errorHandler: ComponentErrorHandler, nameProperty = ''): any[] {
        return items.filter((item) => {
            if (!getValue(item, nameProperty, errorHandler)) {
                return false;
            }
            if (!searchText) {
                return true;
            }

            return getValue(item, nameProperty, errorHandler).toLowerCase().startsWith(searchText.toLowerCase());
        });
    }
}
