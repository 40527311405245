import { InjectionToken } from '@angular/core';

import { INavSidebarService } from './nav-sidebar.library';

/**
 * Token used for providing the INavSidebarService
 * @example
 * // in the app providers section
 * providers: [
 *     { provide: NavSidebarServiceToken, useExisting: NavSidebarService },
 * ],
 */
export const NavSidebarServiceToken = new InjectionToken<INavSidebarService>('NavSidebarService');
