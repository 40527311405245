import { Observable } from 'rxjs';

export interface IEntityListExportConfig {
    exportName: string;
    getDataForExport?: () => Observable<any[]>;
    onExportClick?: () => void;
}

export class EntityListExportConfig implements IEntityListExportConfig {
    public exportName: string;
    public getDataForExport: () => Observable<any[]>;
    public onExportClick: () => void;

    constructor(config: IEntityListExportConfig) {
        Object.assign(this, config);
     }
}
