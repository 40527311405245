import { FileItem } from 'ng2-file-upload';

export interface IAfterAddingPhotoEvent {
    fileItem: FileItem;
}

export class AfterAddingPhotoEvent implements IAfterAddingPhotoEvent {
    constructor(
         public fileItem: FileItem,
    ) {
        this.fileItem = fileItem;
    }
}

export interface IMtBlobFile extends Blob  {
    lastModifiedDate: Date,
    name: string,
}

export class MtBlob extends Blob {
    constructor() {
        super();
    }
    /**
     * function where we take the blob object created from
     * convertToBlob and add the image properties to it so
     * it can be consumed by the API
     * @param theBlob
     */
    static blobToFile(theBlob): IMtBlobFile {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = '';
        return theBlob;
    }

    /**
     * function that coverts the base64 image string to a
     * blob object.  We do this so we can format it into
     * a file object to send to the API.
     * @param base64Str
     */
    static convertToBlob(base64Str: string): Blob {

        let binary = atob(base64Str.split(',')[1]);
        let array = [];
        for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
    }
}
