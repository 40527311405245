import { Component, Input } from '@angular/core';

import { CustomFormControlComponentBase } from '../custom-form-component.base';
import { DynamicField } from '../../libraries/dynamic-field.library';

@Component({
    selector: 'dynamic-form-label',
    templateUrl: './form-label.component.html',
})
export class FormLabelComponent {
    @Input() parentControl: CustomFormControlComponentBase;

    get config(): DynamicField {
        return this.parentControl.config;
    }

    get showRequired(): boolean {
        return this.parentControl.showRequired();
    }

    get showOptional(): boolean {
        return this.parentControl.showOptional();
    }

    constructor() {}
}
