import { IStateMetaItem } from '@model/interfaces/custom/state-meta-item';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';
import { IReferralType } from '@model/interfaces/referral-type';
import { IAddress } from '@model/interfaces/address';
import { count } from 'rxjs/operators';

export class SharedFunctionsService {

    CountriesWithStates = [{CountryCode: 'US'}, {CountryCode: 'CA'}, {CountryCode: 'AU'}];

    constructor() { }

    getCountryId(countryCode: string, countries: ICountryMetaItem[]): number | null {
        let Id = null;

        if (countryCode !== null) {
            let country = countries.find((c) => c.CountryCode.trim() === countryCode.trim());
            if (country) {
                Id = country.Id;
            }

        }
        return Id;
    }

    getCountryNameByCode(countryCode: string, countries: ICountryMetaItem[]): string {
        let name = '';

        if (countryCode !== null) {
            let country = countries.find((c) => c.CountryCode.trim() === countryCode.trim());
            if (country) {
                name = country.Name;
            }

        }
        return name;
    }

    getCountryIdByCode(countryCode: string, countries: ICountryMetaItem[]): number | null {
        let Id = null;

        if (countryCode !== null) {
            let country = countries.find((c) => c.CountryCode.trim() === countryCode.trim());
            if (country) {
                Id = country.Id;
            }

        }
        return Id;
    }

    getCountryCodeById(Id: number, countries: ICountryMetaItem[]): string {
        let countryCode = '';
        let country = countries.find((c) => c.Id === Id);

        if (country) {
            countryCode = country.CountryCode;
        }
        return countryCode;
    }

    //
    // Single point to check whether or not the country code is one that we have a defined list of states for
    //
    countryIdHasStatesList(countryId: number, countries: ICountryMetaItem[], states: IStateMetaItem[]): boolean {

        const countryCode = this.getCountryCodeById(countryId, countries);
        return this.countryHasStatesList(countryCode, states);
    }

    //
    // Single point to check whether or not the country code is one that we have a defined list of states for
    //
    countryHasStatesList(countryCode: string, states: IStateMetaItem[]): boolean {
        let hasStatesList = false;
        if (countryCode) {
            if (states) {
                const index = (states.findIndex((s) => s.CountryCode.trim() === countryCode.trim()));
                hasStatesList = (index >= 0);
            } else {
                hasStatesList = (this.CountriesWithStates.some((c) => c.CountryCode === countryCode.toUpperCase()));
            }
        }
        return hasStatesList;
    }

    getStateId(countryCode: string, countries: ICountryMetaItem[], stateCode: string, states: IStateMetaItem[]): number | null {
        let Id = null;

        if (countryCode !== null) {
            let country = countries.find((c) => c.CountryCode.trim() === countryCode.trim());
            if (country) {
                if (stateCode !== null) {
                    let state = states.find((c) => c.CountryCode.trim() === country.CountryCode.trim() && c.StateCode.trim() === stateCode.trim());
                    if (state) {
                        Id = state.Id;
                    }
                }
            }
        }
        return Id;
    }

    getStatesList(countryCode: string, states: IStateMetaItem[]): IStateMetaItem[] {

        let countryStates = [] as IStateMetaItem[];
        if (countryCode !== null) {
            countryStates = states.filter((s) => s.CountryCode.trim() === countryCode.trim());
        }
        return countryStates;
    }

    getStateCodeById(Id: number, states: IStateMetaItem[]): string | null {
        let stateCode = null;
        const state = states.find((s) => s.Id === Id);

        if (state) {
            stateCode = state.StateCode;
        }

        return stateCode;
    }

    filterStatesForCounty(countryCode: string, states: IStateMetaItem[]): IStateMetaItem[] {

        let statesForCountry: IStateMetaItem[] = [];

        if (this.countryHasStatesList(countryCode, states)) {
            let emptyState: IStateMetaItem = { Id: null, CountryCode: '', StateCode: '', Name: '' };
            statesForCountry.push(emptyState);
            states.filter((s) => s.CountryCode.trim() === countryCode).forEach((s) => statesForCountry.push(s));
        }

        return statesForCountry;
    }

    //
    // Indicate that the Email and Confirm Email fields are the same as one another
    //
    emailsAreEqual(email: string, confirmEmail: string): boolean {
        return this.stringsAreEqual(email, confirmEmail);
    }

    //
    // Indicate that the Phone Number and Confirm Phone Number fields are the same as one another
    //
    phonesAreEqual(phoneNumber: string, confirmPhoneNumber: string): boolean {
        return this.stringsAreEqual(phoneNumber, confirmPhoneNumber);
    }

    //
    // Indicate that the primary value and confirmation value are the same as one another
    //
    stringsAreEqual(primaryValue: string, confirmationValue: string): boolean {
        let isValid = null;

        if (primaryValue && confirmationValue) {
            isValid = (primaryValue.toString().toLowerCase().trim() === confirmationValue.toString().toLowerCase().trim());
        } else if ((primaryValue === null || primaryValue === undefined || primaryValue === '') &&
            (confirmationValue === null || confirmationValue === undefined || confirmationValue === '')
        ) {
            isValid = true;
        }

        return isValid;
    }

    //
    // Determine if the selected referral requires additional comments
    //
    otherReferralType(referralTypeId: number, referralTypes: IReferralType[]): boolean {
        let hasComments = false;
        let referralType = referralTypes.find((r) => r.Id === referralTypeId);
        if (referralType) {
            hasComments = referralType.HasComments;
        }
        return hasComments;
    }

    //
    // Create a single line of text from the givem address, including either StateCode or Province as needed
    //
    formatAddressforView(address: IAddress, states: IStateMetaItem[]): string {
        let result = '';

        if (address) {
            result += address.Address1 ?? '';

            if (address.Address2 && address.Address2.length > 0) {
                result += ', ' + address.Address2;
            }
            result += ',' + address.City ?? '';

            if (this.countryHasStatesList(address.CountryCode, states)) {
                result += ', ' + address.StateCode ?? '';
            } else {
                result += ', ' + address.Province ?? '';
            }

            result += ' ' + address.Zip ?? '';
            result += ',' + address.CountryCode;
        }
        return result;
    }

}
