import { CookieService, CookieOptions } from 'ngx-cookie';

import { CookieStrategyService } from './cookie-strategy.service';

export class PortalCookieStrategy extends CookieStrategyService {
    /**
     *
     */
    constructor(private storage: CookieService) {
        super();
    }

    // Converting this to a promise so we can parse it consistently regardless of the strategy.
    get(key: string): Promise<string> {
        return Promise.resolve(this.storage.get(key));
    }
    set(key: string, value: any, options?: any): void {
        return this.storage.put(key, value, options as CookieOptions);
    }
    remove(key: string): void {
        return this.storage.remove(key);
    }
}
