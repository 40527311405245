import { Pipe, PipeTransform } from '@angular/core';

import { getPrimary } from '@mt-ng2/common-functions';

@Pipe({
    name: 'primary',
})
export class IsPrimaryPipe implements PipeTransform {
    constructor() {}

    transform<T>(value: T[], args?: any): T {
        return getPrimary(value);
    }
}
