import { AbstractControl, FormGroup } from '@angular/forms';

import { DynamicField } from '../libraries/dynamic-field.library';

export enum InputTypes {
    Textbox = 1,
    Textarea = 2,
    Datepicker = 3,
    Phone = 4,
    Timepicker = 5,
    DateTimepicker = 6,
    Colorpicker = 7,
    DateInput = 8,
    DateTimeInput = 9,
    Tags = 10,
    WYSIWYG = 11,
}

export enum SelectInputTypes {
    Dropdown = 1,
    MultiselectDropdown = 2,
    RadioButtonList = 3,
    OldStyleRadioButtonList = 4,
    OldStyleMultiselectDropdown = 5,
    TypeAhead = 6,
}

export function getControlFromFormGroup(group: FormGroup, config: DynamicField): AbstractControl {
    let control = group.get([config.formGroup, config.name]);
    if (!control) {
        control = group.get(config.name);
    }
    return control;
}

export function getGroupFromConfig(parentGroup: FormGroup, config: DynamicField): FormGroup {
    let group: FormGroup = config.formGroup ? <FormGroup>parentGroup.get([config.formGroup]) : parentGroup;
    return group;
}
