import { InjectionToken } from '@angular/core';

/**
 * Interface representing the customizable properties
 * of the document control module.
 * @example
 * const documentControlModuleConfig: IDocumentControlModuleConfig = {
 *     maxFileSize: 5 * 1024 * 1024,
 * };
 * @property allowedMimeType - string array of allowed mime types
 * @property maxFileSize - maxuimum number of bytes allowed
 */
export interface IDocumentControlModuleConfig {
    allowedMimeType?: string[];
    maxFileSize?: number;
}

/**
 * Token used for providing the IDocumentControlModuleConfig
 * @example
 * // in the app or shared modules providers section
 * providers: [
 *     { provide: DocumentControlModuleConfigToken, useValue: documentControlModuleConfig },
 * ],
 */
export const DocumentControlModuleConfigToken = new InjectionToken<IDocumentControlModuleConfig>('document_control_module_config');
