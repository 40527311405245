import { Component, Input, Output, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IAddress } from '@model/interfaces/address';
import { IAddress as IBaseAddress } from '@model/interfaces/base';
import { SharedFunctionsService } from '@common/services/shared-functions-service';
import { CommonService } from '@common/services/common.service';
import { IStateMetaItem } from '@model/interfaces/custom/state-meta-item';

@Component({
    selector: `address-container`,
    templateUrl: './address-container.component.html',
})
export class AddressContainerComponent implements OnInit {
    @Input() address: IAddress;
    @Input() addressType: string;
    @Input() canEdit: boolean;
    @Input() showButtons: boolean;
    @Input() showDelete: boolean;
    @Output() onSave: EventEmitter<IAddress> = new EventEmitter<IAddress>();
    @Output() onDelete: EventEmitter<IAddress> = new EventEmitter<IAddress>();
    @Output() onDeleteBasedAddress: EventEmitter<IBaseAddress> = new EventEmitter<IBaseAddress>();

    addressFormGroup: FormGroup;
    isEditing = false;

    sharedFunctionsService: SharedFunctionsService;
    states: IStateMetaItem[];
formattedAddress: string;
    constructor(
        private fb: FormBuilder,
        private commonService: CommonService,
    ) {

    }

    ngOnInit(): void {
        this.sharedFunctionsService = new SharedFunctionsService();
        this.commonService.getAllStates().subscribe(() => {
            this.states = this.commonService.getAllStateMetaItems();
            this.addressFormGroup = this.fb.group({});
            this.formattedAddress = this.getformattedAddress();
        });
    }

    cancel(): void {
        this.isEditing = false;
    }

    edit(): void {
        this.isEditing = true;
    }

    save(address: IAddress): void {
        this.formattedAddress = this.getformattedAddress();
        this.onSave.emit(address);
        this.cancel();
    }

    delete(address: IAddress): void {
        this.onDeleteBasedAddress.emit(this.getBaseAddress(address));
        this.onDelete.emit(address);
    }

    getBaseAddress(address: IAddress): IBaseAddress {
        let newAddress = {
             Id: address.Id,
             Address1: address.Address1,
             Address2: address.Address2,
             City: address.City,
             StateCode: address.StateCode,
             Province: address.Province,
             Zip: address.Zip,
             CountryCode: address.CountryCode,
        } as IBaseAddress;

        return newAddress;
    }

    createAddress(): void {
        const emptyAddress = {
            Id: null,
            Address1: '',
            Address2: '',
            City: '',
            StateCode: null,
            Province: '',
            Zip: '',
            CountryCode: '',
        };

        this.address = emptyAddress;
        this.isEditing = true;
    }

    noAddress(): boolean {
        return (this.address === undefined || this.address === null);
    }
    //
    // Return the address formatted into a single line of text
    //
    getformattedAddress(): string {
        return this.sharedFunctionsService.formatAddressforView(this.address, this.states);
    }
}
