import { Component, Injector } from '@angular/core';

import { CustomFormControlComponentBase } from '../custom-form-component.base';

@Component({
    styles: [
        `
            .inside-box-maxlength {
                position: relative !important;
                bottom: -13px;
                top: -13px;
                margin-bottom: -13px;
                right: 3px;
                font-size: 70%;
            }
        `,
    ],
    templateUrl: './form-textbox.component.html',
})
export class FormTextboxComponent extends CustomFormControlComponentBase {
    constructor(injector: Injector) {
        super(injector);
    }
}
