import { IEntityListModuleConfig } from '@mt-ng2/entity-list-module-config';
import { EntityListSelectColumn, SelectTypes } from './entity-list-select-column.library';
import { EntityListConfig, IEntity } from './entity-list.library';

export type GetViewableEntitiesFunction = () => any[];

/**
 * helper class used by the entity list component to handle the logic pertaining to selecting rows/entities.
 * It is responsible for
 * - answering questions about the select columns configuration
 * - maintaining the list of selected entities, including logic to update that list
 * - answering questions about what is selected such as "are all items on page currently selected"
 */
export class EntityListSelectHelper {
    // selectConfig is the configuration for the select column
    private _selectConfig: EntityListSelectColumn;
    get selectConfig(): EntityListSelectColumn {
        if (this._selectConfig === undefined) {
            this._selectConfig = this._entityListConfig.select ? new EntityListSelectColumn(this._entityListConfig.select) : null;
        }
        return this._selectConfig;
    }

    // selectedEntities is the array of entities that have been selected
    private _selectedEntities: any[];
    set selectedEntities(value: any[]) {
        this._selectedEntities = value;
    }
    get selectedEntities(): any[] {
        if (!this._selectedEntities) {
            this._selectedEntities = [];
        }
        return this._selectedEntities;
    }

    // uniqueId is the property on the entity to use as the unique identifier
    get uniqueId(): string {
        return this.selectConfig?.uniqueId ?? 'Id';
    }

    private _lastSelectedEntity: any;

    constructor(
        private _entityListConfig: EntityListConfig,
        entityListModuleConfig: IEntityListModuleConfig,
        initialSelectedEntities: IEntity[],
        public getEntities: GetViewableEntitiesFunction,
    ) {
        if (initialSelectedEntities?.length) {
            this.selectedEntities = initialSelectedEntities;
        }
        if (this.selectConfig && !this.selectConfig.selectedColor && entityListModuleConfig?.selectedColor) {
            this.selectConfig.selectedColor = entityListModuleConfig?.selectedColor;
        }
    }

    /**
     * is the select type Multi
     */
    isMultiSelect(): boolean {
        return this.selectConfig?.type === SelectTypes.Multi;
    }

    /**
     * check if the entity passed in is currently in the selected entities array
     * @param entity
     */
    isItemSelected(entity: IEntity): boolean {
        return this.selectedEntities.find((item) => item[this.uniqueId] === entity[this.uniqueId]) ? true : false;
    }

    /**
     * check if the array of entities passed in are all currently in the selected entities array
     * @param entities
     */
    isAllItemsSelected(entities: any[]): boolean {
        const selectedIds = this.selectedEntities.map((item) => item[this.uniqueId]);
        return entities.every((item) => !this.isSelectable(item) || selectedIds.some((id) => id === item[this.uniqueId]));
    }

    /**
     * should the select column be shown on screen
     * @param printMode
     */
    showSelect(printMode: boolean): boolean {
        return this.selectConfig?.showColumn(printMode) ?? false;
    }

    /**
     * should the entity passed in be selectable
     * @param entity
     */
    isSelectable(entity: any): boolean {
        // if the selectConfig has been provided a selectable function callback, then uses it else just return true
        return this.selectConfig?.selectable?.(entity) ?? true;
    }

    /**
     * handle a single entity being clicked
     * @param entity
     */
    handleSelection(entity, clickEvent: MouseEvent): void {
        const shiftKey = clickEvent.shiftKey;
        if (this.isMultiSelect && shiftKey) {
            this.selectionWithShiftKey(entity);
        } else {
            this.selectionWithoutShiftKey(entity);
        }
    }

    private selectionWithShiftKey(entity): void {
        const alreadySelected = this.isItemSelected(entity);
        if (alreadySelected) {
            this.removeItemFromSelected(entity);
        } else {
            if (this._lastSelectedEntity && this.getItemIndexInCurrentEntities(this._lastSelectedEntity) > -1) {
                const selectedRange = this.getSelectedRange(entity);
                this.selectedEntities = this.selectedEntities.concat(
                    selectedRange.filter((item) => !this.isItemSelected(item) && this.isSelectable(item)),
                );
            } else {
                this.selectedEntities.push(entity);
                this._lastSelectedEntity = entity;
            }
        }
    }

    private getSelectedRange(entity: any): any[] {
        const currentEntities = this.getEntities();
        const lastIndex = this.getItemIndexInCurrentEntities(this._lastSelectedEntity);
        const currentIndex = this.getItemIndexInCurrentEntities(entity);
        const startIndex = lastIndex > currentIndex ? currentIndex : lastIndex + 1;
        const nonInclusiveEndingIndex = lastIndex > currentIndex ? lastIndex : currentIndex + 1;
        const selectedRange = currentEntities.slice(startIndex, nonInclusiveEndingIndex);
        return selectedRange;
    }

    private getItemIndexInCurrentEntities(entityToFind): any {
        const currentEntities = this.getEntities();
        return currentEntities.findIndex((item) => item[this.uniqueId] === entityToFind[this.uniqueId]);
    }

    private selectionWithoutShiftKey(entity): void {
        const alreadySelected = this.isItemSelected(entity);
        const type = this.selectConfig?.type;
        if (type === SelectTypes.Multi) {
            if (alreadySelected) {
                this.removeItemFromSelected(entity);
            } else {
                this.selectedEntities.push(entity);
                this._lastSelectedEntity = entity;
            }
        }
        if (type === SelectTypes.Single) {
            if (alreadySelected) {
                return;
            } else {
                this.selectedEntities = [entity];
            }
        }
    }

    /**
     * handle a select all being clicked
     * @param entities
     */
    handleSelectAll(): void {
        const entities = this.getEntities();
        const selectedIds = this.selectedEntities.map((item) => item[this.uniqueId]);
        if (this.isAllItemsSelected(entities)) {
            entities.forEach((item) => {
                if (selectedIds.some((id) => id === item[this.uniqueId])) {
                    this.removeItemFromSelected(item);
                }
            });
        } else {
            entities.forEach((item) => {
                if (this.isSelectable(item) && !selectedIds.some((id) => id === item[this.uniqueId])) {
                    this.selectedEntities.push(item);
                }
            });
        }
    }

    /**
     * remove a single entity from the selected entities array
     * @param entity
     */
    removeItemFromSelected(entity: IEntity): void {
        const index = this.selectedEntities.findIndex((item) => item[this.uniqueId] === entity[this.uniqueId]);
        if (index > -1) {
            this.selectedEntities.splice(index, 1);
        }
    }
}
