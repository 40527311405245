import { FormControl, FormGroup, FormArray } from '@angular/forms';

import { plural, singular, isPlural, isSingular } from 'pluralize';
import exportFromJSON from 'export-from-json';

export const common = {
    exportAsCsv: exportAsCsv,
    getFunctionNameSafe: getFunctionNameSafe,
    getMetaItemValue: getMetaItemValue,
    getPrimary: getPrimary,
    isNull: isNull,
    isNullOrWhitespace: isNullOrWhitespace,
    levenshtein: levenshtein,
    markAllFormFieldsAsDisabled: markAllFormFieldsAsDisabled,
    markAllFormFieldsAsTouched: markAllFormFieldsAsTouched,
    pluralize: pluralize,
    singularize: singularize,
    sortByProperty: sortByProperty,
};

/**
 * Changes a data array to a csv format and flushes it to the browser.
 * @param data the data array
 * @param fileName the filename for the csv file
 * @param includeBOM include BOM meta data in the file, defaults to false
 */
export function exportAsCsv(data: any[], fileName: string, includeBOM = false): void {
    return exportFromJSON({ data, fileName, exportType: 'csv', withBOM: includeBOM });
}

/**
 * Takes a string determines if it is null or if it is empty
 * @param input string to check if null
 */
export function isNullOrWhitespace(input: string): boolean {
    if (isNull(input)) {
        return true;
    }
    if (typeof input !== 'string') {
        return true;
    }

    return input.replace(/\s/g, '').length < 1;
}

/**
 * Takes an object determines if it is null
 * @param obj object to check if null
 */
export function isNull(obj: any): boolean {
    return typeof obj === 'undefined' || obj === null;
}

/**
 * Marks all the fields of a form as touched.  Typical use is
 * to mark a form when there is a validation issue.
 * @param formGroup formgroup to mark
 */
export function markAllFormFieldsAsTouched(formGroup: FormGroup | FormArray): void {
    Object.keys(formGroup.controls).forEach((field) => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched();
        } else if (control instanceof FormGroup || control instanceof FormArray) {
            markAllFormFieldsAsTouched(control);
        }
    });
}

/**
 * Marks all the fields of a form as disabled.  Typical use is
 * to lock a form.
 * @param formGroup formgroup to disable
 */
export function markAllFormFieldsAsDisabled(formGroup: FormGroup | FormArray): void {
    Object.keys(formGroup.controls).forEach((field) => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
            control.disable();
        } else if (control instanceof FormGroup || control instanceof FormArray) {
            markAllFormFieldsAsDisabled(control);
        }
    });
}

/**
 * Function that will return the object in an array that
 * is marked as IsPrimary.
 * @param list list to iterate over
 */
export function getPrimary<T>(list: T[]): T {
    const primary = list.find((item: any) => item.IsPrimary);
    return primary;
}

/**
 * Function that return the array passed in sorted by the defined property
 * @param array array to be sorted
 * @param property property of the array to sort on
 */
export function sortByProperty<T>(array: T[], property: string): void {
    array.sort((a, b) => (a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0));
}

/**
 * Function that returns plural form of a word.
 * @param target
 */
export function pluralize(target: string): string {
    return isSingular(target) ? plural(target) : target;
}

/**
 * Function that returns singular form of a word.
 * @param target
 */
export function singularize(target: string): string {
    return isPlural(target) ? singular(target) : target;
}

/**
 * Function that checks for existence of a function on a class
 * throws an error if function is not found and returns null
 * returns the functionName if found
 * @param classObject
 * @param functionName
 */
export function getFunctionNameSafe(classObject: any, functionName: string): string {
    let functionExists = classObject.prototype.hasOwnProperty(functionName) && typeof classObject.prototype[functionName] === 'function';
    if (!functionExists) {
        throw `failed to find ${functionName} on the following object: \n
        ${classObject.toString()}`;
    }
    return functionExists ? functionName : null;
}

/**
 * Function that gets the Name value from a MetaItem array by id.
 * @param source
 * @param id
 */
export function getMetaItemValue(source: any[], id: number | number[]): string {
    if (!source) {
        return undefined;
    }
    if (id instanceof Array) {
        const items = source.filter((s) => (<number[]>id).some((i) => s.Id === i)).map((s) => s.Name);
        return (items && items.join(', ')) || undefined;
    }
    const item = source.find((s) => s.Id === id);
    return (item && item.Name) || undefined;
}

/**
 * Calculate the levenshtein distance of two strings.
 * See https://en.wikipedia.org/wiki/Levenshtein_distance.
 * Based off https://gist.github.com/andrei-m/982927 (for using the faster dynamic programming
 * version).
 * Copied from Angular-cli project
 * https://github.com/angular/angular-cli/blob/0070ea46e6eaa25ad49d17c24fe7bed546dc1ec0/packages/angular_devkit/core/src/utils/strings.ts
 *
 * @param a String a.
 * @param b String b.
 * @returns A number that represents the distance between the two strings. The greater the number
 *   the more distant the strings are from each others.
 */
export function levenshtein(a: string, b: string): number {
    if (a.length === 0) {
        return b.length;
    }
    if (b.length === 0) {
        return a.length;
    }

    const matrix = [];

    // increment along the first column of each row
    for (let i = 0; i <= b.length; i++) {
        matrix[i] = [i];
    }

    // increment each column in the first row
    for (let j = 0; j <= a.length; j++) {
        matrix[0][j] = j;
    }

    // Fill in the rest of the matrix
    for (let i = 1; i <= b.length; i++) {
        for (let j = 1; j <= a.length; j++) {
            if (b.charAt(i - 1) === a.charAt(j - 1)) {
                matrix[i][j] = matrix[i - 1][j - 1];
            } else {
                matrix[i][j] = Math.min(
                    matrix[i - 1][j - 1] + 1, // substitution
                    matrix[i][j - 1] + 1, // insertion
                    matrix[i - 1][j] + 1, // deletion
                );
            }
        }
    }

    return matrix[b.length][a.length];
}
