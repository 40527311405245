import { Injectable } from '@angular/core';

import { StorageService } from '@mt-ng2/common-classes';
import { from, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RememberUserNameStorageService {
    private USER_NAME_KEY = 'LOGIN_USER_NAME_KEY';

    constructor(private storageService: StorageService) {}
    saveRememberUserName(userName: string): void {
        const cookieExpiration = new Date();
        // save refresh token in cookie for 1 month on each refresh
        // auth token will expire before cookie does but refresh will not
        cookieExpiration.setDate(cookieExpiration.getDate() + 30);
        this.storageService.set(this.USER_NAME_KEY, userName, { expires: cookieExpiration });
    }

    getUserNameFromCookie(): Observable<string> {
        return from(this.storageService.get(this.USER_NAME_KEY));
    }

    removeUserNameCookie(): void {
        this.storageService.remove(this.USER_NAME_KEY);
    }
}
