import { Directive, OnInit, Input, ComponentFactoryResolver, ViewContainerRef, Type } from '@angular/core';

import { IEntityListDynamicCellComponentGeneric, IEntityListComponentMembers } from './libraries/entity-list-column.library';

@Directive({
    selector: '[dynamicCell]',
})
export class EntityListDynamicCellDirective<T> implements OnInit {
    @Input() componentRef: Type<IEntityListDynamicCellComponentGeneric<T>>;
    @Input() item: T;
    @Input() entityListComponentMembers: IEntityListComponentMembers;

    component;

    constructor(private resolver: ComponentFactoryResolver, private container: ViewContainerRef) {}

    ngOnInit(): void {
        const factory = this.resolver.resolveComponentFactory<IEntityListDynamicCellComponentGeneric<T>>(this.componentRef);
        this.component = this.container.createComponent(factory);
        (this.component.instance as IEntityListDynamicCellComponentGeneric<T>).entity = this.item;
        (this.component.instance as IEntityListDynamicCellComponentGeneric<T>).entityListComponentMembers = this.entityListComponentMembers;
    }
}
