import { HttpClient, HttpParams, HttpResponse, HttpHeaders } from '@angular/common/http';

import { Subject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppError, NotFoundError } from '@mt-ng2/errors-module';
import { SearchParams } from '@mt-ng2/common-classes';

export interface IEntity {
    Id: number;
}

export interface ICreateOptions {
    nullOutFKs: boolean;
}

const defaultCreateOptions: ICreateOptions = {
    nullOutFKs: true,
};

// tslint:disable-next-line:max-line-length
export const updatePartialErrorMessage = '@mt-ng2/base-service : updatePartial failed while trying to JSON.stringify() the passed in object with id';

export abstract class BaseService<T extends IEntity> {
    protected emitChangeSource = new Subject<T>();
    public changeEmitted$: Observable<T> = this.emitChangeSource.asObservable();

    constructor(private baseurl: string, protected http: HttpClient) {}

    public emitChange(entity: T): void {
        this.emitChangeSource.next(entity);
    }

    get(searchparameters: SearchParams): Observable<HttpResponse<T[]>> {
        let params = this.getHttpParams(searchparameters);
        return this.http
            .get<T[]>(this.baseurl + '/_search', {
                observe: 'response',
                params: params,
            })
            .pipe(catchError(this.handleError));
    }

    getAll(): Observable<T[]> {
        return this.http.get<T[]>(this.baseurl).pipe(catchError(this.handleError));
    }

    getById(id: number): Observable<T> {
        return this.http.get<T>(this.baseurl + '/' + id).pipe(catchError(this.handleError));
    }

    create(object: T, options = defaultCreateOptions): Observable<number> {
        let clone: any = JSON.parse(JSON.stringify(object));

        if (options.nullOutFKs) {
            clone = this.nullOutFKs(clone);
        }

        return this.http.post<number>(this.baseurl, clone).pipe(catchError(this.handleError));
    }

    createWithFks(object: T): Observable<number> {
        return this.create(object, { nullOutFKs: false });
    }

    delete(id: number): Observable<any> {
        return this.http.delete(this.baseurl + '/' + id).pipe(catchError(this.handleError));
    }

    /**
     * Calls the CRUD Delete and checks for concurrency, needs Id and Version
     * @param object
     */
    deleteVersion(object: T): Observable<any> {
        const clone: any = JSON.parse(JSON.stringify(object));
        return this.http.request('delete', this.baseurl + '/' + clone.Id + '/version', { body: clone }).pipe(catchError(this.handleError));
    }

    update(object: T): Observable<any> {
        let clone: any = JSON.parse(JSON.stringify(object));
        clone = this.nullOutFKs(clone);
        return this.http.put(this.baseurl + '/' + clone.Id, clone).pipe(catchError(this.handleError));
    }

    updateWithFks(object: T): Observable<any> {
        return this.http.put(this.baseurl + '/' + object.Id, object).pipe(catchError(this.handleError));
    }

    /**
     * Calls the CRUD Update and checks for concurrency
     * @param object
     */
    updateVersion(object: T): Observable<number[]> {
        let clone: any = JSON.parse(JSON.stringify(object));
        clone = this.nullOutFKs(clone);
        return this.http.put<number[]>(this.baseurl + '/' + clone.Id + '/version', clone).pipe(catchError((err) => this.handleError(err, clone)));
    }

    /**
     * Calls the CRUD Update and checks for concurrency
     * @param object
     */
    updateVersionWithFks(object: T): Observable<number[]> {
        return this.http.put<number[]>(this.baseurl + '/' + object.Id + '/version', object).pipe(catchError((err) => this.handleError(err, object)));
    }

    /**
     * Updates the entity when you don't have all the properties
     * to pass in
     * @param object
     * @param id
     */
    updatePartial(object: object, id: number): Observable<any> {
        return this._updatePartial(object, id, false);
    }

    /**
     * Updates the Versionable entity when you don't have all the properties
     * to pass in, the object string must include the Version
     * @param object
     * @param id
     */
    updatePartialVersionable(object: any, id: number): Observable<any> {
        return this._updatePartial(object, id, true);
    }

    private _updatePartial(object: object, id: number, versionable: boolean): Observable<any> {
        let objectAsString: string;
        try {
            // Adding the single quotes before and after because of .NET's String Parser
            // We are reading this as a string because we can't serialize it to an object.
            // When this gets sent to the backend .NET can't convert it to a string without
            // the single quotes.
            objectAsString = `'${JSON.stringify(object)}'`;
        } catch (error) {
            throw new Error(updatePartialErrorMessage + ' ' + id);
        }

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        const endPointUrl = versionable ? `${this.baseurl}/${id}/version` : `${this.baseurl}/${id}`;

        return this.http.patch(endPointUrl, objectAsString, { headers }).pipe(catchError(this.handleError));
    }

    /**
     * Calls the CRUD Service doing a merge on the list properties
     * @param object
     * @param id
     */
    updateList(object: T[]): Observable<any> {
        return this.http.put(this.baseurl, object).pipe(catchError(this.handleError));
    }

    handleError(error: Response, formObject?: any): Observable<any> {
        if (error.status === 400) {
            return throwError(error);
        }

        if (error.status === 404) {
            return throwError(new NotFoundError());
        }

        return throwError(new AppError(error, formObject));
    }

    /**
     * Convenience method for formatting the SearchParams object from the @mt-ng2/common-classes
     * package into the expected backend object
     * @param searchparameters
     */
    protected getHttpParams(searchparameters: SearchParams): HttpParams {
        let params = new HttpParams();
        if (searchparameters.query) {
            params = params.append('query', searchparameters.query);
        }
        if (searchparameters.skip) {
            params = params.append('skip', searchparameters.skip.toString());
        }
        if (searchparameters.take) {
            params = params.append('take', searchparameters.take.toString());
        }
        if (searchparameters.order) {
            params = params.append('order', searchparameters.order.toString());
        }
        if (searchparameters.orderDirection) {
            params = params.append('orderDirection', searchparameters.orderDirection.toString());
        }
        if (searchparameters.extraParams && searchparameters.extraParams.length > 0) {
            let extraparams = new HttpParams();
            searchparameters.extraParams.forEach((param) => {
                if (param.valueArray) {
                    if (param.valueArray.length > 0) {
                        extraparams = extraparams.append(param.name, param.valueArray.toString());
                    }
                } else {
                    if (param.value.length > 0) {
                        extraparams = extraparams.set(param.name, param.value);
                    }
                }
            });
            if (extraparams.keys().length > 0) {
                params = params.append('extraparams', extraparams.toString());
            }
        }
        return params;
    }

    /**
     * Clear out the FK objects so that EF doesn't try to update
     * them unintentionally
     * @param object
     */
    private nullOutFKs(object: T): T {
        for (let prop in object) {
            if (typeof object[prop] === 'object') {
                object[prop] = null;
            }
        }
        return object;
    }
}
