import { Component, Input, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { IFormElementEvents } from '../form-elements/form-elements';
import { DynamicField } from '../libraries/dynamic-field.library';
import { ClaimsService } from '@mt-ng2/auth-module';

@Component({
    selector: 'dynamic-field',
    template: ` <div
        *ngIf="showControl"
        appDynamicField
        [config]="field"
        [group]="form"
        (blur)="blur.emit()"
        (focus)="focus.emit()"
        (valueChanges)="valueChanges.emit($event)"
    ></div>`,
})
export class DynamicFieldComponent implements OnInit, OnDestroy, IFormElementEvents {
    @Input() field: DynamicField;
    @Input() form: FormGroup;
    @Input() overrideForm = false;
    @Input() removeControlOnDestroy = true;

    @Output() blur: EventEmitter<any> = new EventEmitter<any>();
    @Output() focus: EventEmitter<any> = new EventEmitter<any>();
    @Output() valueChanges: EventEmitter<any> = new EventEmitter<any>();
    @Output() controlCreated: EventEmitter<AbstractControl> = new EventEmitter<AbstractControl>();

    showControl = true;

    constructor(private fb: FormBuilder, private claimsService: ClaimsService) {}

    ngOnInit(): void {
        if (!this.field.shouldCreateControl(this.claimsService)) {
            this.showControl = false;
            return;
        }
        if (typeof this.form === 'undefined') {
            this.overrideForm = true;
            this.form = this.fb.group({});
        }
        if (this.overrideForm) {
            this.field.formGroup = null;
        }
        let currentGroup = this.field.formGroup ? <FormGroup>this.form.get(this.field.formGroup) : this.form;
        if (!currentGroup) {
            this.formNotFoundError();
        }
        if (currentGroup.get(this.field.name)) {
            currentGroup.setControl(this.field.name, this.field.createControl(this.fb, this.claimsService));
        } else {
            currentGroup.addControl(this.field.name, this.field.createControl(this.fb, this.claimsService));
        }
        const control = currentGroup.get(this.field.name);
        this.controlCreated.emit(control);
    }

    ngOnDestroy(): void {
        if (this.removeControlOnDestroy) {
            let currentGroup = this.field.formGroup ? <FormGroup>this.form.get(this.field.formGroup) : this.form;
            if (currentGroup) {
                currentGroup.removeControl(this.field.name);
            }
        }
    }

    private formNotFoundError(): void {
        this.showControl = false;
        let errorMessage = `
    Error in DynamicFieldComponent:  Form Not Found
    this may happen if the FormGroup on the field is not present on the passed in form.
    field[name] = '${this.field.name}'
    field[formGroup] = '${this.field.formGroup}'
    `;
        throw errorMessage;
    }
}
