import { Component, Input, OnInit, Injector, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { forkJoin, Observable } from 'rxjs';

import { IAddressDynamicFields, AddressDynamicFields } from './address-dynamic-fields.library';
import { DynamicFormModuleConfig } from '../libraries/dynamic-form-module.config';
import { IMetaItem } from '../libraries/interfaces/meta-item';

/**
 * Interface defining the address object
 * @property Id
 * @property Address1
 * @property Address2
 * @property City
 * @property StateCode
 * @property Zip
 * @property CountryCode
 * @property Province
 */
export interface IAddress {
    Id: number;
    Address1: string;
    Address2: string;
    City: string;
    StateCode?: string;
    Zip: string;
    CountryCode?: string;
    Province: string;
}

/**
 * Interface defining the array of address objects
 * @property AddressId
 * @property Address
 * @property IsPrimary
 */
export interface IAddressContainer {
    AddressId: number;
    Address: IAddress;
    IsPrimary: boolean;
}

/**
 * Interface defining the state object
 * @property StateCode
 * @property Name
 */
export interface IState {
    StateCode: string;
    Name: string;
}

/**
 * Interface defining the state service
 * @property getStates
 */
export interface IStatesService {
    getStates(): Observable<IState[]>;
}

/**
 * Interface defining the country object
 * @property CountryCode
 * @property Alpha3Code
 * @property Name
 */
export interface ICountry {
    CountryCode: string;
    Alpha3Code: string;
    Name: string;
}

/**
 * Interface defining the country service
 * @property getCountries
 */
export interface ICountriesService {
    getCountries(): Observable<ICountry[]>;
}

@Component({
    selector: 'mt-address',
    template: `
        <div *ngIf="fields" [formGroup]="parentForm">
            <dynamic-field
                *ngIf="allowInternationalAddresses"
                [field]="fields.Country"
                [form]="parentForm"
                [overrideForm]="true"
                [removeControlOnDestroy]="false"
                (valueChanges)="onCountryChange($event)"
            ></dynamic-field>
            <dynamic-field [field]="fields.Address1" [form]="parentForm" [overrideForm]="true"></dynamic-field>
            <dynamic-field [field]="fields.Address2" [form]="parentForm" [overrideForm]="true"></dynamic-field>
            <dynamic-field *ngIf="!addressIsInternational" [field]="fields.City" [form]="parentForm" [overrideForm]="true"></dynamic-field>
            <dynamic-field
                *ngIf="addressIsInternational"
                [field]="fields.InternationalCity"
                [form]="parentForm"
                [overrideForm]="true"
            ></dynamic-field>
            <dynamic-field *ngIf="!addressIsInternational" [field]="fields.State" [form]="parentForm" [overrideForm]="true"></dynamic-field>
            <dynamic-field *ngIf="addressIsInternational" [field]="fields.Province" [form]="parentForm" [overrideForm]="true"></dynamic-field>
            <dynamic-field *ngIf="!addressIsInternational" [field]="fields.Zip" [form]="parentForm" [overrideForm]="true"></dynamic-field>
            <dynamic-field *ngIf="addressIsInternational" [field]="fields.InternationalZip" [form]="parentForm" [overrideForm]="true"></dynamic-field>
            <div *ngIf="showIsPrimary" [style.marginBottom.px]="15">
                <input type="checkbox" formControlName="IsPrimary" />
                <label>Primary Address</label>
            </div>
        </div>
    `,
})
export class MtAddressComponent implements OnInit {
    /**
     * address object that holds addresses and it's primary flag
     */
    @Input() addressContainer: any;
    /**
     * form object to attach the address component to
     */
    @Input() parentForm: FormGroup;
    /**
     * boolean to toggle the primary checkbox
     */
    @Input() showIsPrimary = true;
    /**
     * allow international addresses
     */
    @Input() allowInternationalAddresses = false;

    /**
     * local property for injecting the state service
     */
    private commonService: IStatesService | ICountriesService;

    states: IMetaItem[] = [];
    countries: IMetaItem[] = [];
    fields: IAddressDynamicFields;

    private _addressIsInternational = false;
    get addressIsInternational(): boolean {
        return this._addressIsInternational;
    }
    set addressIsInternational(value: boolean) {
        this._addressIsInternational = value;
        this.changeDetectorRef.detectChanges();
    }

    constructor(private injector: Injector, private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        const config = this.injector.get(DynamicFormModuleConfig);
        const commonServiceInjectorToken = config.commonService;
        this.commonService = this.injector.get(commonServiceInjectorToken);
        forkJoin([(<IStatesService>this.commonService).getStates(), (<ICountriesService>this.commonService).getCountries()]).subscribe((answers) => {
            const [states, countries] = answers;
            this.states = states.map((state) => ({ Id: state.StateCode, Name: state.Name }));
            this.countries = countries.map((country) => ({ Id: country.CountryCode, Name: country.Name }));
            this.loadForm();
        });
    }

    loadForm(): void {
        if (!this.parentForm) {
            this.parentForm = new FormGroup({});
        }

        if (this.addressContainer.Address.Id) {
            this.parentForm.addControl('Id', new FormControl(this.addressContainer.Address.Id));
        }
        if (this.addressContainer.AddressId) {
            this.parentForm.addControl('AddressId', new FormControl(this.addressContainer.AddressId));
        }

        if (this.showIsPrimary) {
            this.parentForm.addControl('IsPrimary', new FormControl(this.addressContainer.IsPrimary ? true : false));
        }

        this.parentForm.addControl('CountryCode', new FormControl(this.addressContainer.Address.CountryCode, Validators.required));
        this.fields = new AddressDynamicFields(this.addressContainer.Address, this.states, this.countries);

        this.addressIsInternational = this.addressContainer.Address.CountryCode !== 'US';
    }

    onCountryChange(countryCode: string): void {
        this.addressIsInternational = countryCode !== 'US';
    }
}
