import { BaseService } from '@mt-ng2/base-service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IDocument } from '@model/interfaces/base';
import { IDocumentType } from '@model/interfaces/document-type';
import { IDonorDocument } from '@model/interfaces/donor-document';
import { DocumentEntityTypes } from '@model/enums/document-entity-types';

const ENDPOINT_MAPPING = {
    [DocumentEntityTypes.Donors]: 'donor',
    [DocumentEntityTypes.Clinics]: 'clinic',
    [DocumentEntityTypes.Recipients]: 'recipient',
    [DocumentEntityTypes.Users]: 'user',
    [DocumentEntityTypes.GeneticScreening]: 'genetic-screening',
    [DocumentEntityTypes.DonorCycles]: 'donorcyle',
};

@Injectable({
    providedIn: 'root',
})
export class UploadDocumentsService extends BaseService<IDocument> {
    constructor(public http: HttpClient) {
        super('/documents', http);
    }

    private getUrlPrefix(entityType: number): string {
        return ENDPOINT_MAPPING[entityType];
    }

    getDocuments(entityType: number, entityId: number): Observable<IDonorDocument[]> {
        return this.http.get<IDonorDocument[]>(`/${this.getUrlPrefix(entityType)}/documents/${entityId}`, {});
    }

    getGeneticScreeningDocuments(entityType: number, entityId: number): Observable<any> {
        return this.http.get<any>(`/${this.getUrlPrefix(entityType)}/documents/${entityId}`, {});
    }

    getDocumentById(entityType: number, entityId: number, documentId: number): Observable<Blob> {
        return this.http.get(`/${this.getUrlPrefix(entityType)}/documents/${entityId}/${documentId}`, { responseType: 'blob' as 'blob' });
    }

    saveDocument(entityType: number, entityId: number, documentTypeId: number, file: File, geneticScreeningTypeId = 0): any {
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);

        let url = `/${this.getUrlPrefix(entityType)}/documents/${entityId}/uploads/${documentTypeId}`;
        if (geneticScreeningTypeId) {
            url = url.concat(`/genetic-screening-type/${geneticScreeningTypeId}`);
            return this.http.post(url, formData).pipe(catchError(this.handleError));
        }

        return this.http.post(url, formData).pipe(catchError(this.handleError));
    }

    createWithoutUpload(entityType: number, entityId: number, documentTypeId: number): any {
        return this.http
            .post(`/${this.getUrlPrefix(entityType)}/documents/${entityId}/create-without-upload/`, documentTypeId)
            .pipe(catchError(this.handleError));
    }

    updateDocumentEntity(entityType: number, entityId: number, documentEntityId: number, file: File): any {
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);

        return this.http
            .post(`/${this.getUrlPrefix(entityType)}/documents/${entityId}/update-document-entity/${documentEntityId}`, formData)
            .pipe(catchError(this.handleError));
    }

    getDocumentTypes(entityType: number, entityId: number): Observable<IDocumentType[]> {
        return this.http.get<IDocumentType[]>(`/${this.getUrlPrefix(entityType)}/documents/${entityId}/types`, {});
    }

    deleteDocument(entityType: number, entityId: number, documentId: number): Observable<any> {
        return this.http.delete<any>(`/${this.getUrlPrefix(entityType)}/documents/${entityId}/${documentId}`, {});
    }

    deleteWithoutDocument(entityType: number, entityId: number, documentEntityId: number): Observable<any> {
        return this.http.delete<any>(`/${this.getUrlPrefix(entityType)}/documents/${entityId}/delete-without-document/${documentEntityId}`, {});
    }
}
