import { Injectable } from '@angular/core';
import { DatePipe, UpperCasePipe, LowerCasePipe, CurrencyPipe, PercentPipe, DecimalPipe } from '@angular/common';

import { IsPrimaryPipe, PhonePipe } from '@mt-ng2/phone-control';

import { AddressPipe } from '../address.pipe';

@Injectable()
export class EntityListPipeService {
    constructor(
        private primaryPipe: IsPrimaryPipe,
        private phonePipe: PhonePipe,
        private addressPipe: AddressPipe,
        private datePipe: DatePipe,
        private upperCasePipe: UpperCasePipe,
        private lowerCasePipe: LowerCasePipe,
        private numberPipe: DecimalPipe,
        private currencyPipe: CurrencyPipe,
        private percentPipe: PercentPipe,
    ) {}

    /**
     * transforms the value using the indicated pipe
     * @param value
     * @param pipe
     */
    applyPipe(value, pipe): any {
        let pipeValues = pipe.split(':').map((p) => p.replace(/^\'+|\'+$/g, ''));
        if (pipeValues[0] === 'primary') {
            return this.primaryPipe.transform(value);
        }
        if (pipeValues[0] === 'phone') {
            return this.phonePipe.transform(value);
        }
        if (pipeValues[0] === 'address') {
            return this.addressPipe.transform(value);
        }
        if (pipeValues[0] === 'date') {
            return this.datePipe.transform(value, pipeValues[1], pipeValues[2], pipeValues[3]);
        }
        if (pipeValues[0] === 'upper') {
            return this.upperCasePipe.transform(value);
        }
        if (pipeValues[0] === 'lower') {
            return this.lowerCasePipe.transform(value);
        }
        if (pipeValues[0] === 'number') {
            return this.numberPipe.transform(value, pipeValues[1], pipeValues[2]);
        }
        if (pipeValues[0] === 'currency') {
            return this.currencyPipe.transform(value, pipeValues[1], pipeValues[2], pipeValues[3], pipeValues[4]);
        }
        if (pipeValues[0] === 'percent') {
            return this.percentPipe.transform(value, pipeValues[1], pipeValues[2]);
        }
    }
}
