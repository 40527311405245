import {
    Component,
    Input,
    Output,
    EventEmitter,
    ContentChild,
    TemplateRef,
    OnInit,
    KeyValueDiffers,
    DoCheck,
    KeyValueChangeRecord,
    ChangeDetectionStrategy,
    Injector,
    ChangeDetectorRef,
} from '@angular/core';

import { EntityListItemDirective } from '../entity-list-item.directive';
import { EntityListHeaderDirective } from '../entity-list-header.directive';
import { IEntityListModuleConfig, EntityListModuleConfigToken } from '@mt-ng2/entity-list-module-config';
import { EntityListPipeService } from '../services/entity-list-pipe.service';
import {
    IItemSelectedEvent,
    IColumnSelectedEvent,
    IItemDeletedEvent,
    ISelectionChangedEvent,
    IColumnSortedEvent,
    EntityListConfig,
    IEntity,
} from '../libraries/entity-list.library';
import { EntityListDeleteColumn, IEntityListDeleteColumn } from '../libraries/entity-list-delete-column.library';
import { EntityListColumn, SortDirection, IEntityListComponentMembers } from '../libraries/entity-list-column.library';
import { EntityListSelectHelper } from '../libraries/entity-list-select-helper.library';
import { buildEntityListApi, IEntityListApi } from '../libraries/entity-list-api.library';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'entity-list',
    styles: [
        `
            .item-counts {
                padding-left: 15px;
                position: relative;
                top: -20px;
                font-style: italic;
                font-size: small;
            }
        `,
    ],
    template: `
        <entity-list-actions>
            <entity-list-print *ngIf="hasPrint" (printModeChange)="printModeChanged($event)"></entity-list-print>
            <entity-list-export *ngIf="entityListConfig.hasExport" [entityListConfig]="entityListConfig"></entity-list-export>
        </entity-list-actions>
        <div class="table-responsive">
            <table class="table table-hover table-striped" [ngClass]="entityListConfig.hasTableClass ? entityListConfig.tableClass : null">
                <thead>
                    <tr *ngIf="!headerTemplate && entityListConfig">
                        <th
                            *ngIf="selectHelper.showSelect(printMode)"
                            [style.width.px]="selectHelper.selectConfig.width"
                            (click)="selectAllClicked($event)"
                        >
                            <i
                                *ngIf="selectHelper.isMultiSelect()"
                                class="fa fa-lg fa-fw"
                                aria-hidden="true"
                                [class.fa-check-square-o]="selectHelper.isAllItemsSelected(entities)"
                                [class.fa-square-o]="!selectHelper.isAllItemsSelected(entities)"
                            ></i>
                        </th>
                        <ng-container *ngFor="let column of columns">
                            <th
                                *ngIf="column.showColumn(printMode)"
                                [style.width.px]="column.style ? column.style.width : null"
                                [ngStyle]="{
                                    cursor: column.sort.disableSort ? 'auto' : 'pointer'
                                }"
                                [ngClass]="column.hasColumnClass ? column.getColumnClass(null) : null"
                                (click)="columnSorted(column, $event)"
                            >
                                <b
                                    >{{ column.name }}
                                    <span *ngIf="column.sort && !column.sort.disableSort">
                                        <i
                                            [style.opacity]="column.sort.direction === sortEnum.Asc ? null : 0.4"
                                            class="fa fa-sort-asc"
                                            aria-hidden="true"
                                        ></i>
                                        <i
                                            [style.opacity]="column.sort.direction === sortEnum.Desc ? null : 0.4"
                                            style="margin-left: -12px"
                                            class="fa fa-sort-desc"
                                            aria-hidden="true"
                                        ></i>
                                    </span>
                                </b>
                            </th>
                        </ng-container>
                        <th *ngIf="showDelete()" [style.width.px]="entityListConfigDelete.width">
                            <b>{{ entityListConfigDelete.headerText }}</b>
                        </th>
                    </tr>
                    <tr *ngIf="headerTemplate" class="no-pointer">
                        <ng-template [ngTemplateOutlet]="headerTemplate"> </ng-template>
                    </tr>
                </thead>
                <tbody *ngIf="!itemTemplate && entityListConfig">
                    <tr
                        *ngFor="let entity of entities"
                        (click)="itemSelected(entity, $event)"
                        [ngClass]="entityListConfig.hasRowClass ? entityListConfig.getRowClass(entity) : null"
                    >
                        <td
                            *ngIf="selectHelper.showSelect(printMode)"
                            class="ellipsis"
                            [style.background-color]="selectHelper.isItemSelected(entity) ? selectHelper.selectConfig.selectedColor : null"
                            [style.width.px]="selectHelper.selectConfig.width"
                        >
                            <i
                                *ngIf="selectHelper.isSelectable(entity)"
                                class="fa fa-lg fa-fw"
                                aria-hidden="true"
                                [class.fa-check-square-o]="selectHelper.isItemSelected(entity)"
                                [class.fa-square-o]="!selectHelper.isItemSelected(entity)"
                            ></i>
                        </td>
                        <ng-container *ngFor="let column of columns">
                            <td
                                *ngIf="column.showColumn(printMode) && !column.component"
                                (click)="column.fireOnColumnSelected ? columnSelected(entity, column, $event) : null"
                                class="ellipsis"
                                [style.background-color]="selectHelper.isItemSelected(entity) ? selectHelper.selectConfig.selectedColor : null"
                                [style.width.px]="column.style ? column.style.width : null"
                                [ngClass]="column.hasColumnClass ? column.getColumnClass(entity) : null"
                            >
                                <div *ngIf="column.bindToInnerHtml" [innerHTML]="column.getItemValue(entity, entityListPipeService)"></div>
                                <a *ngIf="column.linkFunction" (click)="handleLinkFunction(entity, column, $event)" href="javascript:void(0);">{{
                                    column.getItemValue(entity, entityListPipeService)
                                }}</a>
                                <div *ngIf="!column.bindToInnerHtml && !column.linkFunction">
                                    {{ column.getItemValue(entity, entityListPipeService) }}
                                </div>
                            </td>
                            <td
                                *ngIf="column.showColumn(printMode) && column.component"
                                [style.background-color]="selectHelper.isItemSelected(entity) ? selectHelper.selectConfig.selectedColor : null"
                                [style.width.px]="column.style ? column.style.width : null"
                                [ngClass]="column.hasColumnClass ? column.getColumnClass(entity) : null"
                            >
                                <ng-container
                                    dynamicCell
                                    [componentRef]="column.component"
                                    [item]="entity"
                                    [entityListComponentMembers]="entityListComponentMembers"
                                >
                                </ng-container>
                            </td>
                        </ng-container>
                        <td
                            *ngIf="showDelete() && entityListConfigDelete.confirm && !entityListConfigDelete.component"
                            class="ellipsis"
                            [style.background-color]="selectHelper.isItemSelected(entity) ? selectHelper.selectConfig.selectedColor : null"
                            [style.width.px]="entityListConfigDelete.width"
                            (mtConfirm)="itemDeleted(entity, $event)"
                            [mtConfirmOptions]="entityListConfigDelete.getConfirm(entity)"
                            [innerHtml]="entityListConfigDelete.getColumnHtml(entity)"
                        ></td>
                        <td
                            *ngIf="showDelete() && !entityListConfigDelete.confirm && !entityListConfigDelete.component"
                            class="ellipsis"
                            [style.background-color]="selectHelper.isItemSelected(entity) ? selectHelper.selectConfig.selectedColor : null"
                            [style.width.px]="entityListConfigDelete.width"
                            (click)="itemDeleted(entity, $event)"
                            [innerHtml]="entityListConfigDelete.getColumnHtml(entity)"
                        ></td>
                        <td
                            *ngIf="showDelete() && entityListConfigDelete.component"
                            class="ellipsis"
                            [style.background-color]="selectHelper.isItemSelected(entity) ? selectHelper.selectConfig.selectedColor : null"
                            [style.width.px]="entityListConfigDelete.width"
                        >
                            <ng-container
                                dynamicCell
                                [componentRef]="entityListConfigDelete.component"
                                [item]="entity"
                                [entityListComponentMembers]="entityListComponentMembers"
                            >
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="itemTemplate">
                    <ng-container *ngFor="let entity of entities">
                        <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: entity }"> </ng-template>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div *ngIf="showPagination && pagingNeeded() && !printMode">
            <entity-list-pagination
                [total]="total"
                [(currentPage)]="currentPage"
                [itemsPerPage]="itemsPerPage"
                (pageChange)="getEntities()"
            ></entity-list-pagination>
        </div>
        <div *ngIf="pagingNeeded() && printMode">
            <span class="text-center"
                ><em>limiting results to first {{ itemsPerPage | number }} of {{ total | number }}</em></span
            >
        </div>
        <div *ngIf="showItemCounts!; printMode">
            <span class="item-counts text-muted">{{ getItemCounts() }}</span>
        </div>
        <div *ngIf="noEntities()">
            <h3 style="text-align: center;">{{ emptyMessage }}</h3>
        </div>
    `,
})
export class EntityListComponent implements DoCheck, OnInit, IEntityListComponentMembers {
    get entityListComponentMembers(): IEntityListComponentMembers {
        return {
            columnSelected: this.columnSelected.bind(this),
            entityListConfig: this.entityListConfig,
            getEntities: this.getEntities.bind(this),
            itemDeleted: this.itemDeleted.bind(this),
        };
    }

    get hasPrint(): boolean {
        return this.entityListConfig.print ? true : false;
    }
    printMode = false;

    // object that contains the logic for handling the selection of entities
    selectHelper: EntityListSelectHelper;

    columns: EntityListColumn[];

    /**
     * array of the entities to show in the list
     */
    protected _entities: any[];
    @Input('entities')
    set entities(value: any[]) {
        this._entities = value;
    }
    get entities(): any[] {
        if (!this._entities) {
            this._entities = [];
        }
        return this._entities;
    }

    private _initialSelectedEntities: IEntity[];
    @Input('selectedEntities')
    set selectedEntities(value: IEntity[]) {
        if (this.selectHelper?.selectedEntities) {
            this.selectHelper.selectedEntities = value;
        } else {
            // this covers when the value is set before ngOnInit has run
            this._initialSelectedEntities = value;
        }
    }
    get selectedEntities(): IEntity[] {
        return this.selectHelper?.selectedEntities ?? [];
    }

    sortDirection: SortDirection;
    sortEnum = SortDirection;
    /**
     * config that defines how to display the columns
     *
     * can be overridden by headerTemplate and/or itemTemplate
     */
    private _entityListConfigDiffer: any;
    private _entityListConfig: EntityListConfig;
    @Input('entityListConfig')
    set entityListConfig(value: EntityListConfig) {
        if (value) {
            this.setEntityListConfigDelete(value.delete);
        }
        this._entityListConfig = value;
        if (!this._entityListConfigDiffer && value) {
            this._entityListConfigDiffer = this.differs.find(value).create();
        }

        this.columns = this.entityListConfig.columns
            .filter((column) => !column.excludeFromView)
            .map((column) => {
                if (column instanceof EntityListColumn) {
                    return column;
                }
                return new EntityListColumn(column);
            });
    }
    get entityListConfig(): EntityListConfig {
        return this._entityListConfig;
    }
    entityListConfigDelete: EntityListDeleteColumn;
    /**
     * message to be shown when the entity array is empty
     *
     * default: 'No data to display.'
     */
    @Input() emptyMessage = 'No data to display.';
    /**
     * number of items to show per page
     */
    private _itemsPerPageInitial: number;
    private _itemsPerPage: number;
    @Input('itemsPerPage')
    set itemsPerPage(value: number) {
        if (this._itemsPerPage !== value) {
            this._itemsPerPage = value;
            this.itemsPerPageChange.emit(value);
        }
    }
    get itemsPerPage(): number {
        return this._itemsPerPage;
    }
    @Output() itemsPerPageChange: EventEmitter<number> = new EventEmitter<number>();
    /**
     * total number of items available on all pages
     */
    @Input() total: number;
    /**
     * whether to show the item counts along with paging
     */
    @Input() showItemCounts = false;
    /**
     * the current page value
     *
     * this value supports two-way binding
     */
    @Input() currentPage: number;
    @Output() currentPageChange: EventEmitter<number> = new EventEmitter<number>();

    /**
     * event that is fired whenever the page has been changed
     */
    @Output() onPageChanged: EventEmitter<string> = new EventEmitter<string>();
    /**
     * event that is fired whenever an item is selected
     */
    @Output() onItemSelected: EventEmitter<IItemSelectedEvent> = new EventEmitter<IItemSelectedEvent>();
    /**
     * event that is fired whenever a column sort is clicked
     */
    @Output() onColumnSorted: EventEmitter<IColumnSortedEvent> = new EventEmitter<IColumnSortedEvent>();
    /**
     * event that is fired whenever a column is selected, so long as fireOnColumnSelected is set for that column
     */
    @Output() onColumnSelected: EventEmitter<IColumnSelectedEvent> = new EventEmitter<IColumnSelectedEvent>();
    /**
     * event that is fired whenever an item is deleted
     */
    @Output() onItemDeleted: EventEmitter<IItemDeletedEvent> = new EventEmitter<IItemDeletedEvent>();
    /**
     * event that is fired whenever an item is selected
     */
    @Output() onSelectionChanged: EventEmitter<ISelectionChangedEvent> = new EventEmitter<ISelectionChangedEvent>();
    /**
     * event that is fired when the control is ready
     */
    @Output() ready: EventEmitter<IEntityListApi> = new EventEmitter<IEntityListApi>();

    /**
     * pulls the element with the first EntityListHeaderDirective found, and treats it as a template ref
     *
     * this value will override the header from the entityListConfig
     */
    @ContentChild(EntityListHeaderDirective, { read: TemplateRef })
    headerTemplate: TemplateRef<any>;
    /**
     * pulls the element with the first EntityListItemDirective found, and treats it as a template ref
     *
     * this value will override the item from the entityListConfig
     */
    @ContentChild(EntityListItemDirective, { read: TemplateRef })
    itemTemplate: TemplateRef<any>;

    @Input() showPagination = true;

    public entityListPipeService: EntityListPipeService;
    protected differs: KeyValueDiffers;
    protected entityListModuleConfig: IEntityListModuleConfig;
    // used in the buildEntityListApi
    public cdr: ChangeDetectorRef;

    constructor(injector: Injector) {
        this.entityListPipeService = injector.get(EntityListPipeService);
        this.differs = injector.get(KeyValueDiffers);
        this.entityListModuleConfig = injector.get(EntityListModuleConfigToken, null);
        this.cdr = injector.get(ChangeDetectorRef);
    }

    ngDoCheck(): void {
        if (this._entityListConfigDiffer) {
            const changes = this._entityListConfigDiffer.diff(this._entityListConfig);
            if (changes) {
                changes.forEachChangedItem((record: KeyValueChangeRecord<any, any>) => {
                    if (record.key === 'delete') {
                        this.setEntityListConfigDelete(record.currentValue);
                    }
                });
            }
        }
    }

    ngOnInit(): void {
        // if an itemsPerPage value was not explicitly passed
        if (!this.itemsPerPage) {
            // then assign it
            this.itemsPerPage = this.entityListModuleConfig?.itemsPerPage ?? 12;
        }
        this._itemsPerPageInitial = this.itemsPerPage;

        // setup entity select helper
        this.selectHelper = new EntityListSelectHelper(
            this.entityListConfig,
            this.entityListModuleConfig,
            this._initialSelectedEntities,
            (() => this.entities).bind(this),
        );

        // check if any column was configured to have the default sort if not
        // just set it to the first column
        if (!this.entityListConfig.columns.find((c) => c.sort.hasOwnProperty('direction') && c.sort.direction !== null)) {
            // Get the first column noted as a default sort column
            let col = this.entityListConfig.columns.find((c) => c.sort.hasOwnProperty('isDefaultForSort') && c.sort.isDefaultForSort === true);
            // If one is found we set the sort.direction.  This variable is what the list components uses to
            // know which arrow to activate.  If one is not found I pick the first column by defauld.
            // The only catch here is if they are using a accessor function on the first object.  If thats
            // the case then they need to pass in the sortProperty or the control will bomb out.
            col
                ? (this.entityListConfig.columns[this.entityListConfig.columns.indexOf(col)].sort.direction = SortDirection.Asc)
                : (this.entityListConfig.columns[0].sort.direction = SortDirection.Asc);
        }

        this.ready.emit(buildEntityListApi(this));
    }

    setEntityListConfigDelete(value: IEntityListDeleteColumn): void {
        if (value) {
            if (value instanceof EntityListDeleteColumn) {
                this.entityListConfigDelete = value;
            } else {
                this.entityListConfigDelete = new EntityListDeleteColumn(value);
            }
        } else {
            this.entityListConfigDelete = null;
        }
    }

    noEntities(): boolean {
        return !this.entities || this.entities.length === 0;
    }

    pagingNeeded(): boolean {
        return this.total > this.itemsPerPage;
    }

    getEntities(): void {
        this.currentPageChange.emit(this.currentPage);
        this.onPageChanged.emit();
    }

    selectAllClicked(event: Event): void {
        this.selectHelper.handleSelectAll();
        this.emitSelectionChangedEvent(event);
    }

    itemSelected(entity, event: MouseEvent): void {
        event?.stopPropagation?.();
        if (this.selectHelper.selectConfig && this.selectHelper.isSelectable(entity)) {
            this.selectHelper.handleSelection(entity, event);
            this.emitSelectionChangedEvent(event);
        }
        let itemSelectedEvent: IItemSelectedEvent = {
            $event: event,
            entity: entity,
        };
        this.onItemSelected.emit(itemSelectedEvent);
    }

    setColumnSorting(sortPropertyName: string, direction: SortDirection): void {
        this.sortDirection = direction;
        for (let i = 0; i < this.entityListConfig.columns.length; i++) {
            const col = this.entityListConfig.columns[i];
            if (col.hasOwnProperty('sort')) {
                col.sort.direction = col.sort.sortProperty !== sortPropertyName ? null : this.sortDirection;
            }
        }
    }

    columnSorted(column: EntityListColumn, event: Event): void {
        if (column.sort.disableSort) {
            return;
        }
        event?.stopPropagation?.();
        // Flip the sort control
        column.sort.direction = column.sort.direction === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
        this.setColumnSorting(column.sort.sortProperty, column.sort.direction);
        let columnSortedEvent: IColumnSortedEvent = {
            $event: event,
            column: column,
        };
        this.onColumnSorted.emit(columnSortedEvent);
    }

    emitSelectionChangedEvent(event): void {
        let selectionChangedEvent: ISelectionChangedEvent = {
            $event: event,
            selectedEntities: this.selectHelper.selectedEntities,
        };
        this.onSelectionChanged.emit(selectionChangedEvent);
    }

    columnSelected(entity, column: EntityListColumn, event: Event): void {
        event?.stopPropagation?.();
        let columnSelectedEvent: IColumnSelectedEvent = {
            $event: event,
            column: column,
            entity: entity,
        };
        this.onColumnSelected.emit(columnSelectedEvent);
    }

    itemDeleted(entity, event: Event): void {
        event?.stopPropagation?.();
        let itemDeletedEvent: IItemDeletedEvent = {
            $event: event,
            entity: entity,
        };
        if (this.currentPage > 1 && this.entities.length === 1) {
            // decrement the current page if the item being deleted is the last thing on the page
            this.currentPage--;
            this.getEntities();
        }
        this.onItemDeleted.emit(itemDeletedEvent);
    }

    handleLinkFunction(entity, column: EntityListColumn, event: Event): void {
        event?.stopPropagation?.();
        column.linkFunction(entity);
    }

    printModeChanged(printMode: boolean): void {
        this.printMode = printMode;
        if (printMode) {
            this.currentPage = 1;
            this.itemsPerPage = this.entityListConfig.print.itemsPerPageInPrintMode;
            if (this.entityListConfig.print.automaticallyCallWindowPrint) {
                setTimeout(() => window.print(), 750);
            }
        } else {
            this.itemsPerPage = this._itemsPerPageInitial;
        }
        this.getEntities();
    }

    showDelete(): boolean {
        if (!this.entityListConfigDelete) {
            return false;
        }
        return this.entityListConfigDelete.showColumn(this.printMode);
    }

    getItemCounts(): string {
        return `${(this.currentPage - 1) * this.itemsPerPage + 1} - ${this.currentPage * this.itemsPerPage} of ${this.total}`;
    }
}
