import { FileUploader, FileUploaderOptions, FileLikeObject } from 'ng2-file-upload';
import { NotificationsService } from '@mt-ng2/notifications-module';

import { globalInjector } from './global-injector.library';
import {
    IPhotoControlModuleConfig,
    getSettingsFromConfigs,
    PhotoControlModuleConfigToken,
    IMtFileUploaderSettings,
} from '@mt-ng2/photo-control-config';

export class MtFileUploader extends FileUploader {
    fileHasError: boolean;
    private notificationsService: NotificationsService;

    constructor(componentLevelUploaderSettings?: IMtFileUploaderSettings) {
        const photoControlConfig = globalInjector.get(PhotoControlModuleConfigToken, null) as IPhotoControlModuleConfig;
        const moduleLevelUploaderSettings = (photoControlConfig && photoControlConfig.uploaderSettings) || null;
        let uploaderSettings = getSettingsFromConfigs(moduleLevelUploaderSettings, componentLevelUploaderSettings);
        const config = Object.assign({}, defaultUploaderConfig, uploaderSettings);
        super(config);

        this.notificationsService = globalInjector.get(NotificationsService);
        this.onWhenAddingFileFailed = this.addingFileFailedHandler.bind(this);
    }

    private addingFileFailedHandler(item: FileLikeObject, filter: any, options: any): void {
        let errorMessage: string;
        switch (filter.name) {
            case 'fileSize':
                errorMessage = `Maximum upload size exceeded (${item.size} of ${options.maxFileSize} allowed)`;
                break;
            case 'mimeType':
                const allowedTypes = this.options.allowedMimeType.join(', ');
                errorMessage = `Type "${item.type} is not allowed. Allowed types: "${allowedTypes}"`;
                break;
            default:
                errorMessage = `Unknown error (filter is ${filter.name})`;
        }
        this.fileHasError = true;
        this.notificationsService.error(errorMessage);
    }
}

export const defaultUploaderConfig: IMtFileUploaderSettings = {
    allowedMimeType: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
    isHTML5: true,
    maxFileSize: 1 * 1024 * 1024,
};
