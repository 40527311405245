import { NotificationStrategy } from '../../strategies/notification-strategy';
import { ToastrService } from 'ngx-toastr';

export class PortalNotificationStrategy extends NotificationStrategy {
    /**
     *
     */
    constructor(private toastrService: ToastrService) {
        super();
    }
    success(message: string, options?: any): void {
        if (!options.onHidden) {
            this.toastrService.success(message, null, options);
        } else {
            this.toastrService.success(message, null, options).onHidden.subscribe(() => options.onHidden());
        }
    }

    error(message: string, title?: string, options?: any): void {
        this.toastrService.error(message, title, options);
    }

    warning(message: string, options?: any): void {
        this.toastrService.warning(message, null, options);
    }

    info(message: string, options?: any): void {
        this.toastrService.info(message, null, options);
    }

    show(message: string, title?: string, options?: any, type?: string): void {
        throw new Error('Method not implemented.');
    }
}
