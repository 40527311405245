import { Component, OnInit, Injector, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { INote } from '@mt-ng2/note-control';
import { SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IEntityRouteConfig } from '@mt-ng2/entity-components-base';
import { IItemSelectedEvent, IItemDeletedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { IHasNotes } from '../common-notes.component';
import { NotesEntityListConfig } from './common-notes-list.entity-list-config';
import { EntityComponentsNotesModuleConfig } from '../libraries/module-config.library';

@Component({
    selector: 'app-common-notes-list',
    template: `
        <div *ngIf="!selectedNote || !canEdit">
            <div>
                <div class="input-group">
                    <input type="text" [formControl]="searchControl" class="form-control" placeholder="Search" />
                    <span class="input-group-btn">
                        <button type="button" (click)="clearSearch()" class="btn btn-default btn-nohover btn-flat">
                            <i class="fa"></i>
                            <i class="fa fa-remove" aria-hidden="true"></i>
                            <i class="fa"></i>
                        </button>
                    </span>
                </div>
                <br />
            </div>
            <entity-list
                [entities]="notes"
                [itemsPerPage]="itemsPerPage"
                [total]="total"
                [(currentPage)]="currentPage"
                (onPageChanged)="getNotes()"
                (onItemSelected)="selectNote($event)"
                (onItemDeleted)="deleteNote($event)"
                (onColumnSorted)="columnSorted($event)"
                [entityListConfig]="entityListConfig"
            >
            </entity-list>
            <a [routerLink]="['../']" class="btn btn-default">Back</a>
            <div *ngIf="canEdit" class="fab-wrap-b-r" (click)="addNote()">
                <a class="btn btn-primary btn-fab-lg">
                    <span class="fa fa-plus"></span>
                </a>
            </div>
        </div>
        <div *ngIf="selectedNote && canEdit" class="miles-form padded">
            <h4>{{ selectedNote.Id > 0 ? 'Edit' : 'Add' }} {{ objectName }}</h4>
            <form [formGroup]="noteForm" (ngSubmit)="save()">
                <mt-note [note]="selectedNote" [parentForm]="noteForm" [focus]="true"></mt-note>
                <button type="submit" class="btn btn-flat btn-success">Save</button>
                <button type="button" class="btn btn-flat btn-default" (click)="cancel()">Cancel</button>
                <button *ngIf="selectedNote.Id > 0" type="button" class="btn btn-flat btn-danger pull-right" (mtConfirm)="delete()">Delete</button>
            </form>
        </div>
    `,
})
export class CommonNotesListComponent implements OnInit {
    id: number;
    service: IHasNotes;
    searchControl = new FormControl();
    notes: INote[];
    itemsPerPage = 12;
    currentPage = 1;
    total: number;
    canEdit = false;
    objectName = 'Note';
    noteForm: FormGroup;

    _disableSort: boolean;
    @Input('disableSort')
    set disableSort(value: boolean) {
        this._disableSort = value;
    }

    entityListConfig: NotesEntityListConfig;
    order: string;
    orderDirection: string;

    private _selectedNote: INote = null;
    get selectedNote(): INote {
        return this._selectedNote;
    }
    set selectedNote(value: INote) {
        if (value === null) {
            this.noteForm = new FormGroup({});
        }
        this._selectedNote = value;
    }

    newNote: INote = {
        Id: 0,
        NoteText: '',
        Title: '',
    };

    protected route: ActivatedRoute;
    protected notificationsService: NotificationsService;
    protected claimsService: ClaimsService;
    protected config: EntityComponentsNotesModuleConfig;

    constructor(protected injector: Injector) {
        this.route = injector.get(ActivatedRoute);
        this.notificationsService = injector.get(NotificationsService);
        this.claimsService = injector.get(ClaimsService);
        this.config = injector.get(EntityComponentsNotesModuleConfig);
        this._disableSort = this.config.disableSort;
        this.entityListConfig = new NotesEntityListConfig(this._disableSort);
        this.order = this.entityListConfig.getDefaultSortProperty();
        this.orderDirection = this.entityListConfig.getDefaultSortDirection();
    }

    ngOnInit(): void {
        this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe((value) => this.getNotes());

        this.noteForm = new FormGroup({});

        const config: IEntityRouteConfig = <IEntityRouteConfig>this.route.parent.snapshot.data;
        this.id = +this.route.parent.snapshot.paramMap.get(config.entityIdParam);
        this.canEdit = this.claimsService.hasClaim(config.claimType, [ClaimValues.FullAccess]);
        const service = this.getServiceFromConfig(config);
        this.service = this.injector.get(service);
        this.objectName = config.notesObjectName || 'Note';
        this.getNotes();
    }

    getServiceFromConfig(config: IEntityRouteConfig): any {
        let childRoute = this.route.snapshot;
        while (childRoute.firstChild) {
            childRoute = childRoute.firstChild;
        }
        const childConfig = childRoute.data;
        return childConfig?.service ?? config?.service;
    }

    getNotes(): void {
        const search = this.searchControl.value;
        const searchparamProperties: IEntitySearchParams = {
            order: this.order,
            orderDirection: this.orderDirection,
            query: search?.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };
        const searchparams = new SearchParams(searchparamProperties);

        this.service.getNotes(this.id, searchparams).subscribe((response) => {
            this.notes = response.body;
            this.total = +response.headers.get('X-List-Count');
        });
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getNotes();
    }

    clearSearch(): void {
        this.searchControl.setValue('');
    }

    noNotes(): boolean {
        return !this.notes || this.notes.length === 0;
    }

    pagingNeeded(): boolean {
        return this.total > this.itemsPerPage;
    }

    addNote(): void {
        this.selectedNote = { ...this.newNote };
    }

    save(): void {
        if (this.noteForm.valid) {
            const note = this.noteForm.value;
            this.service.saveNote(this.id, note).subscribe(
                (success) => {
                    this.notificationsService.success('Note Saved Successfully');
                    this.selectedNote = null;
                    this.getNotes();
                },
                (error) => this.notificationsService.error('Save Failed'),
            );
        } else {
            this.notificationsService.error('Save Failed');
            markAllFormFieldsAsTouched(this.noteForm);
        }
    }

    cancel(): void {
        this.selectedNote = null;
    }

    delete(): void {
        if (this.noteForm.value.Id) {
            this.deleteNoteById(this.noteForm.value.Id);
        }
    }

    deleteNote(event: IItemDeletedEvent): void {
        this.deleteNoteById(event.entity.Id);
    }

    protected deleteNoteById(noteId: number): void {
        this.service.deleteNote(this.id, noteId).subscribe(
            (success) => {
                this.notificationsService.success('Note Deleted');
                this.selectedNote = null;
                this.getNotes();
            },
            (error) => this.notificationsService.error('Delete Failed'),
        );
    }

    selectNote(event: IItemSelectedEvent): void {
        let note = event.entity;
        if (this.selectedNote === note) {
            this.selectedNote = null;
        } else {
            this.selectedNote = note;
        }
    }
}
