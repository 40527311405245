import { ComponentErrorHandler } from '@mt-ng2/component-error-handler';

export function getProperty(item: any, nameOfProperty: string): string {
    if (!nameOfProperty) {
        return item;
    }
    const props = nameOfProperty.split('.');
    let value = item;
    props.forEach((prop) => {
        value = value[prop];
    });
    return value;
}

export function regExpEscape(text: string): string {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function getValue(item: any, nameProperty: string, errorHandler: ComponentErrorHandler): string {
    try {
        return getProperty(item, nameProperty);
    } catch (error) {
        errorHandler.addError('getValue failed, check nameProperty to ensure it is valid');
        throw error;
    }
}

export enum Key {
    Tab = 9,
    Enter = 13,
    Escape = 27,
    Space = 32,
    PageUp = 33,
    PageDown = 34,
    End = 35,
    Home = 36,
    ArrowLeft = 37,
    ArrowUp = 38,
    ArrowRight = 39,
    ArrowDown = 40,
}

export interface ISelectionChangedEvent {
    selection: any;
}

export interface ITypeAheadAPI {
    clearValue: () => void;
    setValue: (item: any) => void;
    focus: () => void;
}
