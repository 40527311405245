import { Component, Injector } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { NumericControlTypes, INumericControlComponentApi } from '@mt-ng2/numeric-control';

import { IDynamicFieldTypeNumericFunctions } from '../../libraries/interfaces/dynamic-field-type';
import { CustomFormControlComponentBase } from '../custom-form-component.base';

export enum NumericInputTypes {
    Integer = 1,
    Float = 2,
    Currency = 3,
    Percentage = 4,
}

@Component({
    selector: 'app-form-numeric',
    styles: [
        `
            .space-for-validation {
                min-height: 35px;
                margin-bottom: 15px;
            }
        `,
    ],
    templateUrl: './form-numeric.component.html',
})
export class FormNumericComponent extends CustomFormControlComponentBase {
    get isCurrency(): boolean {
        return this.config.type.inputType === NumericInputTypes.Currency;
    }

    get isPercentage(): boolean {
        return this.config.type.inputType === NumericInputTypes.Percentage;
    }

    get numericControlType(): NumericControlTypes {
        return this.config.type.inputType as any;
    }

    numericControlApi: INumericControlComponentApi;

    constructor(injector: Injector) {
        super(injector);
    }

    focusMe(): void {
        this.numericControlApi.focus();
    }

    hasAddOn(): boolean {
        return this.getNumericAddOn() ? true : false;
    }

    getNumericAddOn(): string {
        let defaultAddOn = '';
        if (this.isPercentage) {
            defaultAddOn = '%';
        }
        if (this.isCurrency) {
            defaultAddOn = '$';
        }
        const addOnFromConfig = this.config.type.numericAddOn?.html;
        return addOnFromConfig === undefined ? defaultAddOn : addOnFromConfig;
    }

    getNumericFunctionsSafe(): IDynamicFieldTypeNumericFunctions {
        return this.config.type.numericFunctions || ({} as any);
    }

    private getNumericAddOnPosition(): 'right' | 'left' {
        let defaultAddOnPosition: 'right' | 'left' = 'left';
        if (this.isPercentage) {
            defaultAddOnPosition = 'right';
        }
        if (this.isCurrency) {
            defaultAddOnPosition = 'left';
        }
        const addOnPositionFromConfig = this.config.type.numericAddOn?.position ?? undefined;
        return addOnPositionFromConfig === undefined ? defaultAddOnPosition : addOnPositionFromConfig;
    }

    isNumericAddOnRight(): boolean {
        return this.getNumericAddOnPosition() !== 'left';
    }

    getMinMaxErrorMessage(): string {
        let min = this.config.validators?.min || this.config.validators?.min === 0 ? this.config.validators.min : null;
        let max = this.config.validators?.max || this.config.validators?.max === 0 ? this.config.validators.max : null;
        if (this.isPercentage) {
            min = min ? min * 100 : min;
            max = max ? max * 100 : max;
        }
        if (min !== null && max !== null) {
            return `${this.config.label} must be ${min} through ${max}`;
        }
        if (min !== null) {
            return `${this.config.label} must be at least ${min}`;
        }
        return `${this.config.label} must be no more than ${max}`;
    }

    protected getErrorMessageFromKey(key: string, errors: ValidationErrors): string {
        let message = super.getErrorMessageFromKey(key, errors);
        if (message) {
            return message;
        }

        switch (key) {
            case 'min':
            case 'max':
                return this.getMinMaxErrorMessage();
            default:
                return '';
        }
    }
}
