import { FormControl } from '@angular/forms';

/**
 * Returns invalid for a control even if 0 is passed.
 * @param control
 */
export function noZeroRequiredValidator(control: FormControl): any {
    let value = control.value;
    if (value && typeof value === 'string') {
        value = (value as string).trim();
    }
    if (!value || value === '0') {
        return {
            noZeroRequired: {
                message: 'required not met: failed for either null, blank string, or zero (number or string)',
            },
        };
    }
    return null;
}
