import { Injectable, Inject, Optional } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Subject } from 'rxjs';
import { PageTitlesModuleConfigToken, IPageTitlesModuleConfig, defaultModuleConfig } from '@mt-ng2/page-titles-config';

@Injectable({
    providedIn: 'root',
})
export class PageTitlesService {
    private _setPageTitleCalled = new Subject<string>();
    /**
     * Observable emitting the value passed in to setPageTitle whenever it is called.
     * This is the value prior to the TitleTextFormatFunction being called.  (i.e. if
     * setPageTitle was called with 'Customers' and the TitleTextFormatFunction would return
     * 'Breck - Customers' this value would be the original 'Customers')
     */
    setPageTitleCalled = this._setPageTitleCalled.asObservable();

    private _pageTitleSet = new BehaviorSubject<string>(null);
    /**
     * Observable emitting the value when the page title changes after having called
     * setPageTitle.  This is the value after the TitleTextFormatFunction has been called.
     * (i.e. if setPageTitle was called with 'Customers' and the TitleTextFormatFunction
     * would return 'Breck - Customers' this value would be formmated value of
     * 'Breck - Customers')
     */
    pageTitleSet = this._pageTitleSet.asObservable();

    /**
     * The page title currently set in the browser tab
     */
    get currentPageTitle(): string {
        return this._pageTitleSet.getValue();
    }

    constructor(
        private titleService: Title,
        @Inject(PageTitlesModuleConfigToken)
        @Optional()
        private moduleConfig: IPageTitlesModuleConfig,
    ) {
        this.setModuleConfig(moduleConfig);
    }

    private setModuleConfig(moduleConfig: IPageTitlesModuleConfig): void {
        if (!moduleConfig) {
            moduleConfig = {};
        }
        this.moduleConfig = Object.assign(defaultModuleConfig, moduleConfig);
    }

    /**
     * This function sets the page title to the browser tab.  It will run the
     * TitleTextFormatFunction on the title parameter prior to setting it.
     * @param {string} title - the title to set.
     */
    setPageTitle(title: string): void {
        if (this.moduleConfig && this.moduleConfig.ignorePageTitleCalls) {
            return;
        }

        // emit that we received a title
        this._setPageTitleCalled.next(title);

        // get any title text format function from the config
        const titleTextFormatFunction = (this.moduleConfig && this.moduleConfig.titleTextFormatFunction) || null;

        // if we have a title text format function, then call it
        const formattedTitle = titleTextFormatFunction ? titleTextFormatFunction(title) : title;

        // set the page title
        this.titleService.setTitle(formattedTitle);

        // emit the final page title
        this._pageTitleSet.next(formattedTitle);
    }
}
