export interface IEntityListPrintConfig {
    itemsPerPageInPrintMode: number;
    automaticallyCallWindowPrint: boolean;
}

export class EntityListPrintConfig implements IEntityListPrintConfig {
    constructor(
        public itemsPerPageInPrintMode = 100,
        public automaticallyCallWindowPrint = true,
    ) { }
}
