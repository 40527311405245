import { InjectionToken } from '@angular/core';

/**
 * function run against every setPageTitle call to allow text changes of the title
 * as pre or post (i.e. 'Breck - Customers')
 * @param {string} title (string) the title text sent in to the setPageTitle call
 * @return {string} final title text that will be set on the page
 */
export type TitleTextFormatFunction = (title: string) => string;

/**
 * Config object for the PageTitlesModule
 * @property {TitleTextFormatFunction} titleTextFormatFunction function run against every
 * setPageTitle call to allow formatting of the title as pre or post (i.e. 'Breck - Customers')
 */
export interface IPageTitlesModuleConfig {
    titleTextFormatFunction?: TitleTextFormatFunction;
    /**
     * @property {boolean} flag to indicate whether to supress the action of setting the
     * title to the page and tab (module-wide setting for use with projects that are not fully
     * implementing pageTitles)
     */
    ignorePageTitleCalls?: boolean;
}

export const defaultModuleConfig: IPageTitlesModuleConfig = {
    ignorePageTitleCalls: false,
    titleTextFormatFunction: null,
};

/**
 * Token used for providing the IPageTitlesModuleConfig
 * @example
 * // in the app or shared modules providers section
 * providers: [
 *     { provide: PageTitlesModuleConfigToken, useValue: pageTitlesModuleConfig },
 * ],
 */
export const PageTitlesModuleConfigToken = new InjectionToken<IPageTitlesModuleConfig>('page_title_module_config');
