import { ComponentErrorHandler } from '@mt-ng2/component-error-handler';

import { DynamicFieldType } from './dynamic-field.library';
import { IDynamicLabel } from './interfaces/dynamic-label';

function instanceOfIDynamicLabel(object: any): object is IDynamicLabel {
    if (typeof object !== 'object') {
        return false;
    }
    return 'label' in object && 'value' in object && 'type' in object;
}

/**
 * This class contructs the label object
 */
export class DynamicLabel implements IDynamicLabel {
    private errorHandler = new ComponentErrorHandler('DynamicLabel', '@mt-ng2/dynamic-form');

    label: string;
    value: string | number | Date | boolean;
    type: DynamicFieldType;
    valueHtml?: string;

    constructor(label: string | IDynamicLabel, value?: string | number | Date | boolean, type?: DynamicFieldType) {
        // check for deprecated constructor params and warn if used
        // *********************************************************************
        // * TODO CCC 9-4-2018: have commented out the deprecated params check,
        // * since it will cause lots of warnings for several pacakges and pages.
        // * once we have retrofitted the other packages, breck, and yo-breck,
        // * we can turn this check back on.
        // *********************************************************************
        // this.deprecatedConstructorParamsCheck(label);
        // build the constructorObject from either the IDynamicLabel first param
        // or the older depreacted params
        const constructorObject: IDynamicLabel = instanceOfIDynamicLabel(label)
            ? <IDynamicLabel>label
            : {
                  label: label,
                  type: type,
                  value: value,
                  valueHtml: null,
              };
        // construct the class from the constructorObject
        this.constructClass(constructorObject);
    }

    private deprecatedConstructorParamsCheck(label: string | IDynamicLabel): void {
        if (typeof label === 'string') {
            this.errorHandler.addWarning(
                'non-interface constructor parameter deprecated September-2018 with release 1.3.0 - use IDynamicLabel as your first parameter instead',
            );
        }
    }

    private constructClass(constructorObject: IDynamicLabel): void {
        // assign contructorObject values to the class
        Object.assign(this, constructorObject);
    }
}
