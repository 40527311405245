import { Component, Injector } from '@angular/core';

import { CustomFormControlComponentBase } from '../custom-form-component.base';

@Component({
    styles: [
        `
            .inside-box-maxlength {
                position: relative !important;
                bottom: -13px;
                top: -13px;
                margin-bottom: -13px;
                right: 3px;
                font-size: 70%;
            }
        `,
    ],
    templateUrl: './form-color-picker.component.html',
})
export class FormColorPickerComponent extends CustomFormControlComponentBase {
    position: 'right' | 'left' | 'top' | 'bottom';
    positionOffsetPercentage: number;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();
        if (this.config.type.colorpickerOptions) {
            this.position = this.config.type.colorpickerOptions.position || 'right';
            this.positionOffsetPercentage = this.config.type.colorpickerOptions.positionOffsetPercentage || 0;
        }
    }

    colorChanged(color: string): void {
        this.getControl().setValue(color);
    }
}
