import { EntityListPipeService } from '../services/entity-list-pipe.service';
import { EntityListConfig, IEntity } from './entity-list.library';
import { Type } from '@angular/core';

export enum ColumnShowOnPrint {
    ShowAlways,
    HideOnPrint,
    ShowOnlyOnPrint,
}

export enum SortDirection {
    Asc = 'asc',
    Desc = 'desc',
}

export type EntityListColumnClassFunction = (entity: any) => string;

export interface IEntityListDynamicCellComponentGeneric<T> {
    entity: T;
    entityListComponentMembers: IEntityListComponentMembers;
}

export interface IEntityListDynamicCellComponent extends IEntityListDynamicCellComponentGeneric<IEntity> {}

export type ColumnSelectedFunction = (entity: IEntity, column: EntityListColumn, event: Event) => void;
export type GetEntitiesFunction = () => void;
export type ItemDeletedFunction = (entity: IEntity, event: Event) => void;
export interface IEntityListComponentMembers {
    columnSelected: ColumnSelectedFunction;
    entityListConfig: EntityListConfig;
    getEntities: GetEntitiesFunction;
    itemDeleted: ItemDeletedFunction;
}

/**
 * defines the style for a column in the EntityListComponent
 * @param width how wide to make the column
 */
export interface IEntityListColumnStyle {
    width: number;
}

export interface IEntitySortProperty {
    isDefaultForSort?: boolean;
    sortProperty?: string;
    direction?: SortDirection;
    defaultDirection?: SortDirection;
    disableSort?: boolean;
}

/**
 * defines a column for the EntityListComponent
 * @param name used for the header row
 * @param accessors used to get the value from the entity (i.e. ['CustomerSource', 'Name'])
 * @param accessorFunction function used to get the value from the entity
 * @param pipes pipes to be run against the value
 * @param bindToInnerHtml uses [innerHTML] binding to show true html
 * @param linkFunction displays the value as an anchor tag with this function running onClick
 * @param fireOnColumnSelected will fire the onColumnSelected event for this column
 * @param style defines the style
 */
export interface IEntityListColumn {
    name: string;
    accessors?: string[];
    accessorFunction?: Function;
    pipes?: string[];
    bindToInnerHtml?: boolean;
    linkFunction?: Function;
    fireOnColumnSelected?: boolean;
    style?: IEntityListColumnStyle;
    showOnPrint?: ColumnShowOnPrint;
    sort?: IEntitySortProperty;
    component?: Type<IEntityListDynamicCellComponentGeneric<any>>;
    columnClass?: string | EntityListColumnClassFunction;
    excludeFromView?: boolean;
    excludeFromExport?: boolean;
}

export class EntityListColumn implements IEntityListColumn {
    public name: string;
    public accessors?: string[];
    public accessorFunction?: Function;
    public pipes?: string[];
    public bindToInnerHtml?: boolean;
    public linkFunction?: Function;
    public fireOnColumnSelected?: boolean;
    public style?: IEntityListColumnStyle;
    public showOnPrint?: ColumnShowOnPrint;
    public sort?: IEntitySortProperty;
    public component?: Type<IEntityListDynamicCellComponentGeneric<any>>;
    public columnClass: string | EntityListColumnClassFunction;
    public excludeFromView?: boolean;
    public excludeFromExport?: boolean;

    public get hasColumnClass(): boolean {
        return this.columnClass ? true : false;
    }

    public getColumnClass(entity: any): string {
        // return null if it's a header because that will never have an entity
        if (!entity) {
            return null;
        }

        if (typeof this.columnClass === 'function') {
            return this.columnClass(entity);
        } else {
            return this.columnClass;
        }
    }

    constructor(column: IEntityListColumn) {
        this.name = column.name;
        this.accessors = column.accessors;
        this.accessorFunction = column.accessorFunction;
        this.pipes = column.pipes;
        this.bindToInnerHtml = column.bindToInnerHtml;
        this.linkFunction = column.linkFunction;
        this.fireOnColumnSelected = column.fireOnColumnSelected;
        this.style = column.style;
        this.showOnPrint = column.showOnPrint;
        if (column.sort) {
            column.sort.direction = column.sort.defaultDirection ? column.sort.defaultDirection : null;
            column.sort.sortProperty = column.sort.sortProperty ? column.sort.sortProperty : this.getSortProperty(column);
            column.sort.disableSort = column.sort.disableSort ? column.sort.disableSort : false;
            column.sort.isDefaultForSort = column.sort.isDefaultForSort;
        } else {
            column.sort = {
                isDefaultForSort: false,
                sortProperty: this.getSortProperty(column),
            };
        }
        this.sort = column.sort;
        this.component = column.component;
        this.columnClass = column.columnClass;
        this.excludeFromExport = column.excludeFromExport;
        this.excludeFromView = column.excludeFromView;
    }

    showColumn(printMode: boolean): boolean {
        let show = true;
        const showOnPrint = this.showOnPrint;
        if (showOnPrint === null || showOnPrint === undefined) {
            return show;
        }

        if (printMode) {
            show = showOnPrint === ColumnShowOnPrint.ShowAlways || showOnPrint === ColumnShowOnPrint.ShowOnlyOnPrint;
        } else {
            show = showOnPrint === ColumnShowOnPrint.ShowAlways || showOnPrint === ColumnShowOnPrint.HideOnPrint;
        }
        return show;
    }

    getItemValue(entity, entityListPipeService: EntityListPipeService): string {
        let column = this;
        let value = entity;

        if (column.accessors) {
            column.accessors.forEach((accessor) => {
                value = value[accessor];
            });
        } else if (column.accessorFunction) {
            value = column.accessorFunction(entity);
        } else {
            value = value[column.name];
        }

        if (column.pipes) {
            column.pipes.forEach((pipe) => {
                value = entityListPipeService.applyPipe(value, pipe);
            });
        }

        return value;
    }

    private getSortProperty(column: IEntityListColumn): string {
        return column.accessors ? column.accessors.join('.') : column.name;
    }
}
