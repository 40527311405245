import { Directive, EventEmitter, HostListener, Input, OnInit, Output, Inject, Optional } from '@angular/core';
import { IAlertsConfig, AlertsModuleConfigToken } from '@mt-ng2/alerts-module-config';

import { IModalOptions, ModalService } from '@mt-ng2/modal-module';

export interface IMtConfirmOptions extends IModalOptions {}

/**
 * [mtConfirm] directive binds to a custom output event that listens to and prevents the default click operation.
 */
@Directive({
    selector: '[mtConfirm]',
})
export class MtConfirmDirective implements OnInit {
    /**
     * Emits when the user clicks "Confirm".
     * Bears a value when using "input", resolved "preConfirm", etc.
     *
     * @example
     * ```html
     * <button (mtConfirm)="delete()">Delete</button>
     * ```
     * ```typescript
     * public delete(): void {
     *     // delete logic
     * }
     * ```
     */
    @Output() public readonly mtConfirm = new EventEmitter<any>();

    /**
     * Emits when the user clicks "Cancel" (or dismisses the modal by any other way).
     * By default, it will emit a string representing the reason for which the modal has been closed, or the
     * value of a rejected "preConfirm".
     *
     * @example
     * ```html
     * <button (mtConfirm)="delete()" (cancel)="canceled($event)">Delete</button>
     * ```
     * ```typescript
     * public canceled(reason: string): void {
     *     // reason can be 'cancel', 'overlay', 'close', and 'timer'
     * }
     * ```
     */
    @Output() public readonly cancel = new EventEmitter<string>();

    /**
     * Pass in a modalOptions object that will override any of the default properties.
     *
     * @example
     * ```html
     * <button (mtConfirm)="delete()" [mtConfirmOptions]="confirmOptions">Delete</button>
     * ```
     * ```typescript
     * confirmOptions: IModalOptions = {
     *     confirmButtonText: 'Yes, delete it!',
     *     text: 'You wont be able to revert this!',
     *     title: 'Are you sure?',
     *     type: 'warning',
     * };
     * ```
     */
    @Input() public readonly mtConfirmOptions: IModalOptions = {};

    /**
     * Holds the default modal options.
     */
    private modalOptions: IModalOptions = {
        cancelButtonClass: 'btn btn-swal-override',
        confirmButtonClass: 'btn btn-swal-override',
        confirmButtonText: this.alertsConfig?.mtConfirmOptions?.confirmButtonText ?? 'Yes, delete it!',
        showCancelButton: true,
        text: this.alertsConfig?.mtConfirmOptions?.text ?? 'You wont be able to revert this!',
        title: this.alertsConfig?.mtConfirmOptions?.title ?? 'Are you sure?',
        type: this.alertsConfig?.mtConfirmOptions?.type ?? 'warning',
    };

    public constructor(private modalService: ModalService, @Inject(AlertsModuleConfigToken) @Optional() private alertsConfig: IAlertsConfig) {}

    public ngOnInit(): void {
        // override any of the passed in options vs the default options
        this.modalOptions = Object.assign(this.modalOptions, this.mtConfirmOptions);
    }

    @HostListener('click', ['$event'])
    public onHostClicked(event: MouseEvent): void {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();

        this.modalService.showModal(this.modalOptions).subscribe((result) => {
            if (result.value) {
                this.mtConfirm.emit(event);
            } else {
                this.cancel.emit(result.dismiss as any);
            }
        });
    }
}
