import { EntityListColumn, IEntityListColumn, SortDirection } from './entity-list-column.library';
import { EntityListPrintConfig } from './entity-list-print.library';
import { IEntityListDeleteColumn } from './entity-list-delete-column.library';
import { IEntityListSelectColumn } from './entity-list-select-column.library';
import { IEntityListExportConfig } from './entity-list-export.library';

export interface IEntity {
    Id: number;
}

export type EntityListRowClassFunction = (entity: any) => string;

/**
 * holds the definition for the EntityListComponent
 * @param columns the columns to show within the entity list
 * @param delete defines a delete column for the entity list
 */
export interface IEntityListConfig {
    columns: IEntityListColumn[];
    delete?: IEntityListDeleteColumn;
    export?: IEntityListExportConfig;
    select?: IEntityListSelectColumn;
    print?: EntityListPrintConfig;
    rowClass?: string | EntityListRowClassFunction;
    tableClass?: string;
}

export interface IItemSelectedEvent {
    entity: any;
    $event: Event;
}

export interface ISelectionChangedEvent {
    selectedEntities: IEntity[];
    $event: Event;
}

export interface IColumnSelectedEvent {
    entity: any;
    column: IEntityListColumn;
    $event: Event;
}

export interface IItemDeletedEvent {
    entity: any;
    $event: Event;
}

export interface IColumnSortedEvent {
    column: EntityListColumn;
    $event: Event;
}

export class EntityListConfig implements IEntityListConfig {
    public columns: IEntityListColumn[];
    public delete: IEntityListDeleteColumn;
    public export: IEntityListExportConfig;
    public select: IEntityListSelectColumn;
    public print: EntityListPrintConfig;
    public rowClass: string | EntityListRowClassFunction;
    public tableClass: string;

    public get hasRowClass(): boolean {
        return this.rowClass ? true : false;
    }

    public get hasExport(): boolean {
        return this.export && (this.export.getDataForExport || this.export.onExportClick) ? true : false;
    }

    public getRowClass(entity: any): string {
        if (typeof this.rowClass === 'function') {
            return this.rowClass(entity);
        } else {
            return this.rowClass;
        }
    }

    /**
     * Checks if the Table class was provided to apply to the table div
     */
    public get hasTableClass(): boolean {
        return this.tableClass ? true : false;
    }

    constructor(config: IEntityListConfig) {
        Object.assign(this, config);
    }

    /**
     * Checks if the config has a default for sort direction.  If not
     * it defaults to Asc.
     */
    public getDefaultSortDirection(): string {
        let ret: string;
        for (let i = 0; i < this.columns.length; i++) {
            const col = this.columns[i];
            if (col.hasOwnProperty('sort') && col.sort.hasOwnProperty('defaultDirection')) {
                return col.sort.defaultDirection;
            }
        }
        return SortDirection.Asc;
    }

    /**
     * Checks if the config has a default for sort set.  If not
     * it just applies it to the first property.
     */
    public getDefaultSortProperty(): string {
        let ret: string;
        for (let i = 0; i < this.columns.length; i++) {
            const col = this.columns[i];
            if (col.hasOwnProperty('sort') && col.sort.hasOwnProperty('isDefaultForSort') && col.sort.isDefaultForSort === true) {
                return col.sort.sortProperty;
            }
        }
        return this.columns[0].sort.sortProperty;
    }
}
