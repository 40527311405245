import { formatPhone, formatPhoneWithExt } from './phones/format-phones.library';
import { formatAddress, formatAddressVertical } from './addresses/format-addresses.library';
import { getFontAwesomeFileImage } from './misc/format-font-awesome.library';
import { formatPercent } from './numbers/format-numbers.library';

export const format = {
    /**
     * Takes an address object and converts it to a human friendly full address
     * @param addr the address object you would like to format
     */
    address: formatAddress,
    /**
     * Takes an address object and converts it to a human friendly full address
     * Note this does include html so it will need to be put on an innerHtml property.
     * @param addr the address object you would like to format
     */
    addressVertical: formatAddressVertical,
    /**
     * Takes in a file name and returns a font awesome icon for it.  It determines
     * this based on the ext.  This is primarily used when needing to flush a file
     * for example in a list
     * @param filename the file name to be evaluated.
     */
    getFontAwesomeFileImage: getFontAwesomeFileImage,
    /**
     * Handles formatting a javascript number to the properly rounded percentage value
     * If you just multiply it by 100 it will not handle floating points.  We use
     * @example
     * // returns 3.6999999999999997
     * 0.037 * 100
     * @example
     * // returns 3.7
     * formatPercent('0.037')
     * @param input string to be formatted to a percentage.  We use string to easily split on the decimal
     */
    percent: formatPercent,
    /**
     * Takes a string of an unformatted phone number and converts it
     * to a human friendly view
     * @param num the phone you would like to format.  Eg. 8565551234
     */
    phone: formatPhone,
    /**
     * Takes a string of an unformatted phone number and extension and converts it
     * to a human friendly view
     * @param num the phone you would like to format.  Eg. 8565551234
     * @param ext the ext you would like to attach to the phone
     */
    phoneWithExt: formatPhoneWithExt,
};
