import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, LOCALE_ID, Output } from '@angular/core';
import { DOCUMENT, formatNumber } from '@angular/common';

import { EntityListComponent } from './entity-list.component';

export interface IStickyHeaderConfig {
    top: number;
    background: string;
    color: string;
}

export interface IGetMoreConfig {
    bottomOfListOffset: string;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'virtual-scroll-entity-list',
    styles: [
        `
            table {
                border-collapse: separate; /* keeps border in place when sticky comes into play */
            }
            .info-bar th {
                font-weight: 400;
                padding-top: 1px;
                font-size: 0.85em;
                padding-bottom: 0;
                padding-left: 18px;
            }
            .scroll-to-top-btn {
                padding-left: 30px;
            }
        `,
    ],
    template: `
        <scroll-check (inViewHasChanged)="isScrolledDown = !$event" offset="0px"></scroll-check>
        <div class="table-responsive">
            <table
                class="table table-hover table-striped"
                [style.border-collapse]="makeHeaderSticky ? 'separate' : null"
                [ngClass]="entityListConfig.hasTableClass ? entityListConfig.tableClass : null"
            >
                <thead
                    [style.position]="makeHeaderSticky ? 'sticky' : null"
                    [style.top.px]="makeHeaderSticky && stickyHeaderConfig.top ? stickyHeaderConfig.top : null"
                    [style.background]="makeHeaderSticky && stickyHeaderConfig.background ? stickyHeaderConfig.background : null"
                    [style.color]="makeHeaderSticky && stickyHeaderConfig.color ? stickyHeaderConfig.color : null"
                >
                    <tr *ngIf="!headerTemplate && entityListConfig">
                        <th
                            *ngIf="selectHelper.showSelect(printMode)"
                            [style.width.px]="selectHelper.selectConfig.width"
                            (click)="selectAllClicked($event)"
                        >
                            <i
                                *ngIf="selectHelper.isMultiSelect()"
                                class="fa fa-lg fa-fw"
                                aria-hidden="true"
                                [class.fa-check-square-o]="selectHelper.isAllItemsSelected(entities)"
                                [class.fa-square-o]="!selectHelper.isAllItemsSelected(entities)"
                            ></i>
                        </th>
                        <ng-container *ngFor="let column of columns">
                            <th
                                *ngIf="column.showColumn(printMode)"
                                [style.width.px]="column.style ? column.style.width : null"
                                [ngStyle]="{
                                    cursor: column.sort.disableSort ? 'auto' : 'pointer'
                                }"
                                [ngClass]="column.hasColumnClass ? column.getColumnClass(null) : null"
                                (click)="columnSorted(column, $event)"
                            >
                                <b
                                    >{{ column.name }}
                                    <span *ngIf="column.sort && !column.sort.disableSort">
                                        <i
                                            [style.opacity]="column.sort.direction === sortEnum.Asc ? null : 0.4"
                                            class="fa fa-sort-asc"
                                            aria-hidden="true"
                                        ></i>
                                        <i
                                            [style.opacity]="column.sort.direction === sortEnum.Desc ? null : 0.4"
                                            style="margin-left: -12px"
                                            class="fa fa-sort-desc"
                                            aria-hidden="true"
                                        ></i>
                                    </span>
                                </b>
                            </th>
                        </ng-container>
                        <th *ngIf="showDelete()" [style.width.px]="entityListConfigDelete.width">
                            <b>{{ entityListConfigDelete.headerText }}</b>
                        </th>
                    </tr>
                    <tr *ngIf="headerTemplate" class="no-pointer">
                        <ng-template [ngTemplateOutlet]="headerTemplate"> </ng-template>
                    </tr>
                    <tr *ngIf="showInfoBar && total > 0" class="info-bar no-pointer">
                        <th colspan="100">
                            <span>{{ formattedTotal() }}</span>
                            <span *ngIf="isScrolledDown && stickyHeaderConfig" class="scroll-to-top-btn"
                                ><a (click)="scrollToTop()" href="javascript:void(0)"
                                    ><i class="fa fa-arrow-up" aria-hidden="true"></i> back to top</a
                                ></span
                            >
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="!itemTemplate && entityListConfig">
                    <tr
                        *ngFor="let entity of entities"
                        (click)="itemSelected(entity, $event)"
                        [ngClass]="entityListConfig.hasRowClass ? entityListConfig.getRowClass(entity) : null"
                    >
                        <td
                            *ngIf="selectHelper.showSelect(printMode)"
                            class="ellipsis"
                            [style.background-color]="selectHelper.isItemSelected(entity) ? selectHelper.selectConfig.selectedColor : null"
                            [style.width.px]="selectHelper.selectConfig.width"
                        >
                            <i
                                *ngIf="selectHelper.isSelectable(entity)"
                                class="fa fa-lg fa-fw"
                                aria-hidden="true"
                                [class.fa-check-square-o]="selectHelper.isItemSelected(entity)"
                                [class.fa-square-o]="!selectHelper.isItemSelected(entity)"
                            ></i>
                        </td>
                        <ng-container *ngFor="let column of columns">
                            <td
                                *ngIf="column.showColumn(printMode) && !column.component"
                                (click)="column.fireOnColumnSelected ? columnSelected(entity, column, $event) : null"
                                class="ellipsis"
                                [style.background-color]="selectHelper.isItemSelected(entity) ? selectHelper.selectConfig.selectedColor : null"
                                [style.width.px]="column.style ? column.style.width : null"
                                [ngClass]="column.hasColumnClass ? column.getColumnClass(entity) : null"
                            >
                                <div *ngIf="column.bindToInnerHtml" [innerHTML]="column.getItemValue(entity, entityListPipeService)"></div>
                                <a *ngIf="column.linkFunction" (click)="handleLinkFunction(entity, column, $event)" href="javascript:void(0);">{{
                                    column.getItemValue(entity, entityListPipeService)
                                }}</a>
                                <div *ngIf="!column.bindToInnerHtml && !column.linkFunction">
                                    {{ column.getItemValue(entity, entityListPipeService) }}
                                </div>
                            </td>
                            <td
                                *ngIf="column.showColumn(printMode) && column.component"
                                [style.background-color]="selectHelper.isItemSelected(entity) ? selectHelper.selectConfig.selectedColor : null"
                                [style.width.px]="column.style ? column.style.width : null"
                                [ngClass]="column.hasColumnClass ? column.getColumnClass(entity) : null"
                            >
                                <ng-container
                                    dynamicCell
                                    [componentRef]="column.component"
                                    [item]="entity"
                                    [entityListComponentMembers]="entityListComponentMembers"
                                >
                                </ng-container>
                            </td>
                        </ng-container>
                        <td
                            *ngIf="showDelete() && entityListConfigDelete.confirm && !entityListConfigDelete.component"
                            class="ellipsis"
                            [style.background-color]="selectHelper.isItemSelected(entity) ? selectHelper.selectConfig.selectedColor : null"
                            [style.width.px]="entityListConfigDelete.width"
                            (mtConfirm)="itemDeleted(entity, $event)"
                            [mtConfirmOptions]="entityListConfigDelete.getConfirm(entity)"
                            [innerHtml]="entityListConfigDelete.getColumnHtml(entity)"
                        ></td>
                        <td
                            *ngIf="showDelete() && !entityListConfigDelete.confirm && !entityListConfigDelete.component"
                            class="ellipsis"
                            [style.background-color]="selectHelper.isItemSelected(entity) ? selectHelper.selectConfig.selectedColor : null"
                            [style.width.px]="entityListConfigDelete.width"
                            (click)="itemDeleted(entity, $event)"
                            [innerHtml]="entityListConfigDelete.getColumnHtml(entity)"
                        ></td>
                        <td
                            *ngIf="showDelete() && entityListConfigDelete.component"
                            class="ellipsis"
                            [style.background-color]="selectHelper.isItemSelected(entity) ? selectHelper.selectConfig.selectedColor : null"
                            [style.width.px]="entityListConfigDelete.width"
                        >
                            <ng-container
                                dynamicCell
                                [componentRef]="entityListConfigDelete.component"
                                [item]="entity"
                                [entityListComponentMembers]="entityListComponentMembers"
                            >
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="itemTemplate">
                    <ng-container *ngFor="let entity of entities">
                        <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: entity }"> </ng-template>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div *ngIf="noEntities()">
            <h3 style="text-align: center;">{{ emptyMessage }}</h3>
        </div>
        <scroll-check (inViewHasChanged)="bottomOfListInViewChanged($event)" [offset]="getMoreConfig.bottomOfListOffset"></scroll-check>
    `,
})
export class VirtualScrollEntityListComponent extends EntityListComponent {
    @Input('entities')
    set entities(value: any[]) {
        this._entities = value;
        setTimeout(() => {
            this.handleGetMoreCheck();
        }, 200);
    }
    get entities(): any[] {
        if (!this._entities) {
            this._entities = [];
        }
        return this._entities;
    }

    @Input() showInfoBar = true;
    @Input() totalLabelSuffix = ' total';

    @Input() makeHeaderSticky = true;
    protected defaultStickyHeaderConfig: IStickyHeaderConfig = { top: 50, background: '#ccc', color: null };
    protected _stickyHeaderConfig: IStickyHeaderConfig = this.defaultStickyHeaderConfig;
    @Input()
    set stickyHeaderConfig(value: Partial<IStickyHeaderConfig>) {
        this._stickyHeaderConfig = Object.assign({}, this.defaultStickyHeaderConfig, value);
    }
    get stickyHeaderConfig(): Partial<IStickyHeaderConfig> {
        return this._stickyHeaderConfig;
    }

    protected defaultGetMoreConfig: IGetMoreConfig = { bottomOfListOffset: '100px' };
    protected _getMoreConfig: IGetMoreConfig = this.defaultGetMoreConfig;
    @Input()
    set getMoreConfig(value: Partial<IGetMoreConfig>) {
        this._getMoreConfig = Object.assign({}, this.defaultGetMoreConfig, value);
    }
    get getMoreConfig(): Partial<IGetMoreConfig> {
        return this._getMoreConfig;
    }

    @Output() getMore = new EventEmitter();

    public bottomOfListInView = false;
    public isScrolledDown = false;

    protected document: any;
    protected LocaleId: string;

    constructor(injector: Injector) {
        super(injector);
        this.document = injector.get(DOCUMENT);
        this.LocaleId = injector.get(LOCALE_ID);
    }

    formattedTotal(): string {
        if (this.total > 0) {
            return `${formatNumber(this.total, this.LocaleId)}${this.totalLabelSuffix}`;
        }
        return '';
    }

    bottomOfListInViewChanged(inView: boolean): void {
        this.bottomOfListInView = inView;
        this.handleGetMoreCheck();
    }

    handleGetMoreCheck(): void {
        if (this.bottomOfListInView) {
            const moreToGet = (this.total ?? 0) > (this.entities?.length ?? 0);
            if (moreToGet) {
                this.getMore.emit();
            }
        }
    }

    scrollToTop(): void {
        this.document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
}
