import {
    Component,
    Output,
    EventEmitter,
    OnDestroy,
    AfterViewInit,
    OnInit,
    ViewChild,
    ElementRef,
    ChangeDetectorRef,
    Injector,
    Inject,
} from '@angular/core';
import { IFormElementEvents } from '../form-elements';
import { Subscription } from 'rxjs';
import { FormElementsBase } from '../form-elements.base';

@Component({
    selector: 'app-form-password',
    templateUrl: './form-password.component.html',
})
export class FormPasswordComponent extends FormElementsBase implements OnInit, AfterViewInit, OnDestroy, IFormElementEvents {
    isFocused = false;

    @Output() blur: EventEmitter<any> = new EventEmitter<any>();
    @Output() focus: EventEmitter<any> = new EventEmitter<any>();
    @Output() valueChanges: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('input', { static: false }) inputElement: ElementRef;

    subscriptions = new Subscription();
    autoFocus: boolean;

    private changeDetectorRef: ChangeDetectorRef;

    constructor(@Inject(Injector) changeDetectorRefOrInjector: ChangeDetectorRef | Injector) {
        super(changeDetectorRefOrInjector);
        if ((changeDetectorRefOrInjector as ChangeDetectorRef).detectChanges) {
            this.changeDetectorRef = changeDetectorRefOrInjector as ChangeDetectorRef;
        } else {
            this.changeDetectorRef = (changeDetectorRefOrInjector as Injector).get(ChangeDetectorRef);
        }
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.getControl().mtExternalFocusEvent.subscribe(() => {
                this.focusMe();
            }),
        );
        this.subscriptions.add(
            this.getControl().mtSetRequiredEvent.subscribe((required: boolean) => {
                this.setRequired(required);
            }),
        );
        this.autoFocus = this.config.autoFocus;
    }

    ngAfterViewInit(): void {
        this.subscriptions.add(
            this.getControl().valueChanges.subscribe((value) => {
                this.valueChanges.emit(value);
            }),
        );
        if (this.autoFocus) {
            this.autoFocus = false;
            setTimeout(() => {
                this.focusMe();
                this.changeDetectorRef.detectChanges();
            });
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    focusMe(): void {
        this.inputElement.nativeElement.focus();
    }

    hasRegexError(): boolean {
        const control = this.getControl();
        if (control.errors && control.touched && control.errors.pattern) {
            control.setErrors({ pattern: true });
            return true;
        }
        return false;
    }

    onBlur(): void {
        this.isFocused = false;
        this.blur.emit();
    }

    onFocus(): void {
        this.isFocused = true;
        this.focus.emit();
    }
}
