import { EntityListConfig, EntityListColumn, SortDirection } from '@mt-ng2/entity-list-module';
import { getFontAwesomeFileImage } from '@mt-ng2/format-functions';
import { IDocument } from '../common-documents.component';

export class DocumentsEntityListConfig extends EntityListConfig {

    constructor() {
        super({
            columns: [
                new EntityListColumn({
                    accessorFunction: function (document: IDocument): string {
                        return `<span class="fa fa-fw ${getFontAwesomeFileImage(document.Name)}"></span><span>${document.Name}</span>`;
                    },
                    bindToInnerHtml: true,
                    fireOnColumnSelected: true,
                    name: 'File',
                    sort: {
                        sortProperty: 'Name',
                    },
                }),
                new EntityListColumn({
                    accessors: ['DateUpload'],
                    name: 'Date Uploaded',
                    pipes: [`date:'medium'`],
                    sort: {
                        defaultDirection: SortDirection.Desc,
                        isDefaultForSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['User', 'AuthUser', 'Username'],
                    name: 'Uploaded By',
                }),
            ],
            delete: {
                confirm: true,
                width: 50,
            },
        });
    }
}
