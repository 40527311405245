export interface ILabelPosition {
    position: LabelPositions;
    colsForLabel?: number;
}

export class LabelPosition implements ILabelPosition {
    public position: LabelPositions;
    public colsForLabel: number;

    constructor(labelPosition: ILabelPosition) {
        this.position = labelPosition.position;
        this.colsForLabel = labelPosition.colsForLabel || 3;
    }
}

export enum LabelPositions {
    Top,
    Left,
    Hidden,
}
