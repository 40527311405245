import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[mtNumbersOnly]',
})
export class MtNumbersOnlyDirective {
    // Allow decimal numbers. The \. is only allowed once to occur
    private regex: RegExp = new RegExp(/-?\d*\.?\d*$/g);
    // Allow key codes for special events.
    private specialKeys: string[] = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];

    constructor(private el: ElementRef) {}

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        // get the current value
        let current: string = this.el.nativeElement.value;

        if (event.key === '-') {
            // negative sign character is handled differently
            if (current.indexOf('-') !== -1) {
                // already has a negative sign, so prevent and return
                event.preventDefault();
                return;
            } else if (this.el.nativeElement.validity?.badInput) {
                // if currently bad input, then we already have a negative sign
                // we just don't see it in the current value because of badInput
                event.preventDefault();
                return;
            } else if (current) {
                // we have a valid current value that is not empty and
                // does not already have a negative sign so
                // prevent default and prepend to beginning of string
                event.preventDefault();
                this.el.nativeElement.value = '-' + current;
            } else {
                // let it through
                return;
            }
        } else {
            // everything else handled like the following
            // ------------------------------------------
            // add the key pressed to that value
            // note: this is not done correctly, since we don't know where the cursor
            // was when this key was pressed, yet via concat we are just putting it on
            // the end
            let next: string = current.concat(event.key);
            // if not valid, then prevent it
            if (next && !String(next).match(this.regex)) {
                event.preventDefault();
            }
        }
    }
}
