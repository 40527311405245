import { Injectable } from '@angular/core';
import { SweetAlert2LoaderService } from '@sweetalert2/ngx-sweetalert2';
import { from, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { IModalOptions, IModalResult, ModalOptions } from '../libraries/modal.library';

@Injectable({ providedIn: 'root' })
export class ModalService {
    constructor(private readonly sweetAlert2Loader: SweetAlert2LoaderService) {}

    showModal(options: IModalOptions = {}): Observable<IModalResult> {
        options = new ModalOptions(options);
        return from(this.sweetAlert2Loader.swal).pipe(concatMap((swal) => from(swal.fire(options))));
    }
}
