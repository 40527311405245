import { InjectionToken } from '@angular/core';

export enum MatchingStrategy {
    Contains,
    StartsWith,
    EndsWith,
}

export interface ITypeAheadConfig {
    matchingStrategy?: MatchingStrategy;
}

export const TypeAheadModuleConfigToken = new InjectionToken<ITypeAheadConfig>('type_ahead_module_config');
