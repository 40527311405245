import { ContainsMatcher } from './ContainsMatcher';
import { StartsWithMatcher } from './StartsWithMatcher';
import { EndsWithMatcher } from './EndsWithMatcher';
import { ComponentErrorHandler } from '@mt-ng2/component-error-handler';
import { MatchingStrategy } from '@mt-ng2/type-ahead-control-config';

export interface IMatchingStrategy {
    // TODO: JJG
    // I don't like how we are passing in the errorHandler here.
    // Wish we could do this a better way, maybe bubble out the
    // error or make the event an observable.
    getItems(items: any[], searchText: string, errorHandler: ComponentErrorHandler, nameProperty?: string): any[];
    getCurrentStrategy(): MatchingStrategy;
    getHighlightedParts(resultStr: string, termLC: string, currentIdx?: number): string[];
}

export class MatcherFactory {
    createMatchingStrategy(strategy: MatchingStrategy): IMatchingStrategy {
        switch (strategy) {
            case MatchingStrategy.Contains:
                return new ContainsMatcher(strategy);
            case MatchingStrategy.StartsWith:
                return new StartsWithMatcher(strategy);
            case MatchingStrategy.EndsWith:
                return new EndsWithMatcher(strategy);
            default:
                return new ContainsMatcher(MatchingStrategy.Contains);
        }
    }
}
