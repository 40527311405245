import { IMatchingStrategy } from './MatcherFactory';

import { getValue, regExpEscape } from '../type-ahead.library';
import { ComponentErrorHandler } from '@mt-ng2/component-error-handler';
import { MatchingStrategy } from '@mt-ng2/type-ahead-control-config';

export class ContainsMatcher implements IMatchingStrategy {
    private _strategy: MatchingStrategy;

    constructor(strategy: MatchingStrategy) {
        this._strategy = strategy;
    }

    getCurrentStrategy(): MatchingStrategy {
        return this._strategy;
    }
    getHighlightedParts(resultStr: string, termLC: string, currentIdx: number): string[] {
        const resultLC = resultStr.toLowerCase();
        return resultLC.split(new RegExp(`(${regExpEscape(termLC)})`)).map((part) => {
            const originalPart = resultStr.substr(currentIdx, part.length);
            currentIdx += part.length;
            return originalPart;
        });
    }

    getItems(items: any[], searchText: string, errorHandler: ComponentErrorHandler, nameProperty = ''): any[] {
        return items.filter((item) => {
            if (!getValue(item, nameProperty, errorHandler)) {
                return false;
            }
            if (!searchText) {
                return true;
            }

            return getValue(item, nameProperty, errorHandler).toLowerCase().includes(searchText.toLowerCase());
        });
    }
}
