import { Component, OnInit, OnDestroy, Output, EventEmitter, Injector } from '@angular/core';

import { Subscription } from 'rxjs';

import { PrintModeService } from '../services/print-mode.service';

@Component({
    selector: 'entity-list-print',
    styles: [
        `
            @media print {
                @page {
                    size: landscape;
                }
            }
            .print-button .btn {
                border-radius: 3px 3px 0 0;
                background-color: #ccc;
                border: 0;
            }
            .close-print-button .btn {
                position: fixed;
                top: 25px;
                right: 25px;
            }
        `,
    ],
    template: `
        <div *ngIf="printMode" class="close-print-button no-print">
            <button type="button" class="btn btn-default btn-flat" (click)="togglePrintMode()">Close Print View</button>
        </div>
        <span *ngIf="!printMode" class="print-button">
            <button type="button" class="btn btn-default btn-xs btn-flat" (click)="togglePrintMode()"><i class="fa fa-xs fa-print"></i> Print</button>
        </span>
    `,
})
export class EntityListPrintComponent implements OnInit, OnDestroy {
    private _printMode = false;
    set printMode(value: boolean) {
        this._printMode = value;
        this.printModeChange.emit(value);
    }
    get printMode(): boolean {
        return this._printMode;
    }
    @Output() printModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    subscriptions = new Subscription();

    protected printModeService: PrintModeService;

    constructor(injector: Injector) {
        this.printModeService = injector.get(PrintModeService);
    }

    ngOnInit(): void {
        this.subscriptions.add(this.printModeService.printMode.subscribe((printMode) => (this.printMode = printMode)));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.printModeService.closePrintMode();
    }

    togglePrintMode(): void {
        this.printModeService.togglePrintMode();
    }
}
