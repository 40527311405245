import { Component, Output, EventEmitter, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, Injector } from '@angular/core';
import { IFormElementEvents } from '../form-elements';
import { Subscription } from 'rxjs';
import { FormElementsBase } from '../form-elements.base';

@Component({
    selector: 'app-form-checkbox',
    styles: [
        `
            .form-horizontal {
                min-height: 20px;
                margin-top: -3px;
            }
        `,
    ],
    templateUrl: './form-checkbox.component.html',
})
export class FormCheckBoxComponent extends FormElementsBase implements OnInit, AfterViewInit, OnDestroy, IFormElementEvents {
    isFocused = false;

    subscriptions = new Subscription();

    @Output() blur: EventEmitter<any> = new EventEmitter<any>();
    @Output() focus: EventEmitter<any> = new EventEmitter<any>();
    @Output() valueChanges: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('input', { static: false }) inputElement: ElementRef;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.getControl().mtExternalFocusEvent.subscribe(() => {
                this.focusMe();
            }),
        );
        this.subscriptions.add(
            this.getControl().mtSetRequiredEvent.subscribe((required: boolean) => {
                this.setRequired(required);
            }),
        );
    }

    ngAfterViewInit(): void {
        this.subscriptions.add(
            this.getControl().valueChanges.subscribe((value) => {
                this.valueChanges.emit(value);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    focusMe(): void {
        this.inputElement.nativeElement.focus();
    }

    onBlur(): void {
        this.isFocused = false;
        this.blur.emit();
    }

    onFocus(): void {
        this.isFocused = true;
        this.focus.emit();
    }

    labelClicked(): void {
        let control = this.getControl();
        if (control.enabled) {
            control.patchValue(control.value === true ? false : true);
            control.markAsDirty();
            control.markAsTouched();
        }
    }
}
