import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class DatepickerDateOptionItem implements NgbDateStruct {
    year: number;
    month: number;
    day: number;

    constructor(date: Date) {
        Object.assign(this, this.getDatePickerOptions(date));
    }

    /**
     * This method takes in a date object and formats it into the format
     * ngbootstrap expects it to be {year: xxxx, month: xx, day: xx}
     * @param date
     */
    getDatePickerOptions(date: Date): NgbDateStruct {
        return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
    }
}
