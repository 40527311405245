import { ComponentErrorHandler } from '@mt-ng2/component-error-handler';

import { DynamicField } from './dynamic-field.library';
import { DynamicLabel } from './dynamic-label.library';
import { SelectInputTypes } from '../form-elements/form-elements.library';
import { IDynamicField } from './interfaces/dynamic-field';
import { DynamicFieldTypes } from './interfaces/dynamic-field-type';
import { IDynamicLabel } from './interfaces/dynamic-label';
import { IDynamicControlsFactory } from './interfaces/dynamic-form-config';

/**
 * This class exposes functions that when given a configuration
 * will render our dynamic forms
 */
export class DynamicConfig<T> {
    DynamicFields: IDynamicField[] = [];
    DynamicLabels: IDynamicLabel[] = [];
    errorHandler = new ComponentErrorHandler('DynamicConfig', '@mt-ng2/dynamic-form');

    setControls(fields: string[], controlsFactory: IDynamicControlsFactory): void {
        fields.forEach((field) => {
            if (this.propertyExistsInControlsFactory(controlsFactory, 'Form', field)) {
                this.DynamicFields.push(controlsFactory.Form[field]);
            }
            if (this.propertyExistsInControlsFactory(controlsFactory, 'View', field)) {
                this.DynamicLabels.push(controlsFactory.View[field]);
            }
        });
    }

    getFormObject(additionalConfigs?: any[]): IDynamicField[] {
        if (additionalConfigs?.length > 0) {
            additionalConfigs.forEach((config) => {
                config.getForCreate().formObject.forEach((formObject: DynamicField) => {
                    // if the object is undefinded then it was probably passed a configuration control
                    // that it couldn't render.
                    // EG: if (id === 0) render SendRestEmail
                    // configuration has 'SendRestEmail' but id > 0
                    // In this scenario I'm just trying to help them understand what could be wrong.
                    // I could just ignore this, but it might have unintended consequences.

                    if (!formObject) {
                        throw 'Form Object is not defined.  Is your custom configuration correct?';
                    }

                    // push the form object
                    this.DynamicFields.push(formObject);

                    let displayValue: string | number;
                    // We want to display the name in the lable for selects not the value
                    if (formObject.type.fieldType === DynamicFieldTypes.Select) {
                        // just incase
                        if (formObject.type.inputType === SelectInputTypes.MultiselectDropdown) {
                            if (formObject.value && (<number[]>formObject.value).length > 0) {
                                const selectValues: string[] = formObject.options
                                    .filter((opt) => {
                                        (<number[]>formObject.value).some((v) => v === opt.Id);
                                    })
                                    .map((opt) => opt.Name);
                                displayValue = selectValues ? selectValues.join(', ') : '';
                            } else {
                                displayValue = '';
                            }
                        } else {
                            const selectValue = formObject.options.find((opt) => opt.Id === formObject.value);
                            displayValue = selectValue ? selectValue.Name : '';
                        }
                    } else {
                        displayValue = <string>formObject.value;
                    }
                    // push the label object
                    this.DynamicLabels.push(
                        new DynamicLabel({
                            label: formObject.label,
                            type: formObject.type,
                            value: displayValue,
                        }),
                    );
                });
            });
        }
        return this.DynamicFields;
    }

    assignFormValues(object: T, formValue: T): void {
        for (let prop in formValue) {
            if (object.hasOwnProperty(prop)) {
                object[prop] = formValue[prop];
            }
        }
    }

    private propertyExistsInControlsFactory(factory: IDynamicControlsFactory, groupAccessor: string, property: string): any {
        if (factory[groupAccessor][property] !== undefined) {
            return true;
        } else {
            this.errorHandler.addWarning(`could not find "${property}" in controlsFactory.${groupAccessor} for fromGroup "${factory.formGroup}"`);
            return false;
        }
    }
}

export class DynamicControls {
    Form: any;
    View: any;

    setShowOptional(value: boolean): void {
        if (this.Form) {
            for (const key in this.Form) {
                if (this.Form.hasOwnProperty(key)) {
                    let control = <DynamicField>this.Form[key];
                    control.validators.showOptional = value;
                    control.validators.showRequired = !value;
                }
            }
        }
    }
}
