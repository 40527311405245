import { InjectionToken } from '@angular/core';
import { KeyboardShortcutCombination, KeyboardKeys } from 'ngx-keyboard-shortcuts';

/**
 * Config object for the KeyboardShortcutsModule
 * @property {KeyboardShortcutCombination} helpKeyBinding keyboard combination that shows the keyboard shortcuts window
 */
export interface IKeyboardShortcutsModuleConfig {
    helpKeyBinding: KeyboardShortcutCombination;
}

export const defaultModuleConfig: IKeyboardShortcutsModuleConfig = {
    helpKeyBinding: [KeyboardKeys.Alt, KeyboardKeys.Shift, 'h'],
};

/**
 * Token used for providing the IKeyboardShortcutsModuleConfig
 * @example
 * // in the shared module's providers section
 * providers: [
 *      { provide: KeyboardShortcutsModuleConfigToken, useValue: keyboardShortcutsModuleConfig },
 * ]
 */
export const KeyboardShortcutsModuleConfigToken = new InjectionToken<IKeyboardShortcutsModuleConfig>('keyboard_shortcuts_module_config');
