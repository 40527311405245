import { IEntityListConfig, EntityListConfig, IEntityListColumn, SortDirection, EntityListColumn } from '@mt-ng2/entity-list-module';
import { EntityListDeleteColumn } from '@mt-ng2/entity-list-module';
import { IAddressContainer, IAddress } from '@mt-ng2/dynamic-form';

export class AddressesEntityListConfig extends EntityListConfig {

    constructor(allowInternationalAddresses = false,
    disableSort?: boolean) {
        const standardConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['Address', 'Address1'],
                    name: 'Address 1',
                    sort: {
                        disableSort: disableSort,
                    },
                }),
                new EntityListColumn({
                    accessors: ['Address', 'Address2'],
                    name: 'Address 2',
                    sort: {
                        disableSort: disableSort,
                    },
                }),
                new EntityListColumn({
                    accessors: ['Address', 'City'],
                    name: 'City',
                    sort: {
                        disableSort: disableSort,
                    },
                }),
                new EntityListColumn({
                    accessors: ['Address', 'StateCode'],
                    name: 'State',
                    sort: {
                        disableSort: disableSort,
                    },
                }),
                new EntityListColumn({
                    accessors: ['Address', 'Zip'],
                    name: 'Zip',
                    sort: {
                        disableSort: disableSort,
                    },
                }),
            ],
        };

        const internationalConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['Address', 'Address1'],
                    name: 'Address 1',
                    sort: {
                        disableSort: disableSort,
                    },
                }),
                new EntityListColumn({
                    accessors: ['Address', 'Address2'],
                    name: 'Address 2',
                    sort: {
                        disableSort: disableSort,
                    },
                }),
                new EntityListColumn({
                    accessors: ['Address', 'City'],
                    name: 'City',
                    sort: {
                        disableSort: disableSort,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (addressContainer: IAddressContainer): string {
                        let address = addressContainer.Address;
                        let isInternational = address.CountryCode !== 'US';
                        return isInternational ? address.Province : address.StateCode;
                    },
                    name: 'State/Province',
                    sort: {
                        disableSort: disableSort,
                        sortProperty: 'Address.StateCode',
                    },
                }),
                new EntityListColumn({
                    accessors: ['Address', 'Zip'],
                    name: 'Zip',
                    sort: {
                        disableSort: disableSort,
                    },
                }),
                new EntityListColumn({
                    accessors: ['Address', 'Country', 'Name'],
                    name: 'Country',
                    sort: {
                        disableSort: disableSort,
                        sortProperty: 'Address.CountryCode',
                    },
                }),
            ],
        };
        super(allowInternationalAddresses ? internationalConfig : standardConfig);
    }

}
