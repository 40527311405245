import { InjectionToken } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

/**
 * An override function that is evaluated first when determining which dynamic
 * component to load for a DynamicField.  If the function returns void || null
 * then the dynamic form module will determine which component to load.
 * Otherwise, whatever the function returns will be used as the component.
 * @example
 * export function overrideDynamicFormComponent(dynamicField: DynamicField): any {
 *     // check for any fields that are numeric and use my custom numeric component instead
 *     if (dynamicField.type.fieldType === DynamicFieldTypes.Numeric) {
 *         return MySuperCoolNumericComponent;
 *     }
 *     // else just return and let the dynamic forms module decide which component to use
 *     return;
 * }
 */
export type CustomFormComponentFunction = (dynamicField: any) => any;

/**
 * A function that is used to determine the display value of a custom form component.
 * When overriding the component with {@link CustomFormComponentFunction} you may
 * need to provide a custom formatter for the view.  Example if you override a datetimepicker
 * to a dateinput you need to tell it not to convert to local time or it will subtract hours
 * and display 1 day in the past
 */
export type CustomFormComponentDisplayValueFunction = (dynamicLabel: any) => any;

/**
 * An override function that is evaluated first when determining the error message to be
 * used as a validation message for a DynamicField.  Return of null or empty string will
 * result in the logic continuing to default messaging.
 * @example
 * export function overrideErrorMessage(key: string, errors: ValidationErrors, dynamicField: DynamicField): string {
 *     if (key === 'noZeroRequired') {
 *         return `${dynamicField.label} is required and zero is not allowed.`
 *     } else {
 *         return null; // fall through to default messaging
 *     }
 * }
 * @param key the key string in the errors object, usually the name of the validator that raised the error
 * @param errors errors object containing all errors from the control
 * @param dynamicField the DynamicField config for the control the error is coming from
 */
export type CustomErrorMessageHandler = (key: string, errors: ValidationErrors, dynamicField: any) => string;

/**
 * Interface representing the customizable properties
 * of the dynamic form module.
 * @example
 * const dynamicFormModuleConfig: IDynamicFormModuleConfig = {
 *     commonService: CommonService,
 * };
 * @property {any} commonService - CommonService injector token. CommonService must have the
 * following methods.
 * @example
 * export class CommonService {
 *     getStates(): Observable<IState[]>;
 *     getCountries(): Observable<ICountry[]>;
 * }
 * @property {CustomFormComponentFunction} customFormComponentFunction - function that will be
 * evaluated first when determining which dynamic form component to use when displaying a
 * dynamic field.
 * @property {CustomFormComponentDisplayValueFunction} customFormComponentDisplayValueFunction -
 * function that will be evaluated first when determining the display value of a dynamic label.
 * @property {boolean} markAsTouchedOnFocus - mark as touched should
 * happen on blur, but our old dynamic forms did it on focus.  This option puts it back to the
 * focus event.
 */
export interface IDynamicFormModuleConfig {
    commonService: any;
    /**
     * function that will be evaluated first when determining which dynamic form component to
     * use when displaying a dynamic field.
     */
    customFormComponentFunction?: CustomFormComponentFunction;
    /**
     * function that will be evaluated first when determining the display value of a dynamic label.
     */
    customFormComponentDisplayValueFunction?: CustomFormComponentDisplayValueFunction;
    /**
     * mark as touched should happen on blur, but our old dynamic forms did it on focus.  This
     * option puts it back to the focus event.
     */
    markAsTouchedOnFocus?: boolean;
    /**
     * default is for date picker to not show the clear button.  This option allows the clear button
     * to show as the default.
     */
    datePickerShowClearButton?: boolean;
    /**
     * function that will be evaluated first when determining the error message to show in a validation
     * message of the form control.
     */
    errorMessageHandler?: CustomErrorMessageHandler;
    /**
     * default is for time picker to not show the clear button.  This option allows the clear button
     * to show as the default.  Note that each individual option needs to be set to show the buttons for
     * the DateTimePicker.
     */
    timePickerShowClearButton?: boolean;
}

/**
 * Token used for providing the IDynamicFormModuleConfig
 * @example
 * // in the app or shared modules providers section
 * providers: [
 *     { provide: DynamicFormModuleConfigToken, useValue: dynamicFormModuleConfig },
 * ],
 */
export const DynamicFormModuleConfigToken = new InjectionToken<IDynamicFormModuleConfig>('dynamic_form_module_config');
