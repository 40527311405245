import { formatNumber } from '@angular/common';

export enum NumericControlTypes {
    Integer = 1,
    Float = 2,
    Currency = 3,
    Percentage = 4,
}

/**
 * Interface for the NumericControlComponent API.
 * @property {Function} focus focuses the control
 */
export interface INumericControlComponentApi {
    focus: () => void;
}

/**
 * rounds a number.
 * @param {number} n the number to round
 * @param {number} decimals the precision to round to
 * @return {number} the result
 */
export function round(n: number, decimals: number): number {
    return Number(Math.round(Number(n + 'e' + decimals)) + 'e-' + decimals);
}

/**
 * used to convert a number either from its display value to its form value or from its form value
 * to its display value
 */
export type ConvertNumberFunction = (value: number, type: NumericControlTypes, scale: number) => number;

/**
 * class used to get default values for ConvertNumberFunctions
 */
export class DefaultConversionFunctions {
    static fromDisplayToForm(type: NumericControlTypes): ConvertNumberFunction {
        if (type === NumericControlTypes.Percentage) {
            return (value: number, type: NumericControlTypes, scale: number) => {
                return value ? round(value / 100, scale + 2) : null;
            };
        }
    }
    static fromFormToDisplay(type: NumericControlTypes): ConvertNumberFunction {
        if (type === NumericControlTypes.Percentage) {
            return (value: number, type: NumericControlTypes, scale: number) => {
                return value ? round(value * 100, scale) : null;
            };
        }
    }
}

/**
 * used to display the string version of a number
 */
export type NumberFormatDisplayFunction = (value: number, type: NumericControlTypes, scale: number) => string;

export function getDefaultNumberFormatDisplayFunction(): NumberFormatDisplayFunction {
    return (value: number, type: NumericControlTypes, scale: number) => {
        return formatNumber(value, 'en-us', `${1}.${scale}-${scale}`);
    };
}
