import { EntityListConfig } from './entity-list.library';
import { EntityListColumn } from './entity-list-column.library';

import { EntityListPipeService } from '../services/entity-list-pipe.service';

export function buildDataForExport(data: any[], entityListConfig: EntityListConfig, entityListPipeService: EntityListPipeService): any[] {
    const columnsForExport = getColumnsForExport(entityListConfig);
    const dataToExport = getDataToExportMissingHeader(data, columnsForExport, entityListPipeService);
    return dataToExport;
}

function getColumnsForExport(entityListConfig: EntityListConfig): EntityListColumn[] {
    return entityListConfig.columns
        .filter((column) => !column.excludeFromExport)
        .map((column) => {
            return column instanceof EntityListColumn ? column : new EntityListColumn(column);
        });
}

function getDataToExportMissingHeader(data: any[], columnsForExport: EntityListColumn[], entityListPipeService: EntityListPipeService): any[] {
    const dataToExport = data.map((item) => {
        const exportItem: any = {};
        columnsForExport.forEach((column) => {
            exportItem[column.name] = column.getItemValue(item, entityListPipeService);
        });
        return exportItem;
    });
    return dataToExport;
}
