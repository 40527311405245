// doing this in a separate file rather than in the app.module.ts file
// avoids circular dependency warnings
import { Injector } from '@angular/core';

/**
 * the Angular injector available anywhere.  Gets loaded in the constructor of this module.
 */
export let globalInjector: Injector;

/**
 * Set the globalInjector.  This should be called in the constructor of this module.
 * @param {Injector} injector
 */
export function setGlobalInjector(injector: Injector): void {
    if (!globalInjector) {
        globalInjector = injector;
    }
}
