import { IMetaItemListDefinition, IMetaItem } from './meta-item';
import { HttpClient } from '@angular/common/http';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { ExtraSearchParams } from '@mt-ng2/common-classes';
import { Observable, of, iif, Subject, ReplaySubject } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';
import { BaseService, IEntity } from './base.service';
import { deprecate } from 'util';

export class MetaItemService<T extends IEntity> extends BaseService<T> {
    _itemsSubject: Subject<T[]> = new Subject<T[]>();
    _callinProgress = false;

    /**
     * @deprecated Do not use the items property from the MetaItemService. If preserving items, use the StaticMetaItemService instead.
     */
    items: T[] = [];

    MetaItemListServiceInitilizer = {
        getItems: (): Observable<MetaItemListDefinition> => {
            return this.getItems().pipe(
                map((items) => {
                    let answer: MetaItemListDefinition = new MetaItemListDefinition(
                        items.map((item) => new MtSearchFilterItem(<any>item, false)),
                        items.map((item) => new MetaItem(item.Id, (<any>item).Name)),
                        this.objectName,
                        this.parameterName,
                        this.serviceName,
                    );
                    return answer;
                }),
            );
        },
    };

    constructor(public serviceName: string, public objectName: string, public parameterName: string, private metabaseurl: string, protected http: HttpClient) {
        super(metabaseurl, http);
    }

    updateItems(items: T[]): Observable<object> {
        return this.http.put(`${this.metabaseurl}/update`, items);
    }

    getItems(): Observable<T[]> {
        return iif(
            this.returnItemsAsObservable.bind(this),
            this.getSubject()
                .asObservable()
                .pipe(take(1)),
            this.getSubject()
                .asObservable()
                .pipe(take(1)),
        );
    }

    getSearchFilterItems(): Observable<MtSearchFilterItem[]> {
        return this.getItems().pipe(
            map((items) => {
                return items.map((item) => new MtSearchFilterItem(<any>item, false));
            }),
        );
    }

    getSubject(): Subject<T[]> {
        if (!this._itemsSubject) {
            this._itemsSubject = new Subject<T[]>();
        }
        return this._itemsSubject;
    }

    private returnItemsAsObservable(): boolean {
        if (!this._callinProgress) {
            this._callinProgress = true;
            this.getAll().subscribe((items) => {
                this._itemsSubject.next(items);
                this._callinProgress = false;
            });
        }

        return false;
    }
}

export class StaticMetaItemService<T extends IEntity> extends MetaItemService<T> {
    _itemsStored = false;
    items: T[] = [];

    constructor(public serviceName: string, public objectName: string, public parameterName: string, private staticmetabaseurl: string, protected http: HttpClient) {
        super(serviceName, objectName, parameterName, staticmetabaseurl, http);
        this.items = null;
    }

    getItems(): Observable<T[]> {
        return iif(
            this.returnItemsAsObservableStatic.bind(this),
            of(this.items),
            this.getSubject()
                .asObservable()
                .pipe(take(1)),
        );
    }

    private returnItemsAsObservableStatic(): boolean {
        if (this.items) {
            return true;
        }

        if (!this._callinProgress) {
            this._callinProgress = true;
            this.getAll().subscribe((items) => {
                this.items = items;
                this._itemsSubject.next(items);
                this._callinProgress = false;
            });
        }

        return false;
    }
}

export class MetaItemListDefinition implements IMetaItemListDefinition {
    constructor(public FilterItems: MtSearchFilterItem[], public Items: MetaItem[], public Name: string, public ParameterName: string, public ServiceName: string) {}

    private getSelectedFilterItems(filterItems: MtSearchFilterItem[]): number[] {
        return filterItems.filter((item) => item.Selected).map((item) => <number>item.Item.Id);
    }

    toExtraSearchParams(): ExtraSearchParams {
        return new ExtraSearchParams({
            name: this.ParameterName,
            valueArray: this.getSelectedFilterItems(this.FilterItems),
        });
    }
}

export class MetaItem implements IMetaItem {
    constructor(public Id: number, public Name: string) {}
}
