import { Component, Injector } from '@angular/core';

import { FormSelectBaseWithNullableOption } from '../form-select-wrapper/form-select-base-with-nullable-option';

@Component({
    styles: [
        `
            .inside-box-maxlength {
                position: relative !important;
                bottom: -13px;
                top: -13px;
                margin-bottom: -13px;
                right: 3px;
                font-size: 70%;
            }
        `,
    ],
    templateUrl: './form-old-style-radio-button-list.component.html',
})
export class FormOldStyleRadioButtonListComponent extends FormSelectBaseWithNullableOption {
    constructor(injector: Injector) {
        super(injector);
    }

    labelClicked(option: any): void {
        let control = this.getControl();
        if (control.enabled) {
            control.patchValue(option.Id);
            control.markAsDirty();
            control.markAsTouched();
        }
    }
}
