import { InjectionToken } from '@angular/core';

/**
 * Interface representing the customizable properties
 * of the entity components documents module.
 * @example
 * const entityComponentsDocumentsModuleConfig: IEntityComponentsDocumentsModuleConfig = {
 *     max: 5,
 * };
 * @property max
 */
export interface IEntityComponentsDocumentsModuleConfig {
    max: number;
}

/**
 * Token used for providing the IEntityComponentsDocumentsModuleConfig
 * @example
 * // in the app or shared modules providers section
 * providers: [
 *     { provide: EntityComponentsDocumentsModuleConfigToken, useValue: entityComponentsDocumentsModuleConfig },
 * ],
 */
export const EntityComponentsDocumentsModuleConfigToken = new InjectionToken<IEntityComponentsDocumentsModuleConfig>(
    'entity_components_documents_module_config',
);
