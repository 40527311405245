/**
 * Interface for controlling the search params object that is passed to the backend.
 * @property {string} query
 * @property {number} [skip]
 * @property {number} [take]
 * @property {object[]} [extraParams]
 * @property {string} [order]
 * @property {string} [orderDirection]
 */
export interface IEntitySearchParams {
    query: string;
    skip?: number;
    take?: number;
    extraParams?: ExtraSearchParams[];
    order?: string;
    orderDirection?: string;
}

/**
 * Used to define the object for passing search parameters to the backend
 * @property {string} query
 * @property {number} [skip]
 * @property {number} [take]
 * @property {object[]} [extraParams]
 * @property {string} [order]
 * @property {string} [orderDirection]
 */
export class SearchParams implements IEntitySearchParams {
    query: string;
    skip?: number;
    take?: number;
    extraParams?: ExtraSearchParams[];
    order?: string;
    orderDirection?: string;
    constructor(searchParams: IEntitySearchParams) {
        this.query = searchParams.query;
        this.skip = searchParams.skip;
        this.take = searchParams.take;
        this.extraParams = searchParams.extraParams;
        this.order = searchParams.order;
        this.orderDirection = searchParams.orderDirection;
    }
}

/**
 * Interface for controlling the extra search params object that is passed to the backend.
 * These are typically used for drop down filter types.
 * @property {string} name
 * @property {string} [value]
 * @property {number[]} [valueArray]
 */
export interface IEntityExtraSearchParams {
    name: string;
    value?: string;
    valueArray?: number[];
}

/**
 * Used to define the object for when you want to pass in additional
 * search parameters not from the main input.
 */
export class ExtraSearchParams implements IEntityExtraSearchParams {
    name: string;
    value?: string;
    valueArray?: number[];
    constructor(extraSearchParams: IEntityExtraSearchParams) {
        this.name = extraSearchParams.name;
        this.value = extraSearchParams.value;
        this.valueArray = extraSearchParams.valueArray;
    }
}
