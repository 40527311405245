import { InjectionToken } from '@angular/core';

/**
 * @description interface representing customizable properties of the environment config
 */
export interface IEnvironmentModuleConfig {
    /**
     * @property {string} version of the API
     */
    apiVersion?: string;
    /**
     * @property {string} name of application
     */
    appName?: string;
    /**
     * @property {string} version of the application
     */
    appVersion?: string;
    /**
     * @property {string} path to assets directory
     */
    assetsPath?: string;
    /**
     * @property {number} auth ID of the client
     */
    authClientId?: number;
    /**
     * @property {string} auth secret variable
     */
    authSecretVariable?: string;
    /**
     * @property {string} base URL of the API
     */
    baseApiUrl?: string;
    /**
     * @property {string} URL path of the docs directory
     */
    docPath?: string;
    /**
     * @property {string} URL path images get uploaded to
     */
    imgUploadPath?: string;
    /**
     * @property {string} google API key for the environment
     */
    googleApiKey?: string;
    /**
     * @property {string} URL path of the docs/images directory
     */
    imgPath?: string;
    /**
     * @property {boolean} flag indicating whether or not to use the logger
     */
    logger?: boolean;
    /**
     * @property {string} flag indicating whether or not this is production
     */
    production?: boolean;
    /**
     * @property {string} site name
     */
    siteName?: string;
    /**
     * @property {string} URL submit help path
     */
    submitHelpUrl?: string;
}

export const EnvironmentModuleConfigToken = new InjectionToken<IEnvironmentModuleConfig>('environment_module_config');
