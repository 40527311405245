import { ComponentErrorHandler } from '@mt-ng2/component-error-handler';
import { ColumnShowOnPrint } from './entity-list-column.library';

export enum SelectTypes {
    Single = 1,
    Multi = 2,
}

export type EntityListSelectColumnSelectableFunction = (entity: any) => boolean;

/**
 * defines a select column for the EntityListComponent
 */
export interface IEntityListSelectColumn {
    /**
     * type of select
     *
     * defaults to Single
     */
    type?: SelectTypes;
    /**
     * width of the column in pixels
     *
     * defaults to 40
     */
    width?: number;
    /**
     * selectedColor is the html color code to use
     *
     * defaults to module config or '#a2c6e5'
     */
    selectedColor?: string;
    /**
     * uniqueId is the property on the entity to use as the unique identifier
     *
     * defaults to 'Id'
     */
    uniqueId?: string;
    /**
     * ColumnShowOnPrint enum determining if the select column should show on print
     *
     * defaults to HideOnPrint
     */
    showOnPrint?: ColumnShowOnPrint;
    /**
     * callback function that if provided will be used to determine if an entity is selectable or not
     *
     * defaults to null
     */
    selectable?: EntityListSelectColumnSelectableFunction;
}

function instanceOfIEntityListSelectColumn(object: any): object is IEntityListSelectColumn {
    if (!object || typeof object !== 'object') {
        return false;
    }
    return (
        'type' in object ||
        'width' in object ||
        'selectedColor' in object ||
        'uniqueId' in object ||
        'showOnPrint' in object ||
        'selectable' in object
    );
}

/**
 * class to be used in the EntityListComponent to define a select column
 */
export class EntityListSelectColumn implements IEntityListSelectColumn {
    private errorHandler = new ComponentErrorHandler('EntityListSelectColumn', '@mt-ng2/entity-list-module');

    public type: SelectTypes;
    public width: number;
    public selectedColor: string;
    public uniqueId: string;
    public showOnPrint: ColumnShowOnPrint;
    selectable: EntityListSelectColumnSelectableFunction;

    /**
     * Deprecation Message: "non interface constructor parameters deprecated January-2021 with release 5.1.0 use IEntityListSelectColumn as your first parameter instead"
     * @param type
     * @deprecated @param width
     * @deprecated @param selectedColor
     * @deprecated @param uniqueId
     * @deprecated @param showOnPrint
     * @deprecated @param selectable
     */
    constructor(
        type?: SelectTypes | IEntityListSelectColumn,
        width?: number,
        selectedColor?: string,
        uniqueId?: string,
        showOnPrint?: ColumnShowOnPrint,
        selectable?: EntityListSelectColumnSelectableFunction,
    ) {
        this.deprecatedConstructorParamsCheck(type as IEntityListSelectColumn);

        const constructorParams: IEntityListSelectColumn = instanceOfIEntityListSelectColumn(type)
            ? <IEntityListSelectColumn>type
            : {
                  selectable: selectable,
                  selectedColor: selectedColor,
                  showOnPrint: showOnPrint,
                  type: type,
                  uniqueId: uniqueId,
                  width: width,
              };

        this.type = typeof constructorParams.type !== 'undefined' && constructorParams.type !== null ? constructorParams.type : SelectTypes.Single;
        this.width = typeof constructorParams.width !== 'undefined' && constructorParams.width !== null ? constructorParams.width : 40;
        this.selectedColor =
            typeof constructorParams.selectedColor !== 'undefined' && constructorParams.selectedColor !== null
                ? constructorParams.selectedColor
                : '#a2c6e5';
        this.uniqueId = typeof constructorParams.uniqueId !== 'undefined' && constructorParams.uniqueId !== null ? constructorParams.uniqueId : 'Id';
        this.showOnPrint =
            typeof constructorParams.showOnPrint !== 'undefined' && constructorParams.showOnPrint !== null
                ? constructorParams.showOnPrint
                : ColumnShowOnPrint.HideOnPrint;
        this.selectable = typeof constructorParams.selectable !== 'undefined' ? constructorParams.selectable : null;
    }

    private deprecatedConstructorParamsCheck(type: IEntityListSelectColumn): void {
        if (type !== undefined && !instanceOfIEntityListSelectColumn(type)) {
            this.errorHandler.addWarning(
                'non interface constructor parameters deprecated January-2021 with release 5.1.0 - use IEntityListSelectColumn as your first parameter instead',
            );
        }
    }

    showColumn(printMode: boolean): boolean {
        let show = true;
        const showOnPrint = this.showOnPrint;
        if (showOnPrint === null || showOnPrint === undefined) {
            return show;
        }

        if (printMode) {
            show = showOnPrint === ColumnShowOnPrint.ShowAlways || showOnPrint === ColumnShowOnPrint.ShowOnlyOnPrint;
        } else {
            show = showOnPrint === ColumnShowOnPrint.ShowAlways || showOnPrint === ColumnShowOnPrint.HideOnPrint;
        }
        return show;
    }
}
